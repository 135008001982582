/* eslint-disable no-unused-vars */
import {Module} from "vuex";
import {NavMenu, PatientFormsEsignDocument, RootState} from "../../index";
import Vue from "vue";
import {PatientFormsEsignDocumentsService, PatientWetSignDocumentsService} from "../../../services/interfaces";

// DO NOT MOVE THIS OR ELSE
import {LoadingType} from "../../constants";
import moment from "moment/moment";
import {cloneDeep} from "lodash";
import {getDate, getFullDateTime} from "@shared/functions/dateFunctions";
import {ErrorNotification, SuccessNotification} from "@shared/functions/NotificationFunctions";

//TODO rename to PatientFormsAndSignatures
export interface PatientFormsAndSignaturesState {
    loading: boolean;
    getting: boolean;
    deleting: boolean;
    saving: boolean;
    sending: boolean;
    patientHistorySideNav?: NavMenu;
    patientHistory?: PatientFormsEsignDocument[];
    nextSignerEmailList?: { folderId: string, email: string }[]
}

export enum PatientFormsAndSignaturesActions {
    LOAD_PATIENT_HISTORY = "PatientFormsAndSignaturesModule/loadPatientHistory",
    GET_NEXT_SIGNER = "PatientFormsAndSignaturesModule/getNextSigner",
    DELETE_ESIGN_DOCUMENT = "PatientFormsAndSignaturesModule/deleteEsignDocument",
    DELETE_WET_SIGN_DOCUMENT = "PatientFormsAndSignaturesModule/deleteWetSignDocument",
    SEND_ESIGN_DOCUMENT_REMINDER = "PatientFormsAndSignaturesModule/sendEsignDocumentReminder",
    UPDATE_ESIGN_DOCUMENT_RECIPIENT = "PatientFormsAndSignaturesModule/updateEsignDocumentRecipient",
    DISABLE_ESIGNATURE_REMINDERS = "PatientFormsAndSignaturesModule/disableEsignatureReminders",
    RESET = "PatientFormsAndSignaturesModule/reset",
}

export enum PatientFormsAndSignaturesMutations {
    SET_LOADING = "PatientFormsAndSignaturesModule/setLoading",
    SET_PATIENT_HISTORY = "PatientFormsAndSignaturesModule/setPatientHistory",
    SET_PATIENT_HISTORY_SIDE_NAV = "PatientFormsAndSignaturesModule/setPatientHistorySideNav",
    SET_NEXT_SIGNER_EMAIL_LIST = "PatientFormsAndSignaturesModule/setNextSignerEmailList",
    RESET = "PatientFormsAndSignaturesModule/reset",
}

export enum PatientFormsAndSignaturesGetters {
    LOADING = "PatientFormsAndSignaturesModule/loading",
    GETTING = "PatientFormsAndSignaturesModule/getting",
    SAVING = "PatientFormsAndSignaturesModule/saving",
    SENDING = "PatientFormsAndSignaturesModule/sending",
    DELETING = "PatientFormsAndSignaturesModule/deleting",
    NEXT_SIGNER_EMAIL_LIST = "PatientFormsAndSignaturesModule/nextSignerEmailList",
    PATIENT_HISTORY = "PatientFormsAndSignaturesModule/patientHistory",
    PATIENT_HISTORY_SIDE_NAV = "PatientFormsAndSignaturesModule/patientHistorySideNav",
}

const initialState = () => ({
    loading: false,
    getting: false,
    deleting: false,
    saving: false,
    sending: false,
    patientHistorySideNav: undefined,
    patientHistory: undefined,
    nextSignerEmailList: undefined,
});

export const PatientFormsAndSignaturesModule: Module<PatientFormsAndSignaturesState, RootState> = {
    namespaced: true,
    state: initialState(),
    actions: {
        async loadPatientHistory(
            {commit, dispatch, rootGetters, getters},
            payload: {
                patientId: string,
                service: PatientFormsEsignDocumentsService,
            }
        ) {
            let success = false;
            try {
                commit("setLoading", {
                    loading: true,
                    loadingType: LoadingType.GETTING,
                });

                const {service, patientId} = payload;
                const patientHistory: PatientFormsEsignDocument[] = await service.getPatientFormsEsignDocuments(patientId);
                commit("setNextSignerEmailList", []);
                commit("setPatientHistory", patientHistory);
                success = true;
            } catch (error) {
                await ErrorNotification(dispatch, error, error.data);
            }
            commit("setLoading", {
                loading: false,
                loadingType: LoadingType.GETTING,
            });
            return success;
        },
        async getNextSigner(
            {commit, dispatch, rootGetters, getters},
            payload: {
                folderId: string,
                service: PatientFormsEsignDocumentsService,
            }
        ) {
            let success = false;
            try {
                commit("setLoading", {
                    loading: true,
                    loadingType: LoadingType.GETTING,
                });

                const {service, folderId} = payload;
                const email: string = await service.getFormNextSigner(folderId);
                const signers = cloneDeep(getters.nextSignerEmailList) || [];
                const index = signers?.findIndex(
                    (item: { folderId: number | string, email: string }) =>
                        item.folderId == folderId
                );
                if (index > -1) {
                    signers[index] = {folderId: folderId, email: email};
                } else {
                    signers.push({folderId: folderId, email: email});
                }
                commit("setNextSignerEmailList", signers);
                success = true;
            } catch (error) {
                await ErrorNotification(dispatch, error, error.data);
            }
            commit("setLoading", {
                loading: false,
                loadingType: LoadingType.GETTING,
            });
            return success;
        },
        async updateEsignDocumentRecipient(
            {commit, dispatch, getters},
            payload: {
                folderId: string | number;
                patientId: string;
                email: string;
                service: PatientFormsEsignDocumentsService;
            }
        ) {
            let success = false;
            try {
                commit("setLoading", {
                    loading: true,
                    loadingType: LoadingType.SAVING,
                });
                const {service, folderId, patientId, email} = payload;
                const recipientUpdated = await service.updateRecipient(folderId, patientId, email);
                if (recipientUpdated) {
                    success = await SuccessNotification(dispatch, `Recipient updated successfully!`);
                }
            } catch (error) {
                await ErrorNotification(dispatch, error, error.data);
            }
            commit("setLoading", {
                loading: false,
                loadingType: LoadingType.SAVING,
            });
            return success;
        },
        async sendEsignDocumentReminder(
            {commit, dispatch, getters},
            payload: {
                folderId: string | number;
                patientId: string;
                service: PatientFormsEsignDocumentsService;
            }
        ) {
            let success = false;
            try {
                commit("setLoading", {
                    loading: true,
                    loadingType: LoadingType.SENDING,
                });
                const {service, folderId, patientId} = payload;
                const reminderSent = await service.sendReminder(folderId, patientId);
                if (reminderSent) {
                    //TODO ideally the reminder would be set here without reloading the page like in history
                    success = await SuccessNotification(dispatch, `Reminder sent successfully!`);
                }
            } catch (error) {
                await ErrorNotification(dispatch, error, error.data);
            }
            commit("setLoading", {
                loading: false,
                loadingType: LoadingType.SENDING,
            });
            return success;
        },
        async deleteWetSignDocument(
            {commit, dispatch, getters},
            payload: {
                patientContractId: string;
                service: PatientWetSignDocumentsService;
            }
        ) {
            let success = false;
            try {
                commit("setLoading", {
                    loading: true,
                    loadingType: LoadingType.DELETING,
                });
                const {service, patientContractId} = payload;
                const deleted = await service.deletePatientWetSignDocument(patientContractId);
                if (deleted >= 200 && deleted < 300) {
                    const patientHistory = (
                        cloneDeep(getters.patientHistory) || []
                    );
                    const index = patientHistory.findIndex((x: PatientFormsEsignDocument) => x.patientContractId?.toString() === patientContractId?.toString());
                    if(index > -1) {
                        patientHistory[index].status = "Deleted";
                        patientHistory[index].lastModifiedTimestamp = moment.utc().format();
                        commit("setPatientHistory", patientHistory);
                    }

                    commit("setPatientHistory", patientHistory);
                    success = await SuccessNotification(dispatch, `Deleted document/form successfully!`);
                }
            } catch (error) {
                await ErrorNotification(dispatch, error, error.data);
            }
            commit("setLoading", {
                loading: false,
                loadingType: LoadingType.DELETING,
            });
            return success;
        },
        async deleteEsignDocument(
            {commit, dispatch, getters},
            payload: {
                folderId: string | number;
                service: PatientFormsEsignDocumentsService;
            }
        ) {
            let success = false;
            try {
                commit("setLoading", {
                    loading: true,
                    loadingType: LoadingType.DELETING,
                });
                const {service, folderId} = payload;
                const deleted = await service.deleteEsignature(folderId);
                if (deleted) {
                    const patientHistory = (
                        cloneDeep(getters.patientHistory) || []
                    );
                    const index = patientHistory.findIndex((x: PatientFormsEsignDocument) => x.folderId?.toString() === folderId?.toString());

                    if(index > -1) {
                        patientHistory[index].status = "Deleted";
                        patientHistory[index].lastModifiedTimestamp = moment.utc().format();
                        commit("setPatientHistory", patientHistory);
                    }
                    success = await SuccessNotification(dispatch, `Deleted document/form successfully!`);
                }
            } catch (error) {
                await ErrorNotification(dispatch, error, error.data);
            }
            commit("setLoading", {
                loading: false,
                loadingType: LoadingType.DELETING,
            });
            return success;
        },
        async disableEsignatureReminders(
            {commit, dispatch, getters},
            payload: {
                folderId: string | number;
                service: PatientFormsEsignDocumentsService;
            }
        ) {
            let success = false;
            try {
                commit("setLoading", {
                    loading: true,
                    loadingType: LoadingType.DISABLING,
                });
                const {service, folderId} = payload;
                const disabled = await service.disableEsignatureReminders(folderId);
                if (disabled) {
                    success = await SuccessNotification(dispatch, `Disabled document/form reminders successfully!`);
                }
            } catch (error) {
                await ErrorNotification(dispatch, error, error.data);
            }
            commit("setLoading", {
                loading: false,
                loadingType: LoadingType.DISABLING,
            });
            return success;
        },
        reset({commit}) {
            commit("reset");
        },
    },
    mutations: {
        setLoading(
            state: PatientFormsAndSignaturesState,
            payload: { loading: boolean; loadingType: string | string[] }
        ) {
            if (typeof payload === "boolean") {
                Vue.set(state, "loading", payload);
            } else {
                if (payload.loadingType) {
                    if (Array.isArray(payload.loadingType)) {
                        payload.loadingType.forEach((item) => {
                            Vue.set(state, item, payload.loading);
                        });
                    } else {
                        Vue.set(state, payload.loadingType, payload.loading);
                    }
                }
            }
            Vue.set(state, "loading", payload.loading);
        },
        setNextSignerEmailList(
            state: PatientFormsAndSignaturesState,
            payload: { folderId: string, email: string }[]
        ) {
            Vue.set(state, "nextSignerEmailList", payload);
        },
        setPatientHistory: function (state: PatientFormsAndSignaturesState, patientHistory?: PatientFormsEsignDocument[]) {
            const history = cloneDeep(patientHistory);
            if (history?.length) {
                history?.forEach((record, index) => {
                    history[index]['lines'] = [];
                    history[index]['isPartiallySigned'] = false;
                    // add line for when item was initially created
                    //TODO format the treatmentPlanUrl with the new system instead of old

                    if(!record?.createdDate && record?.lastModifiedTimestamp && record?.folderId) {
                        record.createdDate = record.lastModifiedTimestamp;
                    }
                    if (record?.createdDate) {
                        if (record.phoneNumber && record.phoneNumber.length > 0) {
                            history[index]?.lines?.push({
                                recipient: record.phoneNumber,
                                status: `${record?.recordType && !record.recordType?.includes('Form') ? record.status : 'Created'} on ${getFullDateTime(record.createdDate)}`,
                                timestamp: moment(record.createdDate).toDate()
                            });
                        } else if (record.email && record.email.length > 0) {
                            history[index]?.lines?.push({
                                recipient: record.email,
                                status: (record.recordType && !record.recordType?.includes('Form') ? record.status : 'Created') + ' on ' + getFullDateTime(record.createdDate),
                                timestamp: moment(record.createdDate).toDate()
                            });
                        }
                        // for sliders/pymt opts/contract PDF - add line for when item was completed or expired
                        if (record.expiredDate) {
                            if (record.completedDate && moment(record.completedDate).isBefore(moment.now())) {
                                history[index]?.lines?.push({
                                    recipient: '',
                                    status: record.status + ' on ' + getFullDateTime(record.completedDate),
                                    timestamp: moment(record.completedDate).toDate()
                                });
                            } else if (moment(record.expiredDate).isBefore(moment.now())) {
                                history[index]?.lines?.push({
                                    recipient: '',
                                    status: 'Expired on ' + getFullDateTime(record.expiredDate),
                                    timestamp: moment(record.expiredDate).toDate()
                                });
                            }
                        }
                        // for e-sign forms only
                        if (record.folderId) {
                            // add line for when item was signed
                            if (record.status !== 'Created' && record.status !== 'Emailed' && record.signedTimestamp) {
                                history[index]?.lines?.push({
                                    recipient: '',
                                    status: (!record.status.includes('Sign') ? 'Signed' : record.status) + (record.signedTimestamp ? ' on ' + getFullDateTime(record.signedTimestamp) : ''),
                                    timestamp: (record.signedTimestamp ? moment(record.signedTimestamp).toDate() : moment().toDate())
                                });
                                if (record.status.includes('Partial')) {
                                    history[index].isPartiallySigned = true;
                                }
                            }
                            // add line for when item was cancelled or deleted, if it's in one of those states
                            if (record.status === 'Cancelled' || record.status === 'Deleted') {
                                history[index]?.lines?.push({
                                    recipient: '',
                                    status: record.status + (record.lastModifiedTimestamp ? ' on ' + getFullDateTime(record.lastModifiedTimestamp) : ''),
                                    timestamp: (record.lastModifiedTimestamp ? moment(record.lastModifiedTimestamp).toDate() : moment().toDate())
                                });
                            }
                        }
                        history[index]['createdDate'] = getDate(cloneDeep(record?.createdDate));
                    }
                    // add line for when wet signature occurred - no created date for this one
                    else if (record.isWetSignature) {
                        history[index]?.lines?.push({
                            recipient: record.email,
                            status: (record.status === "Signed" ? 'Signed (wet)' + (record.signedTimestamp ? ' on ' + getFullDateTime(record.signedTimestamp) : '') : record.status),
                            timestamp: (record.signedTimestamp ? moment(record.signedTimestamp).toDate() : moment().toDate())
                        });
                    }
                    // add line(s) for all communication history entries
                    if (record.patientCommunicationHistories?.length) {
                        record?.patientCommunicationHistories?.forEach((patientCommunicationHistory) => {
                            history[index]?.lines?.push({
                                recipient: patientCommunicationHistory?.recipient,
                                status: patientCommunicationHistory?.action + ' on ' + getFullDateTime(patientCommunicationHistory.createdDate as string),
                                timestamp: moment(patientCommunicationHistory.createdDate).toDate()
                            });
                        });
                    }

                    // now order the status rows just created by timestamp
                    history[index]?.lines?.sort((a, b) => {
                        if (moment(a.timestamp) > moment(b.timestamp)) {
                            return 1;
                        } else if (moment(a.timestamp) < moment(b.timestamp)) {
                            return -1;
                        } else {
                            return 0;
                        }
                    });

                });

                //order full history object for rows in table
                history?.sort((a, b) => {
                    if (moment(a.createdDate) < moment(b.createdDate)) {
                        return 1;
                    } else if (moment(a.createdDate) > moment(b.createdDate)) {
                        return -1;
                    } else {
                        return 0;
                    }
                })
            }
            Vue.set(state, "patientHistory", history);
        },
        setPatientHistorySideNav: function (state: PatientFormsAndSignaturesState, patientId?: string) {
            const sideNav = patientId ? {
                key: "History",
                text: "History",
                regex: /\/history/,
                group: "/history",
                items: [{
                    icon: "$historyLight",
                    key: "History",
                    text: "History",
                    link: `/patients/${patientId}/history/`,
                    group: "/history",
                }],
            } as NavMenu : undefined;
            Vue.set(state, "patientHistorySideNav", sideNav);
        },
        reset: function (state: PatientFormsAndSignaturesState) {
            const newState = initialState();
            Object.keys(newState).forEach(key => {
                try {
                    // @ts-ignore
                    state[key] = newState[key];
                } catch (ex) {
                    console.error('PatientsState Reset Error: ', ex.message);
                }
            });
        },
    },
    getters: {
        loading: (state) => state.loading,
        patientHistorySideNav: (state) => state.patientHistorySideNav,
        patientHistory: (state) => state.patientHistory,
        getting: (state) => state.getting,
        saving: (state) => state.saving,
        sending: (state) => state.sending,
        deleting: (state) => state.deleting,
        nextSignerEmailList: (state) => state.nextSignerEmailList,
    },
};
