import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"persistent":"","fullscreen":_vm.$vuetify.breakpoint.mobile,"max-width":500},model:{value:(_vm.idleTimeOutDialog),callback:function ($$v) {_vm.idleTimeOutDialog=$$v},expression:"idleTimeOutDialog"}},[_c(VCard,[_c(VCardTitle,[_vm._v("Session Expiring Soon")]),_c(VCardText,{staticClass:"body-1"},[_vm._v(" You've been inactive for a while. You may choose \"Yes, stay signed in\" to continue or \"No, sign me out\" if you're done. "),_c('p',{staticClass:"pt-2 font-weight-bold"},[_vm._v("Time remaining: "+_vm._s(_vm.display))])]),_c(VCardActions,{staticClass:"py-1 d-flex justify-end"},[_c(VSpacer),_c(VBtn,{attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.signOut()}}},[_vm._v("No, sign me out ")]),_c(VBtn,{attrs:{"color":"primary","rounded":""},on:{"click":function($event){return _vm.extendSession()}}},[_vm._v("Yes, stay signed in ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }