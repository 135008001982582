import {ProductTypeService} from "../services/interfaces";
import {inject, provide} from "@vue/composition-api";

export const productTypeServiceSymbol = Symbol();

export function provideProductTypeService(service: ProductTypeService) {
  return provide(productTypeServiceSymbol, service);
}

export function useProductTypeService() {
  const productTypeService = inject<ProductTypeService>(
    productTypeServiceSymbol
  );
  if (!productTypeService) {
    throw Error("No Product Type Service to Inject!!");
  }
  return productTypeService;
}
