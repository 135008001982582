import {inject, provide} from "@vue/composition-api";
import {TreatmentCoordinatorsService} from "../services/interfaces";

export const treatmentCoordinatorsServiceSymbol = Symbol();

export function provideTreatmentCoordinatorsService(
  service: TreatmentCoordinatorsService
) {
  return provide(treatmentCoordinatorsServiceSymbol, service);
}

export function useTreatmentCoordinatorsService() {
  const treatmentCoordinatorsService = inject<TreatmentCoordinatorsService>(
    treatmentCoordinatorsServiceSymbol
  );
  if (!treatmentCoordinatorsService) {
    throw Error("No Treatment Coordinator Service to Inject!!");
  }
  return treatmentCoordinatorsService;
}
