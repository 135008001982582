import {inject, provide} from "@vue/composition-api";
import {PatientService} from "../services/interfaces";

export const patientServiceSymbol = Symbol();

export function providePatientService(service: PatientService) {
  return provide(patientServiceSymbol, service);
}

export function usePatientService() {
  const patientService = inject<PatientService>(patientServiceSymbol);
  if (!patientService) {
    throw Error("No Patient Service to Inject!!");
  }
  return patientService;
}
