import {inject, provide} from "@vue/composition-api";
import {PatientTreatmentTypesService} from "../services/interfaces";


export const patientTreatmentTypesServiceSymbol = Symbol();

export function providePatientTreatmentTypesService(
  service: PatientTreatmentTypesService
) {
  return provide(patientTreatmentTypesServiceSymbol, service);
}

export function usePatientTreatmentTypesService() {
  const patientTreatmentTypesService = inject<PatientTreatmentTypesService>(
      patientTreatmentTypesServiceSymbol
  );
  if (!patientTreatmentTypesService) {
    throw Error("No Patient Treatment Types Service to Inject!!");
  }
  return patientTreatmentTypesService;
}
