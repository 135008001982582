import { VBadge } from 'vuetify/lib/components/VBadge';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.className},[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c(VBadge,{attrs:{"value":_vm.isBadgeVisible || _vm.add,"color":_vm.color,"dot":_vm.dot,"content":_vm.add ? '+' : _vm.content,"offset-y":_vm.offset,"offset-x":_vm.offset,"bottom":_vm.add,"bordered":false},scopedSlots:_vm._u([(_vm.add)?{key:"badge",fn:function(){return [_c(VIcon,{staticClass:"text--white",staticStyle:{"cursor":"pointer"},attrs:{"x-small":"","color":"white"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();!_vm.openNewWindowUrl && _vm.routeTo ? _vm.clickNavigateToRoute($event, _vm.routeTo) : _vm.openNewWindowUrl ? _vm.goTo : undefined}}},[_vm._v("$plus ")])]},proxy:true}:null],null,true)},[_c(VBtn,_vm._g({directives:[{name:"blur",rawName:"v-blur"}],class:_vm.active ? 'v-btn--active' : '',attrs:{"icon":"","small":"","exact":_vm.exact,"exact-path":_vm.exactPath,"disabled":_vm.customButtonEnabled ? !_vm.customButtonEnabled : ((!_vm.isBadgeVisible && !_vm.add)),"href":_vm.openNewWindowUrl,"to":!_vm.openNewWindowUrl && _vm.routeTo ? _vm.routeTo : undefined,"target":_vm.openNewWindowUrl ? '_blank' : ''},on:{"click":function($event){_vm.isSearch ? _vm.$emit('close') : undefined}}},!_vm.$vuetify.breakpoint.mobile ? tooltip : undefined),[(_vm.isSplit && _vm.icon === '$briefcase')?_c('font-awesome-layers',{staticClass:"fa-2x",attrs:{"full-width":""}},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'briefcase-blank']}}),_c('font-awesome-icon',{attrs:{"icon":['fal', 'split'],"transform":"down-1 shrink-6","inverse":""}})],1):_c(VIcon,{attrs:{"color":(_vm.linkInformation || {}).isArchived && _vm.linkInformationKey === 'responsiblePartyIds' ? 'warning' : ''}},[_vm._v(_vm._s(_vm.icon)+" ")])],1)],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.tooltipText))])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }