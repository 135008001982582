import {RuleFunction} from "@shared/store";
import {FileMaxUploadSize, FileTypes, IMAGE_TYPES, LOGO_SIZE, PaymentPlanTypes} from "@shared/store/constants";
import {ref} from "@vue/composition-api";
import moment from "moment/moment";

//DO NOT CHANGE REQUIRED RULE WITHOUT UPDATED required-asterisk DIRECTIVE (even looks at spacing)
export function requiredRule(
  message = "Field is required"
): (value: any) => boolean | string {
  return (value) => !!value || value === 0 || message;
}

export function usePhotoValidation() {
  const photoRules = ref<RuleFunction<File>[]>([
    (value: File) =>
      !value ||
      IMAGE_TYPES.includes(value.type) ||
      "Logo must be a png, jpg, gif, or svg",
    (value: File) =>
      !value || value.size <= LOGO_SIZE || "Logo size must be less than 50KB",
  ]);
  const requiredPhotoRules = ref<RuleFunction<File>[]>([
    (value: File) => !!value || "Image is required",
    (value: File) =>
      !value ||
      IMAGE_TYPES.includes(value.type) ||
      "Logo must be a png, jpg, gif, or svg",
    (value: File) =>
      !value || value.size <= LOGO_SIZE || "Logo size must be less than 50KB",
  ]);
  return {
    photoRules,
    requiredPhotoRules,
  };
}

export function uniqueValueRule(
    comparisonValue: string,
    message = "The value has to be unique"
): (value: any) => boolean | string {
  return (value) =>
      comparisonValue != value || message;
}
//DO NOT CHANGE REQUIRED RULE WITHOUT UPDATED required-asterisk DIRECTIVE (even looks at spacing)
export function requiredMultipleSelect(
  message = "Field is required"
): (value: any) => boolean | string {
  return (value) => value.length > 0 || message;
}
export function maxArrayLengthRule(
    length = 2,
    message = `Maximum of ${length} selected`
): (value: any) => boolean | string {
  return (value) => (value || []).length <= length || message;
}

export function maxLengthRule(
  length = 100,
  message = `Field must be less than ${length} characters long`
): (value: any) => boolean | string {
  return (value) => (value || []).length <= length || message;
}

export function minLengthRule(
  length = 8,
  message = `Field must be at least ${length} characters long`
): (value: any) => boolean | string {
  return (value) => value && typeof value === 'string' && value.length >= length || message;
}

export function minAmountRule(
  amount = 0,
  message = `Amount has a minimum value of ${amount}`
): (value: any) => boolean | string {
  return (value) => typeof value === 'undefined'
      || (typeof value !== 'number' && !value)
      || (typeof value === 'string' && value.length && Number(value.replaceAll(',', '')) >= amount)
      || value >= amount
      || message;
}

export function validDateRule(
  message = "Date is not valid"
): (value: any) => boolean | string {
  return (value) =>
    (value ? (typeof value === 'string' && value?.length < 10 ? true : moment.utc(value,"MM-DD-YYYY").isValid()): true) || message;
}

export function minDateLengthRule(
    length = 10,
    message = `Date must be at least ${length} characters long`
): (value: any) => boolean | string {
  return (value) => (!value || typeof value !== 'string') ? true : value.length >= length || message;
}

export function minDateRule(
    minDate: Date | string,
    message = minDate ? `Date has be greater than ${moment(minDate).utcOffset(0, true).format("MM-DD-YYYY")}` : 'Date is greater than minimum date'
): (value: any) => boolean | string {
  return (value) =>
 (!value || !minDate) ? true : (typeof value === 'string' && value?.length < 10 ? true : moment.utc(value, "MM-DD-YYYY").isAfter(moment(minDate, "YYYY-MM-DD").add(1), 'day')) || message;
}

export function maxDateRule(
    maxDate: Date | string,
    message = maxDate ? `Date has to be less than ${moment(maxDate).utcOffset(0, true).format("MM-DD-YYYY")}` : 'Date has to less than maximum date'
): (value: any) => boolean | string {
  return (value) =>
      !value || !maxDate ? true : !moment.utc(value, "MM-DD-YYYY").isAfter(moment.utc(maxDate, "YYYY-MM-DD"), 'day') || message;
}

export function maxAmountRule(
  amount = 100,
  message = `Amount must be less than ${amount}`
): (value: any) => boolean | string {
  return (value) => !value || value <= amount || message;
}

export function maxEncodedLengthRule(
  length = 400,
  message = `Too many special characters. Reduce the message length and/or the number of special characters.`
): (value: any) => boolean | string {
  return (value) =>
    (encodeURIComponent(value) || []).length <= length || message;
}

export function fileTypeRule(
  message = "File must be a pdf"
): (value: any) => boolean | string {
  return (value) => !value || [FileTypes.PDF].includes(value.type) || message;
}

export function fileSizeRule(
  size = FileMaxUploadSize.IN_MB * 1024 * 1024, // in MB
  message = `File size must be less than ${FileMaxUploadSize.IN_MB}MB`
): (value: any) => boolean | string {
  return (value) => !value || value.size < size || message;
}

export function emailExists(
  emails: string[],
  message = `Cannot enter duplicate email address.`
): (value: any) => boolean | string {
  return (value) => !emails.includes((value || "").toLowerCase()) || message;
}

export function payInFullRule(
  message = "'Pay In Full (Credit Card)' requires 'Pay In Full' to also be selected."
): (value: any) => boolean | string {
  return (value) =>
    (value.includes(PaymentPlanTypes.PAY_IN_FULL_CREDIT)
      ? value.includes(PaymentPlanTypes.PAY_IN_FULL)
      : true) || message;
}

export function recurringPaymentRule(
  message = "Recurring Payments (Less Down)' requires 'Recurring Payments' to also be selected. 'Pay In Full (Credit Card)' requires 'Pay In Full' to also be selected."
): (value: any) => boolean | string {
  return (value) =>
    (value.includes(PaymentPlanTypes.RECURRING_LESS)
      ? value.includes(PaymentPlanTypes.RECURRING)
      : true) || message;
}

export function passwordVerificationRule(
  match: boolean
): (value: any) => boolean | string {
  return (value) => (value.length && match) || "Values must match";
}

export function lengthRule(): (value: any) => boolean | string {
  return (value) =>
    (value && value.length >= 8 && (value.length || 0) <= 32) ||
    "Password must contain at least eight characters and less than 32 characters.";
}
