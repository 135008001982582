import {inject, provide} from "@vue/composition-api";
import {ResponsiblePartyTiersService} from "../services/interfaces";

export const responsiblePartyTiersServiceSymbol = Symbol();

export function provideResponsiblePartyTiersService(
  service: ResponsiblePartyTiersService
) {
  return provide(responsiblePartyTiersServiceSymbol, service);
}

export function useResponsiblePartyTiersService() {
  const responsiblePartyTiersService = inject<ResponsiblePartyTiersService>(
    responsiblePartyTiersServiceSymbol
  );
  if (!responsiblePartyTiersService) {
    throw Error("No Responsible Party Tiers Service to Inject!!");
  }
  return responsiblePartyTiersService;
}
