import {inject, provide} from "@vue/composition-api";
import {ResponsiblePartiesService} from "../services/interfaces";

export const responsiblePartiesServiceSymbol = Symbol();

export function provideResponsiblePartiesService(
  service: ResponsiblePartiesService
) {
  return provide(responsiblePartiesServiceSymbol, service);
}

export function useResponsiblePartiesService() {
  const responsiblePartiesService = inject<ResponsiblePartiesService>(
    responsiblePartiesServiceSymbol
  );
  if (!responsiblePartiesService) {
    throw Error("No Responsible Parties Service to Inject!!");
  }
  return responsiblePartiesService;
}