import {ClientRemindersService} from "../services/interfaces";
import {inject, provide} from "@vue/composition-api";

export const clientRemindersServiceSymbol = Symbol();

export function provideClientRemindersService(service: ClientRemindersService) {
  return provide(clientRemindersServiceSymbol, service);
}

export function useClientRemindersService() {
  const clientRemindersService = inject<ClientRemindersService>(clientRemindersServiceSymbol);
  if (!clientRemindersService) {
    throw Error("No Client Reminders Service to Inject!!");
  }
  return clientRemindersService;
}