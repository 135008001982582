<template>
  <!--  TODO would love to remove the rounding or white around edges-->
  <v-dialog v-model="dialog"
            fullscreen
            hide-overlay
            :rounded="false"
            transition="dialog-bottom-transition">
    <v-card>
      <v-toolbar
          dark
          flat
          color="primary"
          :rounded="false"
      >
        <v-btn
            icon
            dark
            @click="close"
        >
          <v-icon>$close</v-icon>
        </v-btn>
        <v-toolbar-title>{{ title }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn
              dark
              text
              @click="close"
          >
            Close
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text class="pa-0 ma-0">
        <v-layout fluid class="d-flex justify-center align-center align-self-center align-content-center"
                  v-resize="onResize">
          <iframe v-if="src" style="min-width: 85vw; min-height:80vh; width:100%; border: none;"
                  :src="src" frameborder="0"
                  :style="'height: ' + (windowSize.y - ($vuetify.breakpoint.width > 959 ? 64 : 56)) + 'px !important'"
                  allowfullscreen sandbox="allow-scripts allow-same-origin allow-presentation" referrerpolicy="no-referrer"
                  allow="autoplay 'self' https://youtube.com"></iframe>
        </v-layout>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import {defineComponent, ref, watch} from "@vue/composition-api";
import Vue from "vue";

interface Props {
  loading: boolean;
  disable: boolean;
  maximizeIcon: boolean;
  showDialog: boolean;
  src: string;
  title: string;
}

export default defineComponent({
  name: "showDialog",
  props: {
    loading: Boolean,
    disable: Boolean,
    showDialog: Boolean,
    src: String,
    title: {
      type: String,
      default: "Video Viewer",
    }
  },
  components: {},
  setup(props: Props, {emit}) {
    const dialog = ref<boolean>(false);
    const windowSize = ref<{
      x: number;
      y: number
    }>({x: window?.innerWidth || 0, y: window?.innerHeight || 0});

    function onResize() {
      windowSize.value = {x: window.innerWidth, y: window.innerHeight};
    }

    async function close() {
      emit('update:showDialog', false);
      await Vue.nextTick();
      emit('update:src', "");
      dialog.value = false;

    }

    watch(
        () => [props.showDialog],
        () => {
          if (props?.showDialog) {
            dialog.value = props.showDialog;
          }
        },
        {immediate: true}
    );

    return {
      dialog,
      windowSize,
      onResize,
      close,
    };
  },
});
</script>
