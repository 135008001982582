import {SORT_ORDER_DIRECTION} from "./constants";
import {Doctor, Office, PatientType, TreatmentCoordinator,} from "./practice/interfaces";
import {PreFillField, ResponsibleParty} from "./responsible-parties/interfaces";
import {
    PpoRateApplicableAdditionalCharge,
    PpoRateApplicableOffice,
    PpoRateApplicableTreatmentClass,
    PpoRateApplicableTreatmentType
} from "./insurance/interfaces";
import {PatientDiscount} from "./patients/interfaces";
import {ProductFee} from "./calculations/interfaces";
import {ProductType} from "./financials/interfaces";
import {AxiosError} from "axios";

export type RuleFunction<T> = (arg: T) => boolean | string;

export interface Service {
    id: number;
    name: string;
    type: string;
}

export interface MaintenanceFile {
    text?: string;
    html?: string;
}

export interface Header {
    text: string;
    value: string;
    width?: string;
    sortable?: boolean;
    align?: string;
}

export interface Select {
    text: string;
    value: string | number | null;
    disabled?: boolean;
}

//TODO make these the vuetify theme from theme.ts
export enum AlertStatus {
    INFO = "info",
    WARNING = "warning",
    ERROR = "error",
    SUCCESS = "success",
}

interface CustomError {
    error?: AxiosError | null;
    name: string;
    data?: string;
    message?: string;
    stack?: string;
}

export interface Alert {
    status: AlertStatus;
    timeout?: number;
    title?: string;
    icon?: string;
    message?: string;
    error?: CustomError | null;
    position?: string;
}

export interface NavMenuItem {
    icon?: string;
    key: string;
    text: string;
    group: string;
    link: string;
    regex?: RegExp;
    items?: NavMenuItem[];
    subTitle?: string;
    subTitle2?: string;
    count?: number;
    addLink?: string;
}

export interface NavMenu {
    icon?: string;
    key: string;
    text: string;
    regex: RegExp;
    group: string;
    items: NavMenuItem[];
};


export interface MainSubMenu {
    title?: string;
    menu: NavMenu[];
    defaultExpanded: boolean;
    defaultSubMenuExpanded: boolean;
    key?: string;
    classProps?: string;
};

export interface AppType {
    icon: string;
    text: string;
    key: string;
    subtitle: string;
    link: string;
}

export interface EntityBase {
    createdDate?: Date | string;
    lastModifiedBy?: string;
    lastModifiedDate?: string | null;
}

export interface Session {
    xsrfToken: string;
    user: User;
}

export interface Role {
    id: string;
    name: string;
}
//User interfaces
export interface User {
    id?: string;
    name?: string;
    firstName: string;
    lastName: string;
    username?: string;
    email: string;
    emailConfirmed?: boolean;
    accessFailedCount?: number;
    accessTotalFailedCount?: number;
    lockoutEnabled?: boolean;
    lockOutEndDateUtc?: Date;
    lastLoginDate?: Date | string;
    roles: string[];
    clientId?: string;
}

export interface Password {
    code: string | null;
    userId: string | null;
    password: string | null;
    termsVerification?: boolean;
}

export interface ChangePassword {
    oldPassword: string;
    newPassword: string;
    email?: string;
}

export interface Registration {
    practiceName: string;
    website?: string | null;
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    termsVerification?: boolean;
    reCaptchaResponse: string | null;
}

export interface Credentials {
    email: string;
    password: string;
    rememberMe: boolean;
    xsrfToken?: string;
}

export interface PatientLoginCredentials {
    email: string;
    lastName: string;
    birthDate: string | Date;
    phoneNumber?: string;
    patientTreatmentPlanId: string;
    code: string;
    userId: string;
    isReadOnly: boolean;
    isEmail: boolean;
}

export interface PatientPortalInfo {
    patientTreatmentPlanId: string;
    code: string;
    userId: string;
    isEmail: boolean;
    isReadOnly: boolean;
}

export interface ForgotPassword {
    email: string;
    reCaptchaResponse: string;
}

//Patient Interfaces

export interface PatientResponsibleParty {
    birthDate?: null | string | Date;
    createdDate?: string;
    email?: null | string;
    firstName: string;
    fullName?: string;
    isObsolete?: boolean;
    lastModifiedBy?: string;
    lastModifiedDate?: string | Date;
    lastName: string;
    patient: Patient;
    patientId: string;
    patientResponsiblePartyId?: string;
    patientResponsiblePartyRelationshipId?: null | number | string;
}

export interface DuplicatePatient {
    patientId: string;
    duplicationType: string;
}

export interface PatientInsurance {
    deductibleAmount?: null | number;
    hasMaximum: boolean;
    insuranceAmount: number;
    insuranceCompanyName: string;
    insuranceMaxAmount?: null | number;
    insurancePhoneNumber?: null | string;
    isInsuranceAmountPercentage: boolean;
    isObsolete?: boolean;
    isOutOfNetwork: boolean;
    isPpoDiscountPercentage: boolean;
    isRemoteVerification: boolean;
    patientInsuranceId: string;
    patientResponsibleParty?: PatientResponsibleParty;
    patientResponsiblePartyId?: string | null;
    ppoDiscountAmount?: null | number;
    subscriberId?: null | string;
}

export interface PatientInsuranceVerification {
    client: Client;
    completedDate?: Date | string;
    createdByEmail?: null | string;
    createdDate?: null | string | Date;
    incompletedDate?: null | string | Date;
    isObsolete?: boolean;
    lastModifiedBy?: string;
    lastModifiedDate?: null | string | Date;
    notesStorageUrl?: null | string;
    patientInsurance: PatientInsurance;
    patientInsuranceId?: string;
    reverifyCompletedDate?: null | string | Date;
    reverifyRequestDate?: null | string | Date;
    reverifyRequestNotes?: null | string | Date;
}

export interface PatientFormsEsignDocument {
    completedDate?: Date | string | null;
    containsSecureField: boolean;
    secureFieldAccessRemoved: boolean;
    createdDate?: null | string | Date;
    email: string;
    expiredDate?: Date | string | null;
    firstName: string;
    folderAccessUrl?: string;
    folderId?: number;
    isWetSignature: false
    lastModifiedTimestamp?: Date | string | null;
    lastName: string;
    messageType: string;
    patientCommunicationHistories?: PatientCommunicationHistories[] | null
    patientContractId?: string;
    phoneNumber?: string;
    signedTimestamp?: Date | string | null;
    status: string;
    treatmentPlanUrl?: string | null;
    //dynamically generated in store
    recordType?: string; //not sure if this is even used anymore
    lines?: DocumentStatusItem[];
    isPartiallySigned?: boolean;
    nextReminderDate?: Date | string | null;
}

export interface DocumentStatusItem {
    recipient?: string,
    status?: string,
    timestamp?: Date | string | null;
}

export interface PatientCommunicationHistories {
    createdDate?: null | string | Date;
    folderId?: number;
    patientContractCommunicationId?: string;
    patientTreatmentPlanCommunicationId?: string;
    patientId: string;
    recipient?: string;
    action?: string;
    status?: string;
}

export interface Patient {
    birthDate?: string | Date;
    clientId?: string;
    createdDate?: string | Date;
    doctorsList?: null | Doctor[];
    externalPatientId?: string | null;
    firstName: string;
    fullName?: string;
    initialExamDateTime?: string | Date | null;
    isArchived?: boolean;
    isExtPatientIdDuplicate?: boolean;
    isNameAndBdayDuplicate?: boolean;
    isNameDuplicate?: boolean;
    isObsolete?: boolean;
    lastModifiedBy?: string;
    lastModifiedDate?: string | Date;
    lastName: string;
    middleName?: null | string;
    office?: Office | null;
    officeId?: string;
    patientId?: string;
    patientInsurances?: PatientInsurance[];
    patientResponsibleParties?: ResponsibleParty[];
    patientType?: PatientType | null;
    patientTypeId?: string;
    profileCreationDate?: string | Date;
}

export interface PatientDataSearchParams {
    start?: number;
    length?: number;
    search?: string;
    sortOrderEnabled?: boolean;
    sortOrder?: string; //could be refined to columns only (enum)
    sortOrderDirection?: SORT_ORDER_DIRECTION;
    officeIds?: string[];
    treatmentCoordinatorIds?: string[];
    doctorIds?: string[];
    fromLastTouchedDate?: string;
    toLastTouchedDate?: string;
    showArchived?: boolean;
    includeTreatmentPlans?: number;
    isRIV?: boolean;
    fromModifiedDateSubset?: string;
    statuses?: string[];
    dateRange?: number;
}

//configuration-general interfaces


export interface InputFields {
    bandingDateDisplay: boolean;
    birthDateDisplay?: boolean;
    clientId: string | null;
    doctorDisplay: boolean;
    externalPatientIdDisplay: boolean;
    inputFieldOptionsId: string | null;
    insuranceCompanyNameDisplay: boolean;
    middleNameDisplay: boolean;
    officeDisplay?: boolean;
    patientTypeDisplay: boolean;
    responsiblePartyRelationshipDisplay: boolean;
    treatmentCoordinatorDisplay: boolean;
    createdDate?: Date | string;
    lastModifiedBy?: string;
    lastModifiedDate?: Date | string;
}

//configuration-document interfaces

export interface IntakePdf {
    formId: string;
    data: string;
}

export interface AnytimePdf {
    formId: string;
    data: string;
}

export interface CustomFormPdf {
    formId: string;
    initialFormId: string;
    contractId: string;
    data: string;
}

export interface Signer {
    signer: number;
    firstName: string;
    lastName: string;
    email: string;
}

export interface NewPatientForm {
    formId: string;
}

export interface PacketBody {
    patientId?: string;
    patientFirstName: string;
    patientLastName: string;
    patientResponsiblePartyId: string;
    responsiblePartyFirstName: string;
    responsiblePartyLastName: string;
    email: string;
    officeId?: string;
    forms: NewPatientForm[];
    initialExamDateTimeString?: string;
    patientSpecificPrefillInfo?: PreFillField[];
    additionalSigner2?: Signer;
    additionalSigner3?: Signer;
    additionalSigner4?: Signer;
    additionalSigner5?: Signer;
}

export interface ClientConfiguration {
    clientId: string;
    createdDate: string | Date;
    formsConfigurationLastModifiedBy: string | null;
    formsConfigurationLastModifiedDate: string | Date | null;
    lastModifiedBy: string | Date | null;
    lastModifiedDate: string | Date | null;
    sharedLibraryFormsConfigurationLastModifiedDate: string | Date | null;
}

export interface ClientConfigurationSettings {
    clientConfiguration: ClientConfiguration;
    isUserInPatientBillingManagerRole: boolean;
}

export interface DocumentField {
    columnId: number;
    name: string;
    description: string;
    docName: string;
}

export interface TemplateDocuments {
    clientId: string | null;
    contractFormTitle?: string;
    paymentOptionsFormTitle?: string;
    practiceName: string;
    website: string;
    hasLetterhead: boolean;
    letterheadMarginTop?: number;
    letterheadMarginBottom?: number;
    letterheadIncludesOffice: boolean;
    isSplitTreatmentPlanAllowed: boolean;
    truthInLendingStatement?: string;
    transferOutReconciliation?: string;
    shouldTransferIncludeInitialsLine: boolean;
    maintenanceCare?: string;
    shouldMaintenanceIncludeInitialsLine: boolean;
    noncompliance?: string;
    shouldNoncomplianceIncludeInitialsLine: boolean;
    shouldAlwaysShowNoncompliance: boolean;
    insuranceEstimate?: string;
    shouldAlwaysShowInsDisclaimer: boolean;
    shouldIncludeInitialsLine: boolean;
    paymentInformation?: string;
    externalFinancingDetails?: string;
    externalFinancingDisclaimer?: string;
    treatmentFeeDetails?: string;
    feeGuaranteeDate?: string;
    footer?: string;
    contractFooter?: string;
    includeContractOfficeInfoFooter: boolean;
    includeOfficeInfoFooter: boolean;
    shouldFooterIncludeInitialsLine: boolean;
    posUpfrontChargesDetails?: string;
    contractUpfrontChargesDetails?: string;
    shouldUpfrontChargesIncludeInitialsLine: boolean;
    shouldIncludeWitnessSignature: boolean;
    downPaymentDueDescription?: string;
    downPayment2DueDescription?: string;
    recurringPaymentStartDateDescription?: string;
    recurringPaymentDateDescription?: string;
    recurringPaymentDateShouldUseDescription: boolean;
    recurringPaymentDateParenthetical?: string;
    //below is a calc rule on configuration only
    arePaymentDatesSpecified?: boolean;
    canSignAfterSliders?: boolean;
    //below is detail with forms
    informedConsentForm?: InformedConsent;
    conditionalTexts?: ConditionalText[];
    autoPaymentForm?: AutoPaymentForm;
    customEsignFiles?: CustomForm[];
}

export interface Client {
    clientId: string;
    companyName: string;
}

export interface CustomEsignField {
    dataFieldJson: JSON | string;
    dependentFieldJson?: JSON | string;
    fieldName?: string;
    fontColorBlue?: number | null;
    fontColorGreen?: number | null;
    fontColorRed?: number | null;
    fontSize?: number | null;
    formFieldId: string;
    formId: string;
    height?: number | null;
    overrideValue: boolean;
    pageLocationInOriginalDocument?: number | null;
    signingParty?: number | null;
    textFieldDefaultValue?: string | null;
    width?: number | null;
    xCoordinate?: number | null;
    yCoordinate?: number | null;
}

export interface IntakeForm {
    clientId?: string;
    clients?: Client[] | null;
    directEsignFormUrl?: string | null;
    embeddedTemplateSessionURL?: string | null;
    enableForm: boolean;
    fileName: string;
    formId: string;
    fileUploadId?: string;
    initialFormId?: string;
    formType?: number | null;
    isSharedLibraryForm?: boolean;
    lastSyncedFieldsTimestamp?: Date | string;
    numberOfSigners?: number | null;
    odpClientFacingFormUrl?: string | null;
    orderId?: number | null;
    pageHeight?: number | null;
    pageWidth?: number | null;
    templateId?: number | null;
    totalPageNumber?: number | null;
    intakeFormFields?: CustomEsignField[] | null;
    appliesToAll?: boolean;
    type?: string;
    requested?: boolean;
    prefillFieldNames?: string;
}

export interface EsignPDFUpload {
    charSet: string | null;
    clientId: string;
    createdDate: Date | string;
    data: string;
    fileUploadId: string;
    lastModifiedBy: Date | string;
    lastModifiedDate: Date | string;
    mediaType: string;
    name: string;
    size: number;
}

export interface AnytimeForm {
    clientId?: string;
    clients?: Client[] | null;
    directEsignFormUrl?: string | null;
    embeddedTemplateSessionURL?: string | null;
    enableForm: boolean;
    fileName: string;
    fileUploadId?: string;
    formId: string;
    formType?: number | null;
    initialFormId?: string;
    isSharedLibraryForm?: boolean;
    lastSyncedFieldsTimestamp?: Date | string;
    numberOfSigners?: number;
    odpClientFacingFormUrl?: string | null;
    orderId: number;
    pageHeight?: number;
    pageWidth?: number;
    templateId?: number;
    totalPageNumber?: number;
    appliesToAll?: boolean;
    additionalCharges?: AdditionalCharge[];
    treatmentClasses?: TreatmentClass[];
    treatmentTypes?: TreatmentType[];
    requested?: boolean;
    prefillFieldNames?: string;
}

export interface ConditionalText {
    conditionalTextId: string;
    documentId: number;
    fieldId: number | null;
    verbiage: string;
    treatmentTypes: TreatmentType[] | [];
    treatmentClasses: TreatmentClass[] | [];
}

export interface BankAcctType {
    bankAcctTypeId: number;
    name: string;
    description: string;
}


export interface PaymentFrequenciesData {
    paymentFrequencyId: number,
    name: string,
    monthlyMultiplier: number,
    adjective: string,
    noun: string,
    description: string
}

export interface AutoPaymentForm {
    formTitle?: string;
    acceptedPaymentInformation?: string;
    applicableForPayInFullContracts: boolean;
    authorizationStatement?: string;
    bankAccountTypeId?: number;
    bankAcctType?: BankAcctType;
    clientId: string;
    creditCardLabel?: string;
    declineStatement?: string;
    enableCreditCard: boolean;
    enableBankAccount: boolean;
    enableForm: boolean;
    enableSecurityCodeField: boolean;
    includeOfficeInfoFooter: boolean;
    introduction?: string;
    isFormRequired: boolean;
    recurPaymentDateInformation?: string;
    requireAddress: boolean;
    requireBankName: boolean;
    requireCardType: boolean;
    requirePhoneNumber: boolean;
    shouldIncludeDownPayments: boolean;
}

export interface ClientReminderForm {
    clientId: string;
    TxStartDocsSchedule?: string;
    PendingTxPlanSchedule?: string;
    IntakeFormsSchedule?: string;
    AnytimeFormsSchedule?: string;
}
export interface DashboardItem { total: number, type: string, key: string, subscription: string | undefined, disabled: boolean };

export interface SharedDocumentsFormUpdate {
    id: string;
    enableForm: boolean;
}

export interface InformedConsentSection {
    additionalCharges?: AdditionalCharge[] | string[];
    appliesToAll: boolean;
    clientId?: string;
    header?: string;
    informedConsentSectionId: string;
    isInitialsRequired: boolean;
    orderId: number;
    shouldInitial: boolean;
    treatmentClasses: TreatmentClass[] | string[];
    treatmentTypes: TreatmentType[] | string[];
    verbiage?: string;
}

export interface InformedConsent {
    clientId: string;
    enableConsentDoctorSignature: boolean;
    enableConsentWitnessSignature: boolean;
    enableForm: boolean;
    includeOfficeInfoFooter: boolean;
    formTitle?: string;
    introduction?: string;
    sections: InformedConsentSection[];
    shouldAppendAaoLogo: boolean;
}

export interface CustomForm {
    additionalCharges?: AdditionalCharge[] | string[];
    appliesToAll: boolean;
    clientId?: string;
    customEsignFileFields?: CustomEsignField[] | null;
    embeddedTemplateSessionURL?: string;
    enableForm: boolean;
    fileName: string;
    formId: string;
    initialFormId?: string;
    lastSyncedFieldsTimestamp?: Date | string;
    numberOfSigners?: number;
    orderId: number;
    pageHeight?: number;
    pageWidth?: number;
    templateId?: number;
    totalPageNumber?: number;
    treatmentClasses?: TreatmentClass[] | string[];
    treatmentTypes?: TreatmentType[] | string[];
    fileUploadId?: string;
}

export interface CalculationRuleType {
    calculationRuleTypeId: number;
    name: string;
}

export interface rtnCalculationRules {
    success: boolean;
    calculationRules?: CalculationRule[];
}

export interface CalculationRule {
    amount?: number | null;
    calculationRuleId?: string;
    calculationRuleTypeId: number;
    calculationRuleType?: CalculationRuleType;
    hasPpoAmount?: boolean | null;
    incrementOrDecrement?: boolean | null;
    isAbsolute?: boolean | null;
    isPercentage?: boolean | null;
    ppoAmount?: number | null;
    treatmentTypeId: string | null;
    treatmentType?: TreatmentType | null;
    description?: string | null;
}

export interface TreatmentComplexity {
    isObsolete?: boolean;
    name: string;
    rank: number;
    treatmentClassId: string;
    treatmentComplexityId?: string;
    treatmentFeeByComplexities?: TreatmentFeeByComplexity[];
}

export interface TreatmentFeeByComplexity {
    amount?: number;
    isObsolete?: boolean;
    ppoUpchargeAmount?: number | null;
    treatmentClassId: string;
    treatmentComplexity: TreatmentComplexity;
    treatmentComplexityId: string;
    treatmentFeeByComplexityId: string;
    treatmentTypeId: string;
}

export interface TreatmentClass {
    bandingImpactId: number | null;
    isObsolete: boolean;
    linkedTreatmentClassId?: string | null;
    linkedTreatmentClass?: TreatmentClass | null | undefined;
    treatmentComplexities?: TreatmentComplexity[] | null | undefined;
    treatmentClassId: string;
    treatmentClassName: string;
    treatmentTypes?: TreatmentType[] | string[];
    links?: number;
}

export interface TreatmentType {
    isHybrid?: boolean;
    isObsolete?: boolean;
    name: string;
    rank: number;
    treatmentTypeId?: string;
    treatmentClasses?: TreatmentClass[];
    treatmentClassesIdx?: string[];
    treatmentFeeByComplexities?: TreatmentFeeByComplexity[];
    calculationRules?: CalculationRule[];
    treatmentFee?: number;
}

export interface PatientTreatmentType {
    hybridTreatmentType?: TreatmentType | null;
    hybridTreatmentTypeId?: string | null;
    linkedTreatmentFee?: number | null;
    patientTreatmentPlanId?: string;
    treatmentFee: number | null;
    treatmentTime: number | null;
    treatmentTimeMax?: number | null;
    treatmentType?: TreatmentType | null;
    treatmentTypeId: string;
}

export interface AdditionalCharge {
    hasMultiplier: boolean;
    hoverText?: string | null;
    isDefault: boolean;
    isObsolete: boolean;
    isUpfrontCharge: boolean;
    multiplierDescription?: string;
    productFeeAmount: number;
    productFeeId: string;
    productName: string;
    productTypeId: string;
}

export interface PatientAdditionalCharge {
    patientAdditionalChargeId?: string;
    patientTreatmentPlanId?: string;
    productFeeId: string;
    productName: string;
    productFeeAmount: number;
    isUpfrontCharge: boolean;
    hasMultiplier: boolean;
    multiplierDescription?: string;
    productQuantity?: number;
    hoverText?: string | null;
    isDefault: boolean;
    isObsolete: boolean;
    isChecked?: boolean; //optional for use on frontend is ignored on backend
    productFee?: ProductFee;
    patientNonApplicableProdTxTypeCombos?: PatientNonApplicableProdTxTypeCombo[];
    productTotalFee?: number; //calculated on backend
    productType?: ProductType;
    productTypeId?: string;
}

export interface PatientProductType {
    isObsolete: boolean;
    name: string;
    productFees: PatientAdditionalCharge[];
    productTypeId: string;
}

export interface PatientPpoRate {
    patientPpoRateId?: string;
    clientId?: string;
    patientTreatmentPlanId?: string;
    ppoRateId: string;
    groupName?: string;
    name?: string;
    isAmountPercentage: boolean;
    isFeeReplacement: boolean;
    amount: number;
    ppoRateApplicableAdditionalCharges?:
        | PpoRateApplicableAdditionalCharge[]
        | AdditionalCharge[]
        | string[];
    ppoRateApplicableOffices?: PpoRateApplicableOffice[] | Office[] | string[];
    ppoRateApplicableTreatmentClasses?:
        | PpoRateApplicableTreatmentClass[]
        | TreatmentClass[]
        | string[];
    ppoRateApplicableTreatmentTypes?:
        | PpoRateApplicableTreatmentType[]
        | TreatmentType[]
        | string[];
}

export interface PatientNonApplicableProdTxTypeCombo {
    patientAdditionalChargeId: string;
    treatmentTypeId: string;
    //  patientAdditionalCharge?: PatientAdditionalCharge; //not sure if these will be used here
    //  treatmentType?: TreatmentType; //not sure if these will be used here
}

// export interface PatientPpoRateApplicableAdditionalCharge {
//     patientPpoRateId: string;
//     productFeeId: string;
// }
//
// export interface PatientPpoRateApplicableOffice {
//     patientPpoRateId: string;
//     officeId: string;
// }
//
// export interface PatientPpoRateApplicableTreatmentClass {
//     patientPpoRateId: string;
//     treatmentClassId: string;
// }
//
// export interface PatientPpoRateApplicableTreatmentType {
//     patientPpoRateId: string;
//     treatmentTypeId: string;
// }

export interface PatientVisit {
    doctor?: Doctor | null;
    doctorId?: string | null;
    examDate?: string | Date | null;
    office?: Office | null;
    officeId?: string;
    patientId: string;
    patientTreatmentPlanId?: string;
    treatmentCoordinator?: TreatmentCoordinator | null;
    treatmentCoordinatorId?: string | null;
}

export interface PatientSplitTreatmentPlanPortion {
    createdDate?: string | Date;
    lastModifiedBy?: string;
    lastModifiedDate?: string | Date
    isRespPartyAmtPercentage: boolean;
    isUpfrontChargesAmtPercentage: boolean;
    patientResponsibleParty?:ResponsibleParty | PatientResponsibleParty | null;
    patientResponsiblePartyId: string;
    patientTreatmentPlanId: string;
    responsiblePartyAmount: number;
    upfrontChargesAmount: number;
    isObsolete?: boolean;
    acceptedOrDeclinedDate?: Date | string | null;
    status?: number;
}

export interface TreatmentPlan {
    bandingArchId?: string | null;
    bandingDate?: Date | string | null;
    createdDate?: Date | string;
    isObsolete: boolean;
    lastAskedToRegenerateDate?: Date | string | null;
    lastModifiedBy?: string;
    lastModifiedDate?: string | null;
    notes?: string | null;
    patient?: Patient | null;
    patientAdditionalCharges?: PatientAdditionalCharge[];
    patientId: string;
    patientPpoRates?: PatientPpoRate[];
    patientResponsibleParties: ResponsibleParty[] | string[] | PatientResponsibleParty[];
    patientSplitTreatmentPlanPortions?: PatientSplitTreatmentPlanPortion[];
    patientTreatmentPlanId?: string;
    patientTreatmentTypes?: PatientTreatmentType[];
    patientVisit: PatientVisit;
    treatmentClass?: TreatmentClass | null;
    treatmentClassId?: string;
    treatmentComplexity?: TreatmentComplexity | null;
    treatmentComplexityId?: string;
    patientDiscounts?: PatientDiscount[];
    accountHolderId?: string;
    accountHolder?: ResponsibleParty | PatientResponsibleParty;
    acceptedOrDeclinedDate?: Date | string | null;
    status?: number;
}

export interface FileData {
    charSet?: string | null;
    clientId: string;
    createdDate: string | Date;
    data: string;
    fileUploadId: string;
    lastModifiedBy: string;
    lastModifiedDate: string;
    mediaType: string;
    name: string;
    size: number;
}

export interface PatientTreatmentPlanCommunicationsSubset {
    officeId?: string;
    officeName?: string;
    officeContactEmail?: string;
    officePhoneNumber?: string;
    hasLogo: boolean;
    imageBase64?: string;
    logoId?: string;
    pageWidthPercent: number;

}

export interface DeletedEsignDocuments {
    monthlyAllowedTotal?: number;
    monthlyDeletedCount?: number;
    monthlyRemainingCount?: number;
}
