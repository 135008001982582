import {inject, provide} from "@vue/composition-api";
import {PatientVisitsService} from "../services/interfaces";


export const patientVisitsServiceSymbol = Symbol();

export function providePatientVisitsService(
  service: PatientVisitsService
) {
  return provide(patientVisitsServiceSymbol, service);
}

export function usePatientVisitsService() {
  const patientVisitsService = inject<PatientVisitsService>(
      patientVisitsServiceSymbol
  );
  if (!patientVisitsService) {
    throw Error("No Patient Visits Service to Inject!!");
  }
  return patientVisitsService;
}
