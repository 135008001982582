<template>
  <div>
    <v-card class="text-center pa-1"
            v-shortkey="['enter']"
            @shortkey.prevent="submit"
    >
      <v-card-title class="justify-center display-1 mb-2">Welcome</v-card-title>
      <v-card-subtitle>Sign in to your V2 account</v-card-subtitle>

      <!-- sign in form -->
      <v-card-text>
        <v-form
            v-model="valid"
            ref="value"
            @submit.prevent="submit"

            :disabled="loading"
        >
          <v-text-field
              v-model.trim="fields.email"
              :rules="typeof fields.email === 'string' && !fields.email.length ? rules.email : [regexEmailRule()]"
              label="Email"
              name="email"
              type="email"
              outlined
              autofocus
              validate-on-blur
              ref="emailRef"
              @keydown.enter="$event.target.blur()"
          ></v-text-field>
          <v-text-field
              v-model="fields.password"
              :append-icon="showPassword ? '$eye' : '$eye-slash'"
              :rules="typeof fields.password === 'string' && !fields.password.length ? rules.password : [minLengthRule()]"
              :type="showPassword ? 'text' : 'password'"
              label="Password"
              name="password"
              outlined
              @click:append="showPassword = !showPassword"
              validate-on-blur
              ref="passwordRef"
              @keydown.enter="$event.target.blur()"
          ></v-text-field>
          <v-btn
              :loading="loading && statusLoggingIn"
              :disabled="loading"
              block
              x-large
              color="primary"
              @click="submit"
          >Login
          </v-btn
          >
          <div class="mt-5">
            <router-link to="/auth/forgot-password" :class="{'disabled' :loading}">
              Forgot your Password?
            </router-link>
          </div>
        </v-form>
      </v-card-text>
    </v-card>
  </div>
</template>

<script lang="ts">
/*
|---------------------------------------------------------------------
| Sign In Page Component
|---------------------------------------------------------------------
|
| Sign in template for user authentication into the application
|
*/
import {computed, defineComponent, reactive, ref, toRefs,} from "@vue/composition-api";
import {useStore} from "../../providers/use-store";
import {useRouter} from "../../providers/use-router";
import {SessionActions, SessionGetters} from "../../store/session";
import {useAccountService} from "../../providers/use-account-service";
import {FormDefinition} from "../../../main/src/components/forms/interfaces";
import {VForm} from "../../../main/src/components/forms/types";
import {minLengthRule, regexEmailRule, requiredRule,} from "../../../main/src/composables/validation";
import {SessionStatuses} from "../../store/constants";
import Vue from "vue";
import {User} from "../../store";


interface SingninForm extends FormDefinition {
  value: VForm | null;
  valid: boolean;
  fields: {
    email: string;
    password: string;
  };
  rules: {
    email: ((message?: string) => {})[];
    password: ((message?: string) => {})[];
  };
}

export default defineComponent({
  name: "SigninPage",
  setup() {
    const store = useStore();
    const router = useRouter();
    const service = useAccountService();
    const emailRef = ref<HTMLFormElement>();
    const passwordRef = ref<HTMLFormElement>();
    const loading = computed<boolean>(
        () => store.getters[SessionGetters.LOADING]
    );

    const statusLoggingIn = computed<boolean>(
        () =>
            store.getters[SessionGetters.STATUS] === SessionStatuses.LOGIN_STARTED
    );

    const status = computed<string | undefined>(
        () =>
            store.getters[SessionGetters.STATUS]
    );
    const showPassword = ref<boolean>(false);

    const user = computed<User>(
        () =>
            store.getters[SessionGetters.USER]
    );
    const form = reactive<SingninForm>({
      value: null,
      valid: false,
      fields: {
        email: "",
        password: "",
      },
      rules: {
        email: [requiredRule(), regexEmailRule()],
        password: [requiredRule(), minLengthRule()],
      },
    });

    async function submit() {
      await Vue.nextTick();
      form.value?.validate();
      await Vue.nextTick();
      if (form.valid) {
        const values = {
          ...form.fields,
        };
        await store.dispatch(SessionActions.LOGIN, {
          email: values.email,
          password: values.password,
          service,
        });

        if (status.value === SessionStatuses.LOGIN_SUCCESSFUL) {
          form?.value?.reset();
        }
      }
    }

    return {
      showPassword,
      ...toRefs(form),
      submit,
      loading,
      statusLoggingIn,
      emailRef,
      passwordRef,
      regexEmailRule,
      minLengthRule,
      status,
    };
  },
});
</script>
<style lang="scss" scoped>
.disabled {
  opacity: 0.5;
  pointer-events: none;
}
</style>