import {computed} from "@vue/composition-api";
import {SessionGetters} from "@shared/store/session";
import {AppNames} from "@shared/store/constants";
import {useStore} from "@shared/providers";
import config from "../../configs";
import {AppType} from "@shared/store";

export function useRolesToFilterApps() {
    const store = useStore();
    let apps:AppType[] = config.toolbar.apps;
    const configManager = computed<boolean>(
        () => store.getters[SessionGetters.IS_CONFIG_MANAGER]
    );
    const userManager = computed<boolean>(
        () => store.getters[SessionGetters.IS_USER_MANAGER]
    );
    const canViewPatients = computed<boolean>(
        () => store.getters[SessionGetters.CAN_VIEW_PATIENTS]
    );
    const admin = computed<boolean>(
        () => store.getters[SessionGetters.IS_ADMIN]
    );
    const extInsuranceVerification = computed<boolean>(
        () => store.getters[SessionGetters.IS_EXT_INSURANCE_VERIFICATION_ADMIN]
    );

    if (!configManager.value) {
        apps = apps.filter(
            (app) => app.text !== AppNames.CONFIGURATION
        );
    }
    if (!canViewPatients.value || (extInsuranceVerification.value && !admin.value)) {
        apps = apps.filter((app) => app.text !== AppNames.PATIENTS);
    }
    if (!userManager.value) {
        apps = apps.filter((app) => app.text !== AppNames.USERS);
    }
    if(!extInsuranceVerification.value) {
        apps = apps.filter((app) => app.text !== AppNames.INSURANCE_VERIFICATION);
    }
    if (!admin.value) {
        apps = apps.filter((app) => app.text !== AppNames.ADMIN);
    }

    return apps || [];
  }