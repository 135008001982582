import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"persistent":"","max-width":"500"},scopedSlots:_vm._u([(!_vm.hideActivatorButton)?{key:"activator",fn:function(ref){
var on = ref.on;
return [_vm._t("activator-custom",null,{"on":on}),(!_vm.$scopedSlots['activator-custom'] || _vm.$slots['activator-custom'])?_c(VBtn,_vm._g({class:_vm.actionClass,attrs:{"type":"button","disabled":_vm.loading || _vm.disable,"elevation":_vm.actionElevation,"color":_vm.actionColor,"icon":!!_vm.actionIcon && !_vm.actionFab,"fab":_vm.actionFab,"text":!_vm.actionIcon && _vm.actionText,"small":(!_vm.actionFab ? !!_vm.actionIcon || _vm.actionSmall : _vm.actionFab && !_vm.actionSmall) && !_vm.actionLarge,"x-small":(_vm.actionFab && _vm.actionSmall) && !_vm.actionLarge,"outlined":!!_vm.actionOutlined,"rounded":"","loading":_vm.actionLoading,"large":_vm.dark || _vm.actionLarge,"title":_vm.btnTitle}},on),[(!!_vm.actionIcon)?_c(VIcon,{attrs:{"small":""}},[_vm._v(_vm._s(_vm.actionIcon))]):_c('span',[_vm._v(_vm._s(_vm.actionButtonText))])],1):_vm._e()]}}:null],null,true),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[(_vm.displayTitleOverride)?_c(VCardTitle,[_vm._v(_vm._s(_vm.displayTitleOverride))]):_c(VCardTitle,[_vm._v(_vm._s(_vm.action)+" "+_vm._s(_vm.type))]),_c(VCardText,[_c(VContainer,[_c(VRow,[_c(VCol,{directives:[{name:"sanitize",rawName:"v-sanitize.inline",value:(_vm.text),expression:"text",modifiers:{"inline":true}}],staticClass:"d-flex align-center"})],1),(!!_vm.$slots['additional-text'])?_c(VRow,[_c(VCol,{staticClass:"d-flex align-center pt-0"},[_vm._t("additional-text")],2)],1):_vm._e()],1)],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"primary","disabled":_vm.loading,"text":""},on:{"click":function($event){return _vm.cancelDialog()}}},[_vm._v(_vm._s(_vm.dismissButtonText))]),_c(VBtn,{attrs:{"color":"primary","disabled":_vm.loading || _vm.disable,"loading":_vm.actionLoading,"rounded":""},on:{"click":function($event){return _vm.submit()}}},[_vm._v(_vm._s(_vm.actionButtonText)+" ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }