import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VMenu,{attrs:{"offset-y":"","left":"","transition":"slide-y-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({staticClass:"elevation-2",attrs:{"icon":""}},on),[_c(VBadge,{attrs:{"color":!!_vm.currentUser ? 'success' : 'error',"dot":"","bordered":"","offset-x":"10","offset-y":"10"}},[(!!_vm.currentUser && !!_vm.name)?_c('avatar',{attrs:{"username":_vm.name,"size":40}}):_c(VAvatar,{attrs:{"size":"40"}},[_c(VIcon,[_vm._v(" $user-circle ")])],1)],1)],1)]}}])},[_c(VList,{attrs:{"dense":"","nav":""}},[_vm._l((_vm.menu),function(item,index){return _c(VListItem,{key:index,attrs:{"to":item.link,"exact":item.exact,"disabled":item.disabled,"link":""}},[_c(VListItemIcon,{staticClass:"align-center"},[_c(VIcon,{class:{ 'grey--text': item.disabled },attrs:{"small":""}},[_vm._v(_vm._s(item.icon)+" ")])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(item.text))])],1)],1)}),((_vm.menu || []).length)?_c(VDivider,{staticClass:"my-1"}):_vm._e(),_c(VListItem,{on:{"click":_vm.signOut}},[_c(VListItemIcon,{staticClass:"align-center"},[_c(VIcon,{attrs:{"small":""}},[_vm._v("$sign-out")])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v("Log Out")])],1)],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }