import {inject, provide} from "@vue/composition-api";
import {LogoService} from "../services/interfaces";

export const logoServiceSymbol = Symbol();

export function provideLogoService(service: LogoService) {
  return provide(logoServiceSymbol, service);
}

export function useLogoService() {
  const logoService = inject<LogoService>(logoServiceSymbol);
  if (!logoService) {
    throw Error("No Logo Service to Inject!!");
  }
  return logoService;
}
