import {inject, provide} from "@vue/composition-api";
import {MaintenanceService} from "../services/interfaces";

export const maintenanceServiceSymbol = Symbol();

export function provideMaintenanceService(
  service: MaintenanceService
) {
  return provide(maintenanceServiceSymbol, service);
}

export function useMaintenanceService() {
  const maintenanceService = inject<MaintenanceService>(
      maintenanceServiceSymbol
  );
  if (!maintenanceService) {
    throw Error("No Maintenance Service to Inject!!");
  }
  return maintenanceService;
}
