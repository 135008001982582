<template v-if="loading">
<!--  <v-container class="fill-height d-flex justify-center" fluid>-->
    <img
        style="
          opacity: 0.5;
          position: absolute;
          top: calc(50% - 50px);
          left: calc(50% - 50px);
        "
        src="/images/loading.gif"
        alt="loading"
    />
<!--  </v-container>-->
</template>

<script lang="ts">
import {defineComponent} from "@vue/composition-api";

export default defineComponent({
  name: "PageLoadingIcon",
  props: {
    loading: {
      type: Boolean,
      default: true
    },
  },
});
</script>
