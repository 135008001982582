import {inject, provide} from "@vue/composition-api";
import {UserService} from "../services/interfaces";

export const userServiceSymbol = Symbol();

export function provideUserService(service: UserService) {
  return provide(userServiceSymbol, service);
}

export function useUserService() {
  const userService = inject<UserService>(userServiceSymbol);
  if (!userService) {
    throw Error("No User Service to Inject!!");
  }
  return userService;
}
