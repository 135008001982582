import {inject, provide} from "@vue/composition-api";
import {DoctorService} from "../services/interfaces";

export const doctorServiceSymbol = Symbol();

export function provideDoctorService(
  service: DoctorService
) {
  return provide(doctorServiceSymbol, service);
}

export function useDoctorService() {
  const doctorService = inject<DoctorService>(
    doctorServiceSymbol
  );
  if (!doctorService) {
    throw Error("No Doctor Service to Inject!!");
  }
  return doctorService;
}