import {inject, provide} from "@vue/composition-api";
import {InsuranceVerificationService} from "../services/interfaces";

export const insuranceVerificationServiceSymbol = Symbol();

export function provideInsuranceVerificationService(
  service: InsuranceVerificationService
) {
  return provide(insuranceVerificationServiceSymbol, service);
}

export function useInsuranceVerificationService() {
  const insuranceVerificationService = inject<InsuranceVerificationService>(
      insuranceVerificationServiceSymbol
  );
  if (!insuranceVerificationService) {
    throw Error("No Insurance Verification Service to Inject!!");
  }
  return insuranceVerificationService;
}
