import {inject, provide} from "@vue/composition-api";
import {PatientTreatmentPlanAdditionalChargesService} from "../services/interfaces";


export const patientTreatmentPlanAdditionalChargesServiceSymbol = Symbol();

export function providePatientTreatmentPlanAdditionalChargesService(
  service: PatientTreatmentPlanAdditionalChargesService
) {
  return provide(patientTreatmentPlanAdditionalChargesServiceSymbol, service);
}

export function usePatientTreatmentPlanAdditionalChargesService() {
  const patientTreatmentPlanAdditionalChargesService = inject<PatientTreatmentPlanAdditionalChargesService>(
      patientTreatmentPlanAdditionalChargesServiceSymbol
  );
  if (!patientTreatmentPlanAdditionalChargesService) {
    throw Error("No Patient Treatment Plan Additional Charges Service to Inject!!");
  }
  return patientTreatmentPlanAdditionalChargesService;
}
