import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VMenu,{attrs:{"offset-y":"","left":"","transition":"slide-y-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({attrs:{"icon":"","title":"apps"}},on),[_c(VIcon,[_vm._v("$th")])],1)]}}])},[_c(VList,{staticClass:"d-flex flex-row flex-wrap pa-2 text-center justify-center align-content-center",staticStyle:{"max-width":"330px"},attrs:{"rounded":""}},_vm._l((_vm.apps),function(app){return _c(VListItem,{directives:[{name:"shortkey",rawName:"v-shortkey.avoid",modifiers:{"avoid":true}}],key:app.link,staticClass:"ma-0 pa-1 app-tile",staticStyle:{"flex":"0 50%"},attrs:{"to":app.link,"disabled":_vm.disableApp(app.text),"link":"","inactive":_vm.disableApp(app.text)}},[_c(VIcon,{directives:[{name:"shortkey",rawName:"v-shortkey.avoid",modifiers:{"avoid":true}}],attrs:{"color":_vm.disableApp(app.text) ? '' : 'primary',"disabled":_vm.disableApp(app.text)},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.clickNavigateTo($event, app.link)}}},[_vm._v(_vm._s(app.icon))]),_c('div',{staticClass:"font-weight-bold ma-0 mt-1"},[_vm._v(_vm._s(app.text))]),_c('div',{staticClass:"caption"},[_vm._v(" "+_vm._s(app.subtitleKey ? app.subtitleKey : app.subtitle)+" ")])],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }