import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.$vuetify.breakpoint.width >= 768 && _vm.$vuetify.breakpoint.width < 1264)?_c('div',[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.$vuetify.breakpoint.width >= 800)?_c(VBtn,_vm._g(_vm._b({directives:[{name:"shortkey",rawName:"v-shortkey.avoid",modifiers:{"avoid":true}}],attrs:{"icon":"","to":{name: 'dashboard'}}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("$home")])],1):_vm._e()]}}],null,false,2713551575)},[_c('span',[_vm._v("Dashboard")])]),(!!_vm.subscription.enableIntakeAndAnytimeForms)?_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(!!_vm.subscription.enableIntakeAndAnytimeForms)?_c(VBtn,_vm._g(_vm._b({directives:[{name:"shortkey",rawName:"v-shortkey.avoid",modifiers:{"avoid":true}}],attrs:{"icon":"","to":{name: 'patients-additional-forms-create'}}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("$file-plus")])],1):_vm._e()]}}],null,false,2945593931)},[_c('span',[_vm._v("Send Intake/Anytime Form")])]):_vm._e(),_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({directives:[{name:"shortkey",rawName:"v-shortkey.avoid",modifiers:{"avoid":true}}],attrs:{"icon":"","to":{name: 'patients-create'}}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("$user-plus")])],1)]}}],null,false,2377743234)},[_c('span',[_vm._v("Create Patient")])]),_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({directives:[{name:"shortkey",rawName:"v-shortkey.avoid",modifiers:{"avoid":true}}],attrs:{"icon":"","to":{name: 'patients'}}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("$users")])],1)]}}],null,false,77693999)},[_c('span',[_vm._v("Patients")])])],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }