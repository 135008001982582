import {ClientConfigurationSettingsService} from "../services/interfaces";
import {inject, provide} from "@vue/composition-api";

export const clientConfigurationSettingsServiceSymbol = Symbol();

export function provideClientConfigurationSettingsService(service: ClientConfigurationSettingsService) {
  return provide(clientConfigurationSettingsServiceSymbol, service);
}

export function useClientConfigurationSettingsService() {
  const clientConfigurationSettingsService = inject<ClientConfigurationSettingsService>(clientConfigurationSettingsServiceSymbol);
  if (!clientConfigurationSettingsService) {
    throw Error("No Client Configuration Settings Service to Inject!!");
  }
  return clientConfigurationSettingsService;
}
