import {TreatmentComplexitiesService} from "../services/interfaces";
import {inject, provide} from "@vue/composition-api";

export const treatmentComplexitiesServiceSymbol = Symbol();

export function providetreatmentComplexitiesService(
  service: TreatmentComplexitiesService
) {
  return provide(treatmentComplexitiesServiceSymbol, service);
}

export function useTreatmentComplexitiesService() {
  const treatmentComplexitiesService = inject<TreatmentComplexitiesService>(
    treatmentComplexitiesServiceSymbol
  );
  if (!treatmentComplexitiesService) {
    throw Error("No Treatment Complexities Service to Inject!!");
  }
  return treatmentComplexitiesService;
}
