<template>
  <v-expand-transition
     >
    <div  v-show="!menuItem.subscription || (subscriptionsEnabled && Array.isArray(subscriptionsEnabled) && subscriptionsEnabled.includes(menuItem.subscription))"
    >
      <v-list-item
          v-if="!menuItem.items"
          :input-value="menuItem.value"
          :to="menuItem.link"
          :exact-path="menuItem.exact"
          :disabled="menuItem.disabled"
          :active-class="currentRoute === menuItem.link ? 'primary--text' : 'font-weight-bold'"
          link
          :class="xSmall ? 'menu-level-3-padding-left' : ''"
      >
        <v-list-item-icon :style="{'align-items':'center!important','flex-direction': 'row', 'text-align': 'center'}"
                          :class="!!menuItem.subTitle2 ? 'pt-3' : (!!menuItem.subTitle ? 'pt-1' : '')">
          <v-icon class="d-flex justify-center" :style="{'min-width': '27px'}" v-if="menuItem.icon && !subgroup"
                  :small="small" :x-small="xSmall" :class="{ 'grey--text': menuItem.disabled }">
            {{ menuItem.icon }}
          </v-icon>
          <font-awesome-icon v-else-if="menuItem.add" size="sm" :icon="['fal', 'plus']"
                             transform="shrink-2"/>
          <font-awesome-icon v-else size="xs" :icon="['fal', 'circle']"
                             transform="shrink-4"/>
        </v-list-item-icon>
        <v-tooltip v-if="small || xSmall" bottom :nudge-left="24" :nudge-top="8" :open-delay="1000">
          <template v-slot:activator="{ on:tooltip }">
            <v-list-item-content v-on="tooltip">
              <v-list-item-title
                  :class="{'font-weight-bold primary--text' : !subgroup && !xSmall && currentRoute === menuItem.link}">
                {{ menuItem.key ? (menuItem.key) : menuItem.text }}
              </v-list-item-title>
              <v-list-item-subtitle v-if="menuItem.subTitle" :class="menuItem.subTitle2 ? 'mb-0' : ''">
                <small>{{ menuItem.subTitle }}</small>
              </v-list-item-subtitle>
              <v-list-item-subtitle v-if="menuItem.subTitle2">
                <small><small>{{ menuItem.subTitle2 }}</small></small>
              </v-list-item-subtitle>
            </v-list-item-content>
          </template>
          <div class="pa-0">
            <p class="ma-0 pa-0">{{ menuItem.key ? (menuItem.key) : menuItem.text }}</p>
            <p class="ma-0 pa-0 body-1" v-if="menuItem.subTitle"><small>{{ menuItem.subTitle }}</small></p>
            <p class="ma-0 pa-0 body-2" v-if="menuItem.subTitle2"><small>{{ menuItem.subTitle2 }}</small></p>
          </div>
        </v-tooltip>
        <v-list-item-content v-else>
          <v-list-item-title
              :class="{'font-weight-bold primary--text' : !subgroup && !xSmall && currentRoute === menuItem.link}">
            {{ menuItem.key ? (menuItem.key) : menuItem.text }}
          </v-list-item-title>
          <v-list-item-subtitle v-if="menuItem.subTitle" :class="menuItem.subTitle2 ? 'mb-0' : ''">
            <small>{{ menuItem.subTitle }}</small>
          </v-list-item-subtitle>
            <v-list-item-subtitle v-if="menuItem.subTitle2">
                <small><small>{{ menuItem.subTitle2 }}</small></small>
            </v-list-item-subtitle>
        </v-list-item-content>

      </v-list-item>

      <v-list-group
          v-else
          :value="expand ? true : menuItem.regex ? menuItem.regex.test(routePath) : false"
          :disabled="menuItem.disabled"
          :sub-group="subgroup"
          :to="menuItem.link"
          active-class="font-weight-bold"
          link
          :color="(currentRoute === menuItem.link || menuItem.regex && menuItem.regex.test(routePath)) ? 'primary' : ''"
          append-icon="$circle-chevron-down"
          :class="{'sub-group-icon' : subgroup && menuItem.icon && menuItem.link,'primary--text': (menuItem.regex && menuItem.regex.test(routePath)) || currentRoute === menuItem.link}"
      >
        <template v-slot:activator>
          <v-list-item-icon :style="{'align-items':'center'}" v-if="!subgroup">
            <v-badge :value="typeof menuItem.count === 'number' && menuItem.count" icon :content="menuItem.count"
                     offset-x="5" offset-y="5" :color="currentRoute === menuItem.link || menuItem.regex && menuItem.regex.test(routePath) ? 'primary' : 'grey'">
              <v-icon class="d-flex justify-center" :style="{'min-width': '27px'}" :small="small"
                      :x-small="xSmall" :color="(currentRoute === menuItem.link || (menuItem.regex && menuItem.regex.test(routePath))) ? 'primary' : ''">
                {{ menuItem.icon || '' }}
              </v-icon>
            </v-badge>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title
                :class="{'font-weight-bold primary--text' : ((!subgroup && !xSmall && (currentRoute === menuItem.link)) || (menuItem.regex && menuItem.regex.test(routePath)))}">
              {{ menuItem.key ? (menuItem.key) : menuItem.text }}
            </v-list-item-title>
          </v-list-item-content>
        </template>
        <template v-slot:appendIcon>
          <v-tooltip bottom max-width="300px" v-if="subgroup && menuItem.icon && menuItem.link">
            <template v-slot:activator="{ on }">
              <v-btn
                  v-on="on"
                  class="d-flex justify-content-end align-self-center pr-0"
                  @click.stop="navigateTo(menuItem.link)"
                  :disabled="currentRoute.includes(menuItem.link)"
                  link
                  x-small icon v-if="subgroup && menuItem.icon && menuItem.link">
                <v-icon x-small>{{ menuItem.icon || '' }}</v-icon>
              </v-btn>
            </template>
            <span> {{ menuItem.key ? `open ${menuItem.key}` : `open ${menuItem.text}` }}</span>
          </v-tooltip>
        </template>
        <slot></slot>
      </v-list-group>
    </div>
  </v-expand-transition>
</template>

<script lang="ts">
/*
|---------------------------------------------------------------------
| Navigation Menu Item Component
|---------------------------------------------------------------------
|
| Navigation items for the NavMenu component
|
*/
import {computed, defineComponent} from "@vue/composition-api";
import {useRouteComponents} from '../../composables/helpers/use-route-components';
import {ClientSubscriptionsGetters} from "@shared/store/financials/client-subscriptions";
import {useStore} from "@shared/providers";

export default defineComponent({
  name: "NavMenuItem",
  props: {
    menuItem: {
      type: Object,
      default: () => {
      }
    },
    subgroup: {
      type: Boolean,
      default: false
    },
    small: {
      type: Boolean,
      default: false
    },
    xSmall: {
      type: Boolean,
      default: false
    },
    expand: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const store = useStore();
    const {routePath, navigateTo} = useRouteComponents();
    const subscriptionsEnabled = computed<string[]>(() => store.getters[ClientSubscriptionsGetters.CLIENT_SUBSCRIPTIONS_ENABLED]);

    return {
      currentRoute: routePath,
      navigateTo,
      subscriptionsEnabled,
      routePath,
    }
  }
});
</script>
<style lang="scss" scoped>

::v-deep .v-list-group.primary--text > .v-list-group__header {

  .v-list-group__header__append-icon .v-icon {
    color: var(--v-primary-base) !important;
  }
  .v-list-group__header__prepend-icon .v-icon {
    color: var(--v-primary-base) !important;
  }
}

::v-deep .v-list-group.v-list-group--sub-group.sub-group-icon .v-list-group__header.v-list-item {
  .v-list-item .v-list-item__icon.v-list-group__header__append-icon {
    outline-color: inherit;
  }
}

::v-deep .v-list-group .v-list-group__header .v-list-item__icon.v-list-group__header__append-icon {
padding-top: 4px !important;
}

::v-deep .v-list-group.sub-group-icon.v-list-group--active > .v-list-group__header > .v-list-group__header__append-icon .v-icon {
  transform: none;
}

// handles the circle 3rd level amount it is indented
.menu-level-3-padding-left {
  padding-left: 24px !important;
}

</style>
