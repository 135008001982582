<template>
  <div v-if="$vuetify.breakpoint.width >= 768 && $vuetify.breakpoint.width < 1264">
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon
               v-if="$vuetify.breakpoint.width >= 800"
               v-bind="attrs"
               v-on="on"
               v-shortkey.avoid
               :to="{name: 'dashboard'}"
        >
          <v-icon>$home</v-icon>
        </v-btn>
      </template>
      <span>Dashboard</span>
    </v-tooltip>
    <v-tooltip bottom v-if="!!subscription.enableIntakeAndAnytimeForms">
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-if="!!subscription.enableIntakeAndAnytimeForms"
               icon
               v-bind="attrs"
               v-on="on"
               v-shortkey.avoid
               :to="{name: 'patients-additional-forms-create'}"
        >
          <v-icon>$file-plus</v-icon>
        </v-btn>
      </template>
      <span>Send Intake/Anytime Form</span>
    </v-tooltip>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon
               v-bind="attrs"
               v-on="on"
               v-shortkey.avoid
               :to="{name: 'patients-create'}"
        >
          <v-icon>$user-plus</v-icon>
        </v-btn>
      </template>
      <span>Create Patient</span>
    </v-tooltip>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon
               v-bind="attrs"
               v-on="on"
               v-shortkey.avoid
               :to="{name: 'patients'}"
        >
          <v-icon>$users</v-icon>
        </v-btn>
      </template>
      <span>Patients</span>
    </v-tooltip>
  </div>
</template>

<script lang="ts">
import {computed, defineComponent} from "@vue/composition-api";
import {useRouteComponents} from "../../composables/helpers";
import {AppType} from "@shared/store";
import {useRolesToFilterApps} from "../../composables/filters/use-roles-to-filter-apps";
import {AppNames} from "@shared/store/constants";
import {ClientSubscription} from "@shared/store/financials/interfaces";
import {ClientSubscriptionsGetters} from "@shared/store/financials/client-subscriptions";
import {useStore} from "@shared/providers";

/*
|---------------------------------------------------------------------
| Toolbar Apps Component
|---------------------------------------------------------------------
|
| Quickmenu for applications in the toolbar
|
*/
export default defineComponent({
  name: "ToolbarShortcuts",
  setup() {
    const store = useStore();
    const apps = computed<AppType[]>(() => useRolesToFilterApps() || []);
    const {clickNavigateTo, navigateTo, routeMeta} = useRouteComponents();
    const subscription = computed<ClientSubscription>(
        () => store.getters[ClientSubscriptionsGetters.CLIENT_SUBSCRIPTIONS]
    );

    function disableApp(appText: string) {
      if (routeMeta.value.type) {
        if (
            routeMeta.value.type.toLowerCase() ===
            AppNames.DASHBOARD.toLowerCase() &&
            routeMeta.value.layout
        ) {
          return false;
        }
        return routeMeta.value.type.toLowerCase() === appText.toLowerCase();
      }
      return false;
    }

    return {
      AppNames,
      routeMeta,
      apps,
      navigateTo,
      disableApp,
      clickNavigateTo,
      subscription,
    };
  },
});
</script>
<style lang="scss" scoped>
::v-deep .v-btn--active {
  color: var(--v-primary-base) !important;
}
</style>
