import {inject, provide} from "@vue/composition-api";
import {PatientEsignDocumentsService} from "../services/interfaces";


export const patientEsignDocumentsSymbol = Symbol();

export function providePatientEsignDocumentsService(
  service: PatientEsignDocumentsService
) {
  return provide(patientEsignDocumentsSymbol, service);
}

export function usePatientEsignDocumentsService() {
  const patientEsignDocumentsService = inject<PatientEsignDocumentsService>(
      patientEsignDocumentsSymbol
  );
  if (!patientEsignDocumentsService) {
    throw Error("No Patient Esign Documents Service to Inject!!");
  }
  return patientEsignDocumentsService;
}
