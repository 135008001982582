import {inject, provide} from "@vue/composition-api";
import {PatientTypeService} from "../services/interfaces";

export const patientTypeServiceSymbol = Symbol();

export function providePatientTypeService(service: PatientTypeService) {
  return provide(patientTypeServiceSymbol, service);
}

export function usePatientTypeService() {
  const patientTypeService = inject<PatientTypeService>(
    patientTypeServiceSymbol
  );
  if (!patientTypeService) {
    throw Error("No Patient Type Service to Inject!!");
  }
  return patientTypeService;
}
