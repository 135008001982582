<template>
  <div @click.stop="isSearch ? $emit('close') : null" class="d-flex flex-row flex-grow-0">
    <div class="ma-0 pa-0" :class="isSearch ? 'v-list-item__action pl-1' : 'padding-right-4'"
         v-if="(!isSearch || $vuetify.breakpoint.width >= 768)">

      <v-menu
          bottom
          transition="slide-y-transition"
      >
        <template v-slot:activator="{ on: menu, attrs }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on: tooltip }">
              <v-btn
                  icon
                  v-bind="attrs"
                  v-on="!$vuetify.breakpoint.mobile ? { ...tooltip, ...menu } : {...menu}"
                  small
                  class="px-0"
                  v-blur
              >
                <v-icon>$user-circle</v-icon>
              </v-btn>
            </template>
            <span>Patient Create Options, Archive/Restore, Delete</span>
          </v-tooltip>
        </template>
        <v-list dense nav>
          <v-subheader class="d-flex align-content-start">CREATE</v-subheader>
          <v-list-item
              v-show="subscription.enableFeePresentations"
              link
              :disabled="!linkInformation || !(linkInformation.responsiblePartyIds || []).length || (routeName && routeName.startsWith('patients-treatment-plans-create') && (routeParams || {}).id === patientId)"
              :to="{ name: 'patients-treatment-plans-create', params: { id: patientId}}"
              @click.stop="isSearch ? $emit('close') : null"
          >
            <v-list-item-title>Treatment Plan</v-list-item-title>
          </v-list-item>
          <v-list-item
              link
              :to="{ name: 'patients-additional-forms', params: { id: patientId}, query: { type: 'intake' }}"
              @click.stop="isSearch ? $emit('close') : null"
              :disabled="routeName && routeName.startsWith('patients-additional-forms') && (routeParams || {}).id === patientId"
          >
            <v-list-item-title>Intake Form</v-list-item-title>
          </v-list-item>
          <v-list-item
              v-show="subscription.enableIntakeAndAnytimeForms"
              :disabled="routeName && routeName.startsWith('patients-additional-forms') && (routeParams || {}).id === patientId"
              link
              :to="{ name: 'patients-additional-forms', params: { id: patientId}, query: { type: 'anytime' }}"
              @click.stop="isSearch ? $emit('close') : null"
          >
            <v-list-item-title>Anytime Form</v-list-item-title>
          </v-list-item>
          <v-subheader class="d-flex align-content-start">OPTIONS</v-subheader>
          <v-list-item :disabled="loading"
                       v-show="subscription.enableIntakeAndAnytimeForms"
                       link>
            <v-list-item-title
                @click="patient.isArchived ? restorePatient(patientId) : archivePatient(patientId)">
              {{ patient.isArchived ? 'Restore' : 'Archive' }}
            </v-list-item-title>
          </v-list-item>
          <v-list-item :disabled="loading"
                       link
                       class="ont-weight-regular"
          >
            <v-list-item-title
            >
              <confirmation-dialog
                  action="Delete"
                  :name="patient.fullName"
                  :actionLoading="deleting"
                  :loading="loading"
                  type="Patient"
                  @delete="deletePatient(patientId)"
              >

                <template v-slot:activator-custom="{on}">
                  <v-btn
                      text
                      plain
                      v-on="on"
                      color="error"
                      class="ma-0 pa-0 d-flex justify-content-start justify-start btn-fix-plain"
                  >
                    Delete
                  </v-btn>
                </template>
              </confirmation-dialog>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
    <div class="ma-0" :class="isSearch ? 'v-list-item__action pr-1' : 'padding-left-4'"
         v-if="(!isSearch || $vuetify.breakpoint.width > 800)">
      <div class="pa-0 d-flex justify-center align-self-center">
        <v-tooltip bottom>
          <template v-slot:activator="{ on:tooltip }">
            <v-btn icon
                   small
                   link
                   v-on="!$vuetify.breakpoint.mobile ? tooltip : undefined"
                   :to="{ name: 'patients-history', params: { id: patientId}}"
                   @click.stop="isSearch ? $emit('close') : null"
                   v-blur
            >
              <v-icon>
                $historyLight
              </v-icon>
            </v-btn>
          </template>
          <span>History</span>
        </v-tooltip>
      </div>
    </div>
  </div>
</template>

<script lang="ts">

import {computed, defineComponent} from "@vue/composition-api";
import {LinkInformation, ListPatient} from "@shared/store/patients/interfaces";
import {usePatientService, useStore} from "@shared/providers";
import {ClientSubscription} from "@shared/store/financials/interfaces";
import ConfirmationDialog from "@main/components/dialogs/ConfirmationDialog.vue";
import {PatientsActions, PatientsGetters} from "@shared/store/patients";
import {useRouteComponents} from "@main/composables/helpers";

interface Props {
  linkInformation: LinkInformation;
  patient: ListPatient;
  patientId: string;
  subscription: ClientSubscription;
  loading: boolean;
  isSearch: boolean;
}

export default defineComponent({
  name: "StatusActions",
  props: {
    linkInformation: Object() as () => LinkInformation,
    patient: Object() as () => ListPatient,
    patientId: String,
    subscription: Object,
    loading: Boolean,
    isSearch: Boolean,
  },
  components: {ConfirmationDialog},
  setup(props: Props, {emit}) {
    const store = useStore();
    const patientsService = usePatientService();
    const {routeName, routeQuery, routeParams, navigateToName} = useRouteComponents();

    const deleting = computed<boolean>(
        () => store.getters[PatientsGetters.DELETING]
    );
    const saving = computed<boolean>(
        () => store.getters[PatientsGetters.SAVING]
    );
    const restoring = computed<boolean>(
        () => store.getters[PatientsGetters.RESTORING]
    );

    async function archivePatient(id?: string) {
      const success = await store.dispatch(PatientsActions.BULK_ARCHIVE, {
        ids: [id] as string[],
        service: patientsService,
      });
      if (success && props?.isSearch) {
        emit("close");
      }

      if (success && routeParams.value?.id === props?.patientId) {
        navigateToName("patients-list");
      }
    }

    async function restorePatient(id?: string) {
      if (!id) return;
      const success = await store.dispatch(PatientsActions.BULK_RESTORE, {
        ids: [id] as string[],
        service: patientsService,
      });

      if (success && props?.isSearch) {
        emit("close");
      }
    }

    async function deletePatient(id?: string) {
      const success = await store.dispatch(PatientsActions.BULK_DELETE, {
        ids: [id] as string[],
        service: patientsService,
      });
      if (success && props?.isSearch) {
        emit("close");
      }

      if (success && routeParams.value?.id === props?.patientId) {
        navigateToName("patients-list");
      }
    }

    return {
      archivePatient,
      restorePatient,
      deletePatient,
      deleting,
      saving,
      restoring,
      routeName,
      routeParams,
      routeQuery,
    };
  },
});
</script>
<style lang="scss" scoped>

::v-deep .v-btn {
  border-radius: 4px !important;
}

::v-deep .v-btn--active {
  color: var(--v-primary-base) !important;
}

::v-deep .padding-left-4 {
  padding-left: 4px !important;
}

::v-deep .padding-right-4 {
  padding-right: 4px !important;
}
</style>