import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarItems } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
import Resize from 'vuetify/lib/directives/resize';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"fullscreen":"","hide-overlay":"","rounded":false,"transition":"dialog-bottom-transition"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VToolbar,{attrs:{"dark":"","flat":"","color":"primary","rounded":false}},[_c(VBtn,{attrs:{"icon":"","dark":""},on:{"click":_vm.close}},[_c(VIcon,[_vm._v("$close")])],1),_c(VToolbarTitle,[_vm._v(_vm._s(_vm.title))]),_c(VSpacer),_c(VToolbarItems,[_c(VBtn,{attrs:{"dark":"","text":""},on:{"click":_vm.close}},[_vm._v(" Close ")])],1)],1),_c(VCardText,{staticClass:"pa-0 ma-0"},[_c(VLayout,{directives:[{def: Resize,name:"resize",rawName:"v-resize",value:(_vm.onResize),expression:"onResize"}],staticClass:"d-flex justify-center align-center align-self-center align-content-center",attrs:{"fluid":""}},[(_vm.src)?_c('iframe',{staticStyle:{"min-width":"85vw","min-height":"80vh","width":"100%","border":"none"},style:('height: ' + (_vm.windowSize.y - (_vm.$vuetify.breakpoint.width > 959 ? 64 : 56)) + 'px !important'),attrs:{"src":_vm.src,"frameborder":"0","allowfullscreen":"","sandbox":"allow-scripts allow-same-origin allow-presentation","referrerpolicy":"no-referrer","allow":"autoplay 'self' https://youtube.com"}}):_vm._e()])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }