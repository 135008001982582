import {ProductFeesService} from "../services/interfaces";
import {inject, provide} from "@vue/composition-api";

export const productFeesServiceSymbol = Symbol();

export function provideProductFeesService(service: ProductFeesService) {
  return provide(productFeesServiceSymbol, service);
}

export function useProductFeesService() {
  const productFeesService = inject<ProductFeesService>(
    productFeesServiceSymbol
  );
  if (!productFeesService) {
    throw Error("No Product Fees Service to Inject!!");
  }
  return productFeesService;
}
