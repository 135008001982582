<template>
  <div class="d-flex flex-row flex-grow-1" @click.stop="">
    <status-item icon="$clipboard-user"
                 :customColor="linkInformation && ((linkInformation.responsiblePartyIds && linkInformation.responsiblePartyIds.length) || (linkInformation.firstName && linkInformation.firstName.length) || (linkInformation.lastName && linkInformation.lastName.length)) ? 'success' : 'warning'"
                 :linkInformation="linkInformation"
                 linkInformationKey="responsiblePartyIds"
                 :customIsBadgeVisible="true"
                 :routeTo="{ name: 'patients-edit', params: {'id': patientId} }"
                 :customTooltipText="(linkInformation && ((linkInformation.responsiblePartyIds && linkInformation.responsiblePartyIds.length) || (linkInformation.firstName && linkInformation.firstName.length) || (linkInformation.lastName && linkInformation.lastName.length)) ? ('View/Edit Patient' + ((linkInformation || {}).isArchived ? ' (Archived)' : '')) : ('Incomplete Patient Information' + ((linkInformation || {}).isArchived ? ' (Archived)' : ''))) "
                 :isSearch="isSearch"
                 :exact="false"
                 :exact-path="true"
                 @close="$emit('close')"
    ></status-item>
    <div> <!--   start tx plans   -->
      <status-item
          v-if="linkInformation && ((linkInformation.patientTreatmentPlans || []).length === 0) && linkInformation && ((linkInformation.responsiblePartyIds && linkInformation.responsiblePartyIds.length) || (linkInformation.firstName && linkInformation.firstName.length) || (linkInformation.lastName && linkInformation.lastName.length)) "
          icon="$briefcase"
          :linkInformation="linkInformation"
          linkInformationKey="patientTreatmentPlans"
          :routeTo="{ path: `/patients/${patientId}/treatment-plans/create`, name: 'patients-treatment-plans-create', params: {'id': patientId}}"
          customTooltipText="Create Treatment Plan"
          add
          :isSearch="isSearch"
          @close="$emit('close')"
      ></status-item>
      <status-item v-else
                   icon="$briefcase"
                   :linkInformation="linkInformation"
                   linkInformationKey="patientTreatmentPlans"
                   :routeTo="linkInformation && linkInformation.patientTreatmentPlans && linkInformation.patientTreatmentPlans.length ? { name: 'patients-treatment-plan-update', params: {'id': patientId, 'treatmentPlanId' : (linkInformation.patientTreatmentPlans[0] || {}).patientTreatmentPlanId}} : { name: 'patients-treatment-plans-create', params: {'id': patientId}}"
                   :customTooltipText="linkInformation && (linkInformation.patientTreatmentPlans || []).length ? (getTreatmentPlanStatusTextAndDate((linkInformation.patientTreatmentPlans[0] || {}).status, (linkInformation.patientTreatmentPlans[0] || {}).acceptedOrDeclinedDate, false, 'Go to the latest tx plan')) : ''"
                   :customColor="linkInformation && linkInformation.txStartEsignDocumentInformation && Array.isArray(linkInformation.txStartEsignDocumentInformation) && linkInformation.txStartEsignDocumentInformation.length && (linkInformation.txStartEsignDocumentInformation[0] || {}).status > 0 ? 'success' : linkInformation && linkInformation.patientTreatmentPlans && linkInformation.patientTreatmentPlans.length ? getTreatmentPlanStatusColor(((linkInformation.patientTreatmentPlans[0] || {}).status), 'primary') : 'grey'"
                   :isSearch="isSearch"
                   :isSplit="((linkInformation || {}).patientTreatmentPlans || []).length && ((((linkInformation || {}).patientTreatmentPlans || [])[0] || {}).patientSplitTreatmentPlanPortions || []).length > 0"
                   :active="routeName && routeName.startsWith('patients-treatment-plans-') && (routeParams || {}).id === patientId"
                   @close="$emit('close')"
      ></status-item>
    </div> <!--   end tx plans   -->
    <div> <!--   start sliders   -->
      <status-item
          v-if="((linkInformation ||{}).emailedSlidersDocumentInformation || []).length === 0 && ((linkInformation || {}).treatmentPlanIds || []).length"
          :icon="'$sliders'"
          :linkInformation="linkInformation"
          linkInformationKey="emailedSlidersDocumentInformation"
          :routeTo="linkInformation && linkInformation.treatmentPlanIds && linkInformation.treatmentPlanIds.length ? { name: 'patients-tx-plan-payment-options-basic', params: {'id': patientId, 'treatmentPlanId' : linkInformation.treatmentPlanIds[0]}} : undefined"
          type="sliders"
          :customColor="getTreatmentPlanStatusColor(((linkInformation || {}).patientTreatmentPlans || []).length && (((linkInformation || {}).patientTreatmentPlans || [])[0] || {}).status)"
          :customIsBadgeVisible="((linkInformation || {}).patientTreatmentPlans || []).length && (((linkInformation || {}).patientTreatmentPlans || [])[0] || {}).status > -1 ? true : false"
          :customButtonEnabled="true"
          :customTooltipText="((linkInformation || {}).patientTreatmentPlans || []).length && (((linkInformation || {}).patientTreatmentPlans || [])[0] || {}).status > -1 ? getTreatmentPlanStatusTextAndDate(((linkInformation || {}).patientTreatmentPlans || []).length && (((linkInformation || {}).patientTreatmentPlans || [])[0] || {}).status, ((linkInformation || {}).patientTreatmentPlans || []).length && (((linkInformation || {}).patientTreatmentPlans || [])[0] || {}).acceptedOrDeclinedDate) : 'View Payment Options / Sliders'"
          :isSearch="isSearch"
          :exact="false"
          @close="$emit('close')"
      ></status-item>
      <status-item
          v-else
          :icon="((linkInformation ||{}).emailedSlidersDocumentInformation || []).length && (linkInformation.emailedSlidersDocumentInformation[0] || {isReadOnly: false}).isReadOnly ? '$fileInvoiceDollarLight' : '$sliders'"
          :linkInformation="linkInformation"
          linkInformationKey="emailedSlidersDocumentInformation"
          :routeTo="linkInformation && linkInformation.treatmentPlanIds && linkInformation.treatmentPlanIds.length ? { name: 'patients-tx-plan-payment-options-basic', params: {'id': patientId, 'treatmentPlanId' : linkInformation.treatmentPlanIds[0]}} : undefined"
          :typeText="((linkInformation ||{}).emailedSlidersDocumentInformation || []).length && (linkInformation.emailedSlidersDocumentInformation[0] || {isReadOnly: false}).isReadOnly  ? 'Payment Options' : 'Sliders'"
          :customColor="getTreatmentPlanStatusColor(((linkInformation || {}).patientTreatmentPlans || []).length && (((linkInformation || {}).patientTreatmentPlans || [])[0] || {}).status)"
          :customTooltipText="((linkInformation || {}).patientTreatmentPlans || []).length && (((linkInformation || {}).patientTreatmentPlans || [])[0] || {}).status > -1 ? getTreatmentPlanStatusTextAndDate(((linkInformation || {}).patientTreatmentPlans || []).length && (((linkInformation || {}).patientTreatmentPlans || [])[0] || {}).status, ((linkInformation || {}).patientTreatmentPlans || []).length && (((linkInformation || {}).patientTreatmentPlans || [])[0] || {}).acceptedOrDeclinedDate) : ''"
          type="sliders"
          :exact="false"
          :isSearch="isSearch"
          @close="$emit('close')"
      ></status-item>
    </div> <!--   end sliders   -->
    <div> <!--   start tx start docs   -->
      <status-item
          v-if="((linkInformation ||{}).txStartEsignDocumentInformation || []).length === 0 && ((linkInformation || {}).treatmentPlanIds || []).length"
          :icon="'$fileSignature'"
          :linkInformation="linkInformation"
          linkInformationKey="emailedSlidersDocumentInformation"
          :routeTo="linkInformation && linkInformation.treatmentPlanIds && linkInformation.treatmentPlanIds.length ? { name: 'patients-tx-plan-tx-start-docs-basic', params: {'id': patientId, 'treatmentPlanId' : linkInformation.treatmentPlanIds[0]}} : undefined"
          :customIsBadgeVisible="false"
          :customButtonEnabled="true"
          :exact="false"
          customTooltipText="View Treatment Start Docs"
          :isSearch="isSearch"
          @close="$emit('close')"
      ></status-item>
      <status-item
          v-else
          :icon="linkInformation.txStartEsignDocumentInformation ? '$fileSignature' : linkInformation.emailedPdfDocumentInformation ? '$filePdfLight' : '$fileSignature'"
          :linkInformation="linkInformation"
          :linkInformationKey="linkInformation.txStartEsignDocumentInformation ? 'txStartEsignDocumentInformation' : linkInformation.emailedPdfDocumentInformation ? 'emailedPdfDocumentInformation' : 'txStartEsignDocumentInformation'"
          :type="linkInformation.txStartEsignDocumentInformation ? 'signing' : linkInformation.emailedPdfDocumentInformation ? 'emailing' : 'signing'"
          :typeText="linkInformation.txStartEsignDocumentInformation ? 'Tx Start Doc' : linkInformation.emailedPdfDocumentInformation ? 'Tx Start Doc PDF' : 'Tx Start Doc'"
          :routeTo="linkInformation && (!linkInformation.txStartEsignDocumentInformation || (linkInformation.txStartEsignDocumentInformation.length && linkInformation.txStartEsignDocumentInformation[0] && !linkInformation.txStartEsignDocumentInformation[0].hrefSrc.startsWith('https'))) ? ((linkInformation ||{}).treatmentPlanIds || []).length === 0 && ((linkInformation || {}).treatmentPlanIds || []).length ? { name: 'patients-tx-plan-tx-start-docs-basic', params: {'id': patientId, 'treatmentPlanId' : linkInformation.treatmentPlanIds[0]}} : undefined : undefined"
          :openNewWindowUrl="linkInformation.txStartEsignDocumentInformation && Array.isArray(linkInformation.txStartEsignDocumentInformation) && linkInformation.txStartEsignDocumentInformation.length && linkInformation.txStartEsignDocumentInformation[0].hrefSrc && typeof linkInformation.txStartEsignDocumentInformation[0].hrefSrc === 'string' && linkInformation.txStartEsignDocumentInformation[0].hrefSrc.startsWith('https') ? linkInformation.txStartEsignDocumentInformation[0].hrefSrc : ''"
          :isSearch="isSearch"
          :active="routeName && routeName.startsWith('patients-tx-plan-tx-start-docs') && (routeParams || {}).id === patientId"
          :exact="false"
          @close="$emit('close')"
      ></status-item>
    </div> <!--   end tx start docs   -->
    <v-divider class="mr-1"
               v-if="(subscription|| {}).enableIntakeAndAnytimeForms || linkInformation.intakeFormsDocumentsInformation || linkInformation.anytimeFormsDocumentsInformation"
               vertical
    ></v-divider>
    <div
        v-if="(subscription|| {}).enableIntakeAndAnytimeForms || linkInformation.intakeFormsDocumentsInformation || linkInformation.anytimeFormsDocumentsInformation">
      <!--   start intake forms  -->
      <status-item
          v-if="(subscription|| {}).enableIntakeAndAnytimeForms && (((linkInformation || {}).intakeFormsDocumentsInformation || []).length === 0)"
          icon="$fileUser"
          :linkInformation="linkInformation"
          linkInformationKey="intakeFormsDocumentsInformation"
          :routeTo="{ path: `/patients/${patientId}/additional-forms/create?type=intake`, name: 'patients-additional-forms', params: {'id': patientId}, query: {type: 'intake'}}"
          customTooltipText="Create Intake Form"
          :active="routeName && routeName.startsWith('patients-additional-forms') && ( !routeQuery || routeQuery.type !== 'anytime') && (routeParams || {}).id === patientId"
          add
          :isSearch="isSearch"
          @close="$emit('close')"
      ></status-item>
      <status-item
          v-else
          icon="$fileUser"
          :linkInformation="linkInformation"
          linkInformationKey="intakeFormsDocumentsInformation"
          typeText="Intake Form"
          type="signing"
          :openNewWindowUrl="(linkInformation.intakeFormsDocumentsInformation || []).length && (linkInformation.intakeFormsDocumentsInformation || [])[0].hrefSrc && typeof linkInformation.intakeFormsDocumentsInformation[0].hrefSrc === 'string' ? linkInformation.intakeFormsDocumentsInformation[0].hrefSrc : ''"
          :isSearch="isSearch"
          :active="routeName && routeName.startsWith('patients-additional-forms') && ( !routeQuery || routeQuery.type !== 'anytime') && (routeParams || {}).id === patientId"
          @close="$emit('close')"
      ></status-item>
    </div><!--   end intake forms  -->
    <div
        v-if="(subscription|| {}).enableIntakeAndAnytimeForms || linkInformation.intakeFormsDocumentsInformation || linkInformation.anytimeFormsDocumentsInformation">
      <status-item
          v-if="(subscription|| {}).enableIntakeAndAnytimeForms && (((linkInformation || {}).anytimeFormsDocumentsInformation || []).length === 0)"
          icon="$filesMedical"
          :linkInformation="linkInformation"
          linkInformationKey="anytimeFormsDocumentsInformation"
          :routeTo="{ path: `/patients/${patientId}/additional-forms/create?type=anytime`, name: 'patients-additional-forms', params: {'id': patientId}, query: {type: 'anytime'}}"
          :active="routeName && routeName.startsWith('patients-additional-forms') && ( !routeQuery || routeQuery.type === 'anytime') && (routeParams || {}).id === patientId"
          customTooltipText="Create Anytime Form"
          add
          :isSearch="isSearch"
          @close="$emit('close')"
      ></status-item>
      <status-item
          v-else
          icon="$filesMedical"
          :linkInformation="linkInformation"
          linkInformationKey="anytimeFormsDocumentsInformation"
          typeText="Anytime Form"
          type="signing"
          className="pr-1"
          :active="routeName && routeName.startsWith('patients-additional-forms') && ( !routeQuery || routeQuery.type === 'anytime') && (routeParams || {}).id === patientId"
          :openNewWindowUrl="(linkInformation.anytimeFormsDocumentsInformation || []).length && (linkInformation.anytimeFormsDocumentsInformation || [])[0].hrefSrc && typeof linkInformation.anytimeFormsDocumentsInformation[0].hrefSrc === 'string' ? linkInformation.anytimeFormsDocumentsInformation[0].hrefSrc : ''"
          :isSearch="isSearch"
          @close="$emit('close')"
      ></status-item>
    </div>
  </div>

</template>

<script lang="ts">

import {computed, defineComponent} from "@vue/composition-api";
import {LinkInformation} from "@shared/store/patients/interfaces";
import StatusItem from "./StatusItem.vue";
import {PatientDataSearchParams} from "@shared/store";
import {PatientsGetters} from "@shared/store/patients";
import {useStore} from "@shared/providers";
import {ClientSubscription} from "@shared/store/financials/interfaces";
import moment from "moment";
import {SIGNING_TYPES} from "@shared/store/constants";
import {useRouteComponents} from "@main/composables/helpers";
import { getTreatmentPlanStatusColor, getTreatmentPlanStatusTextAndDate } from "@shared/functions/statusFunctions";

interface Props {
  linkInformation: LinkInformation;
  loading: boolean;
  patientId: string;
  subscription: ClientSubscription;
  isSideNav: boolean;
  isSearch: boolean;
}

export default defineComponent({
  name: "Statuses",
  props: {
    linkInformation: Object,
    loading: Boolean,
    patientId: String,
    subscription: Object,
    isSideNav: Boolean,
    isSearch: Boolean,
  },
  components: {StatusItem},
  setup(props: Props) {
    const store = useStore();
    const {routeName, routeQuery, routeParams} = useRouteComponents();
    const patientDataFilters = computed<PatientDataSearchParams>(
        () => store.getters[PatientsGetters.PATIENT_DATA_FILTERS]
    );

    return {
      patientDataFilters,
      moment,
      routeName,
      routeParams,
      routeQuery,
      SIGNING_TYPES,
      getTreatmentPlanStatusTextAndDate,
      getTreatmentPlanStatusColor,
    };
  },
});
</script>
<style lang="scss" scoped>

::v-deep .v-btn {
  border-radius: 4px !important;
}

::v-deep .v-btn--active {
  color: var(--v-primary-base) !important;
}
</style>