/* eslint-disable no-unused-vars */
import {Module} from "vuex";
import {
    NavMenu,
    NavMenuItem,
    Patient,
    PatientAdditionalCharge,
    PatientFormsEsignDocument,
    RootState,
    User
} from "../index";
import Vue from "vue";
import {
    PatientContractsService,
    PatientService,
    PatientTreatmentPlanAdditionalChargesService,
    PatientTreatmentPlanCommunicationsService,
    PatientTreatmentPlanDiscountsService,
    PatientTreatmentPlansService
} from "../../services/interfaces";

// DO NOT MOVE THIS OR ELSE
import {TreatmentPlan} from "../interfaces";
import {
    APP_TYPES,
    BROWSER_TYPES,
    ClientSubscriptions,
    ConditionalTextTypes,
    LoadingType,
    NEW_ITEM_ID
} from "../constants";
import {clone, cloneDeep} from "lodash";
import moment from "moment/moment";
import {PatientDiscount} from "../patients/interfaces";
import {PatientContractToEmail, PatientTreatmentPlanToEmail, ResponsibleParty} from "../responsible-parties/interfaces";
import {
    PatientContractsActions,
    PatientContractsGetters,
    PatientContractsMutations
} from "../documents/patient-contracts";
import {ESignerData} from "./interfaces";
import {SessionGetters} from "../session";
import {PatientsActions, PatientsGetters} from "../patients";
import {UIGetters} from "../ui-customizations";
import {getDate} from "@shared/functions/dateFunctions";
import {ErrorNotification, InfoNotification, SuccessNotification} from "@shared/functions/NotificationFunctions";
import {
    PatientFormsAndSignaturesGetters,
    PatientFormsAndSignaturesMutations
} from "@shared/store/patients/patient-forms-and-signatures";
import {PatientContract, PatientEsignDocument} from "@shared/store/documents/patient-contracts/interfaces";
import { getTreatmentPlanStatusText } from "@shared/functions/statusFunctions";

//TODO add store previous results for better experience like patient
export interface PatientTreatmentPlansState {
    loading: boolean;
    getting: boolean;
    saving: boolean;
    sending: boolean;
    deleting: boolean;
    reviewing: boolean;
    restoring: boolean;
    signing: boolean;
    reload: boolean;
    isSplit: boolean;
    isOfficeMode: boolean;
    arePaymentOptionsFixed: boolean;
    selectedPatientTreatmentPlansSideNav?: NavMenu;
    selectedPatientTreatmentPlans?: TreatmentPlan[];
    selectedPatientTreatmentPlan?: TreatmentPlan;
}


export enum PatientTreatmentPlansActions {
    CREATE_TREATMENT_PLAN = "PatientTreatmentPlansModule/createTreatmentPlan",
    LOAD_SELECTED_PATIENT_TREATMENT_PLANS = "PatientTreatmentPlansModule/loadSelectedPatientTreatmentPlans",
    LOAD_SELECTED_TREATMENT_PLAN = "PatientTreatmentPlansModule/loadSelectedTreatmentPlan",
    UPDATE_TREATMENT_PLAN = "PatientTreatmentPlansModule/updateTreatmentPlan",
    UPDATE_TREATMENT_PLAN_RESPONSIBLE_PARTIES = "PatientTreatmentPlansModule/updateTreatmentPlanResponsibleParties",
    UPDATE_TREATMENT_PLAN_NOTES = "PatientTreatmentPlansModule/updateTreatmentPlanNotes",
    DELETE_TREATMENT_PLAN = "PatientTreatmentPlansModule/deleteTreatmentPlan",
    LOAD_SECURE_DATA = "PatientTreatmentPlansModule/loadSecureData",
    REMOVE_SECURE_DATA_ACCESS = "PatientTreatmentPlansModule/removeSecureDataAccess",
    LOAD_PAYMENT_OPTIONS_PDF = "PatientTreatmentPlansModule/loadPaymentOptionsPdf",
    SEND_PAYMENT_OPTIONS_PDF = "PatientTreatmentPlansModule/sendPaymentOptionsPdf",
    SEND_PDF = "PatientTreatmentPlansModule/sendPdf",
    SEND_TREATMENT_PLAN_ADJUSTMENTS = "PatientTreatmentPlansModule/sendTreatmentPlanAdjustments",
    UPDATE_TREATMENT_PLAN_LAST_ASKED_TO_REGENERATE = "PatientTreatmentPlansModule/updateLastAskedToRegenerate",
    EMBEDDED_SIGNING_SESSION = "PatientTreatmentPlansModule/embeddedSigningSession",
    EMAIL_SIGNING_SESSION = "PatientTreatmentPlansModule/emailSigningSession",
    DECLINE_TREATMENT = "PatientTreatmentPlansModule/declineTreatment",
    RESET_TREATMENT_PLAN_STATUS = "PatientTreatmentPlansModule/resetTreatmentPlanStatus",
    DISABLE_REMINDERS = "PatientTreatmentPlansModule/disableReminders",
}

export enum PatientTreatmentPlansMutations {
    SET_LOADING = "PatientTreatmentPlansModule/setLoading",
    SET_SELECTED_PATIENT_TREATMENT_PLANS = "PatientTreatmentPlansModule/setSelectedPatientTreatmentPlans",
    SET_SELECTED_TREATMENT_PLAN = "PatientTreatmentPlansModule/setSelectedPatientTreatmentPlan",
    SET_RELOAD = "PatientTreatmentPlansModule/setReload",
    SET_IS_OFFICE_MODE = "PatientTreatmentPlansModule/setIsOfficeMode",
    RESET = "PatientTreatmentPlansModule/reset",
}

export enum PatientTreatmentPlansGetters {
    LOADING = "PatientTreatmentPlansModule/loading",
    SAVING = "PatientTreatmentPlansModule/saving",
    SENDING = "PatientTreatmentPlansModule/sending",
    GETTING = "PatientTreatmentPlansModule/getting",
    DELETING = "PatientTreatmentPlansModule/deleting",
    REVIEWING = "PatientTreatmentPlansModule/reviewing",
    RESTORING = "PatientTreatmentPlansModule/restoring",
    SIGNING = "PatientTreatmentPlansModule/signing",
    RELOAD = "PatientTreatmentPlansModule/reload",
    IS_SPLIT = "PatientTreatmentPlansModule/isSplit",
    IS_OFFICE_MODE = "PatientTreatmentPlansModule/isOfficeMode",
    ARE_PAYMENT_OPTIONS_FIXED = "PatientTreatmentPlansModule/arePaymentOptionsFixed",
    SELECTED_PATIENT_TREATMENT_PLANS = "PatientTreatmentPlansModule/selectedPatientTreatmentPlans",
    SELECTED_PATIENT_TREATMENT_PLANS_SIDE_NAV = "PatientTreatmentPlansModule/selectedPatientTreatmentPlansSideNav",
    SELECTED_PATIENT_TREATMENT_PLAN = "PatientTreatmentPlansModule/selectedPatientTreatmentPlan",
}

const initialState = () => ({
    loading: false,
    getting: false,
    saving: false,
    sending: false,
    deleting: false,
    reviewing: false,
    restoring: false,
    signing: false,
    reload: false,
    isSplit: false,
    isOfficeMode: false,
    arePaymentOptionsFixed: false,
    selectedPatientTreatmentPlansSideNav: undefined,
    selectedPatientTreatmentPlans: undefined,
    selectedPatientTreatmentPlan: undefined,
});

type StateType = PatientTreatmentPlansState;

export const PatientTreatmentPlansModule: Module<PatientTreatmentPlansState,
    RootState> = {
    namespaced: true,
    state: initialState(),
    actions: {
        async loadSecureData(
            {commit, dispatch},
            payload: {
                service: PatientTreatmentPlansService;
                folderId: string;
            }
        ) {
            let secureData: string[] = [];
            try {
                commit("setLoading", {
                    loading: true,
                    loadingType: LoadingType.REVIEWING,
                });
                const {service, folderId} = payload;
                secureData = await service.getSecureData(
                    folderId
                );
            } catch (error) {
                await ErrorNotification(dispatch, error, error.data);
            }
            commit("setLoading", {
                loading: false,
                loadingType: LoadingType.REVIEWING,
            });
            return secureData;
        },
        async removeSecureDataAccess(
            {commit, rootGetters, dispatch},
            payload: {
                service: PatientTreatmentPlansService;
                folderId: string | number;
            }
        ) {
            let success = false;
            try {
                commit("setLoading", {
                    loading: true,
                    loadingType: LoadingType.REVIEWING,
                });
                const {service, folderId} = payload;
                await service.putSecureDataAccessRemoval(
                    folderId?.toString()
                );

                //update patient history
                const patientHistory = cloneDeep(rootGetters[PatientFormsAndSignaturesGetters.PATIENT_HISTORY]) || undefined;
                (patientHistory || []).forEach(
                    (item: PatientFormsEsignDocument, index: number) => {
                        if (item?.folderId?.toString() === folderId?.toString()) {
                            patientHistory[index].secureFieldAccessRemoved = true;
                        }
                    }
                );
                commit(PatientFormsAndSignaturesMutations.SET_PATIENT_HISTORY, patientHistory, {root: true});

                //update contract
                const patientContracts = cloneDeep(rootGetters[PatientContractsGetters.PATIENT_CONTRACTS]) || undefined;
                (patientContracts || []).forEach(
                    (contract: PatientContract, contractIndex: number) => {
                        (contract?.patientEsignDocuments || []).forEach((item: PatientEsignDocument, index: number) => {
                            if (item?.folderId?.toString() === folderId?.toString() && !!item?.patientEsignature) {
                                patientContracts[contractIndex].patientEsignDocuments[index].patientEsignature.secureFieldAccessRemoved = true;
                            }
                        });
                    }
                );
                commit(PatientContractsMutations.SET_PATIENT_CONTRACTS, patientContracts, {root: true});

                success = true;
                await SuccessNotification(dispatch, "Secure Field(s) Access Removed!");
            } catch (error) {
                await ErrorNotification(dispatch, error, error.data);
            }
            commit("setLoading", {
                loading: false,
                loadingType: LoadingType.REVIEWING,
            });
            return success;
        },
        async loadSelectedPatientTreatmentPlans(
            {commit, dispatch},
            payload: {
                service: PatientTreatmentPlansService;
                id: string;
            }
        ) {
            let success = false;
            try {
                commit("setLoading", true);
                const {service, id} = payload;
                const treatmentPlans: TreatmentPlan[] = await service.getTreatmentPlans(
                    id
                );

                commit("setSelectedPatientTreatmentPlans", {patientId: id, treatmentPlans: treatmentPlans});
                success = true;
            } catch (error) {
                await ErrorNotification(dispatch, error, error.data);
            }
            commit("setLoading", false);
            return success;
        },
        async loadSelectedTreatmentPlan(
            {commit, dispatch, getters},
            payload: {
                service: PatientTreatmentPlansService;
                patientTreatmentPlanDiscountsService: PatientTreatmentPlanDiscountsService;
                patientTreatmentPlanAdditionalChargesService: PatientTreatmentPlanAdditionalChargesService;
                id: string;
            }
        ) {
            let success = false;
            try {
                commit("setLoading", {
                    loading: true,
                    loadingType: LoadingType.GETTING,
                });
                const {
                    service,
                    patientTreatmentPlanDiscountsService,
                    patientTreatmentPlanAdditionalChargesService,
                    id
                } = payload;

                const treatmentPlanService: Promise<TreatmentPlan> = service.getSelectedTreatmentPlan(
                    id
                );
                const patientDiscountsService: Promise<PatientDiscount[]> = patientTreatmentPlanDiscountsService.getSelectedTreatmentPlanDiscounts(id);
                const patientAdditionalChargesService: Promise<PatientAdditionalCharge[]> = patientTreatmentPlanAdditionalChargesService.getSelectedTreatmentPlanAdditionalCharges(id);

                const rtnServiceCalls = await Promise.all([treatmentPlanService, patientDiscountsService, patientAdditionalChargesService]);
                const treatmentPlan = cloneDeep(rtnServiceCalls[0]);
                const patientDiscounts = cloneDeep(rtnServiceCalls[1]);
                const patientAdditionalCharges = cloneDeep(rtnServiceCalls[2]);
                const filteredPatientAdditionalCharges = patientAdditionalCharges ? patientAdditionalCharges?.filter((charge) => charge.isChecked) : [];
                filteredPatientAdditionalCharges.forEach((item: PatientAdditionalCharge, index: number) => {
                    filteredPatientAdditionalCharges[index].patientTreatmentPlanId = item.patientTreatmentPlanId && item.patientTreatmentPlanId !== NEW_ITEM_ID ? item.patientTreatmentPlanId : treatmentPlan?.patientTreatmentPlanId;
                    filteredPatientAdditionalCharges[index].productTotalFee = typeof item?.productQuantity !== 'number'
                        ? item?.productFeeAmount
                        : (item.productQuantity * item?.productFeeAmount);
                    filteredPatientAdditionalCharges[index].patientNonApplicableProdTxTypeCombos = filteredPatientAdditionalCharges[index]?.patientNonApplicableProdTxTypeCombos?.filter((combo) => !!combo?.treatmentTypeId) || [];
                });

                if (treatmentPlan) {
                    (treatmentPlan || {}).patientDiscounts = patientDiscounts ? patientDiscounts : [];
                    (treatmentPlan || {}).patientAdditionalCharges = patientAdditionalCharges ? patientAdditionalCharges?.filter((charge) => charge.isChecked) : [];
                }
                commit("setSelectedPatientTreatmentPlan", treatmentPlan);

                const selectedPatientTreatmentPlan = cloneDeep(getters.selectedPatientTreatmentPlan) || null;
                if (selectedPatientTreatmentPlan?.patientTreatmentPlanId && selectedPatientTreatmentPlan?.patientTreatmentPlanId !== NEW_ITEM_ID) {
                    const selectedPatientTreatmentPlans = cloneDeep(
                        getters.selectedPatientTreatmentPlans
                    );

                    (selectedPatientTreatmentPlans || []).forEach(
                        (item: TreatmentPlan, index: number) => {
                            if (item.patientTreatmentPlanId === id) {
                                selectedPatientTreatmentPlans[index] = selectedPatientTreatmentPlan;
                            }
                        }
                    );

                    commit("setSelectedPatientTreatmentPlans", {
                        patientId: selectedPatientTreatmentPlan?.patientId,
                        treatmentPlans: selectedPatientTreatmentPlans
                    });
                }
                success = true;
            } catch (error) {
                await ErrorNotification(dispatch, error, error.data);
            }
            commit("setLoading", {
                loading: false,
                loadingType: LoadingType.GETTING,
            });

            if (success) commit("setReload", false);
            return success;
        },
        loadPaymentOptionsPdf: async function (
            {commit, dispatch, rootGetters},
            payload: {
                service: PatientTreatmentPlansService;
                id: string;
                html: string;
            }
        ) {
            try {
                commit("setLoading", {
                        loading: true,
                        loadingType: LoadingType.RESTORING
                    },
                );
                commit("setLoading", {loading: true, loadingType: LoadingType.RESTORING});
                const {service, html, id} = payload;

                const browserType: BROWSER_TYPES = rootGetters[
                    UIGetters.BROWSER_TYPE];

                await service.generatePaymentOptionsPdf(
                    id,
                    html,
                    browserType,
                );
                if (process.env.VUE_APP_TYPE === APP_TYPES.PORTAL) {
                    await InfoNotification(dispatch, "Make sure to allow pop-ups to see the printable pdf.");
                }
            } catch (error) {
                await ErrorNotification(dispatch, error, error.data);
            }
            commit("setLoading", {loading: false, loadingType: LoadingType.RESTORING});
        },
        async createTreatmentPlan(
            {commit, dispatch, getters},
            payload: {
                values: TreatmentPlan;
                service: PatientTreatmentPlansService;
                patientTreatmentPlanDiscountsService: PatientTreatmentPlanDiscountsService;
                patientTreatmentPlanAdditionalChargesService: PatientTreatmentPlanAdditionalChargesService;
            }
        ) {
            let success = false;
            try {
                commit("setLoading", {
                    loading: true,
                    loadingType: LoadingType.SAVING,
                });
                const {
                    service,
                    patientTreatmentPlanDiscountsService,
                    patientTreatmentPlanAdditionalChargesService,
                    values
                } = payload;
                const newTreatmentPlan = await service.saveTreatmentPlan(values);
                //TODO remove call here get from information

                const loadIsSuccessful: boolean = (newTreatmentPlan || {}).patientTreatmentPlanId ? await dispatch("loadSelectedTreatmentPlan", {
                    service: service,
                    patientTreatmentPlanDiscountsService: patientTreatmentPlanDiscountsService,
                    patientTreatmentPlanAdditionalChargesService: patientTreatmentPlanAdditionalChargesService,
                    id: (newTreatmentPlan || {}).patientTreatmentPlanId
                }) : false;

                if (!loadIsSuccessful) {
                    throw Error("Unable to save and load treatment plan");
                }

                const selectedPatientTreatmentPlans = cloneDeep(getters.selectedPatientTreatmentPlans) || [];
                const selectedPatientTreatmentPlan = cloneDeep(getters.selectedPatientTreatmentPlan) || null;
                selectedPatientTreatmentPlans.push(selectedPatientTreatmentPlan);
                commit("setSelectedPatientTreatmentPlans", {
                    patientId: values?.patientId,
                    treatmentPlans: selectedPatientTreatmentPlans
                });
                success = await SuccessNotification(dispatch, "Created Treatment Plan!");

            } catch (error) {
                await ErrorNotification(dispatch, error, error.data);
            }

            commit("setLoading", {loading: false, loadingType: LoadingType.SAVING});
            return success;
        },
        async updateTreatmentPlan(
            {commit, dispatch, getters},
            payload: {
                id: string;
                values: TreatmentPlan;
                service: PatientTreatmentPlansService;
                patientTreatmentPlanDiscountsService: PatientTreatmentPlanDiscountsService;
                patientTreatmentPlanAdditionalChargesService: PatientTreatmentPlanAdditionalChargesService;
            }
        ) {
            let success = false;
            try {
                commit("setLoading", {
                    loading: true,
                    loadingType: LoadingType.SAVING,
                });
                const {
                    service,
                    id,
                    values,
                    patientTreatmentPlanDiscountsService,
                    patientTreatmentPlanAdditionalChargesService
                } = payload;
                const response = await service.updateTreatmentPlan(id, values);
                //TODO remove call here get from information
                const loadIsSuccessful: boolean = id ? await dispatch("loadSelectedTreatmentPlan", {
                    service: service,
                    patientTreatmentPlanDiscountsService: patientTreatmentPlanDiscountsService,
                    patientTreatmentPlanAdditionalChargesService: patientTreatmentPlanAdditionalChargesService,
                    id: id
                }) : false;

                if (!loadIsSuccessful) {
                    throw Error("Unable to save and load treatment plan");
                }
                const selectedPatientTreatmentPlan = cloneDeep(getters.selectedPatientTreatmentPlan) || null;

                if (!response.patientTreatmentPlanId) {
                    throw Error("error updating treatment plan");
                }
                success = await SuccessNotification(dispatch, `Updated Treatment Plan!`);
            } catch (error) {
                await ErrorNotification(dispatch, error, error.data);
            }
            commit("setLoading", {loading: false, loadingType: LoadingType.SAVING});
            return success;
        },
        async updateTreatmentPlanNotes(
            {commit, dispatch, getters},
            payload: {
                id: string;
                notes: string;
                service: PatientTreatmentPlansService;
                suppressNotification: boolean;
            }
        ) {
            let success = false;
            try {
                commit("setLoading", {
                    loading: true,
                    loadingType: LoadingType.SAVING,
                });
                const {
                    service,
                    id,
                    notes,
                    suppressNotification,
                } = payload;
                let lastModifiedDate = moment.utc().format();
                const response = await service.updateTreatmentPlanNotes(id, notes);
                let lastAskedToRegenerateDate = moment.utc().format();
                if (!response || !response.lastModifiedDate) {
                    Error("Unable to update tx plan notes");
                } else {
                    lastModifiedDate = response.lastModifiedDate;
                    lastAskedToRegenerateDate = response?.lastAskedToRegenerateDate ? response.lastAskedToRegenerateDate : lastAskedToRegenerateDate;
                }

                const selectedPatientTreatmentPlan = cloneDeep(getters.selectedPatientTreatmentPlan) || null;
                selectedPatientTreatmentPlan.notes = notes;
                selectedPatientTreatmentPlan.lastModifiedDate = lastModifiedDate;
                selectedPatientTreatmentPlan.lastAskedToRegenerateDate = lastAskedToRegenerateDate;
                const selectedPatientTreatmentPlans = cloneDeep(
                    getters.selectedPatientTreatmentPlans
                );
                (selectedPatientTreatmentPlans || []).forEach(
                    (item: TreatmentPlan, index: number) => {
                        if (item.patientTreatmentPlanId === id) {
                            selectedPatientTreatmentPlans[index] = selectedPatientTreatmentPlan;
                        }
                    }
                );
                commit("setSelectedPatientTreatmentPlans", {
                    patientId: selectedPatientTreatmentPlan?.patientId,
                    treatmentPlans: selectedPatientTreatmentPlans
                });
                commit("setSelectedPatientTreatmentPlan", selectedPatientTreatmentPlan);
                success = await SuccessNotification(dispatch, `Updated Notes!`, suppressNotification);
            } catch (error) {
                await ErrorNotification(dispatch, error, "Error trying to save notes " + error.data);
            }
            commit("setLoading", {loading: false, loadingType: LoadingType.SAVING});
            return success;
        },
        async resetTreatmentPlanStatus(
            {commit, dispatch, getters},
            payload: {
                id: string;
                service: PatientTreatmentPlansService;
                skipSave: boolean;
            }
        ) {
            let success = false;

            const {
                service,
                id,
                skipSave,
            } = payload;
            try {
                const selectedPatientTreatmentPlan = cloneDeep(getters.selectedPatientTreatmentPlan) || null;

                if (selectedPatientTreatmentPlan && typeof selectedPatientTreatmentPlan.status === 'number' && selectedPatientTreatmentPlan.status !== -1) {
                    if (!skipSave) {
                        const rtnStatus = await service.resetTreatmentPlanStatus(id);
                        if (rtnStatus < 200 || rtnStatus >= 300) {
                            throw Error("Unable to reset status");
                        }
                    }
                    selectedPatientTreatmentPlan.status = -1;
                    selectedPatientTreatmentPlan.acceptedOrDeclinedDate = null;
                    const selectedPatientTreatmentPlans = cloneDeep(
                        getters.selectedPatientTreatmentPlans
                    );

                    (selectedPatientTreatmentPlans || []).forEach(
                        (item: TreatmentPlan, index: number) => {
                            if (item.patientTreatmentPlanId === selectedPatientTreatmentPlan?.patientTreatmentPlanId) {
                                selectedPatientTreatmentPlans[index] = selectedPatientTreatmentPlan;
                            }
                        }
                    );

                    commit("setSelectedPatientTreatmentPlans", {
                        patientId: selectedPatientTreatmentPlan?.patientId,
                        treatmentPlans: selectedPatientTreatmentPlans
                    });
                    commit("setSelectedPatientTreatmentPlan", selectedPatientTreatmentPlan);
                }

                success = true;
            } catch (error) {
                await ErrorNotification(dispatch, error, error.data);
            }
            return success;
        },
        async updateLastAskedToRegenerate(
            {commit, dispatch, getters},
            payload: { id: string; service: PatientTreatmentPlansService; }
        ) {
            let success = false;
            try {
                commit("setLoading", {
                    loading: true,
                    loadingType: LoadingType.SAVING,
                });
                const {service, id} = payload;
                const selectedPatientTreatmentPlan = cloneDeep(getters.selectedPatientTreatmentPlan) || null;

                let lastAskedToRegenerateDate = await service.updateLastAskedToRegenerate(id);

                if (!lastAskedToRegenerateDate || !moment.utc(lastAskedToRegenerateDate).isValid()) {
                    await ErrorNotification(dispatch, undefined, "Unable to save regeneration date");
                    lastAskedToRegenerateDate = moment.utc().format();
                }

                selectedPatientTreatmentPlan.lastAskedToRegenerateDate = lastAskedToRegenerateDate;
                //todo update patientTreatmentPlan.lastAskedToRegenerateDate = responseDate.date;
                const selectedPatientTreatmentPlans = cloneDeep(
                    getters.selectedPatientTreatmentPlans
                );

                (selectedPatientTreatmentPlans || []).forEach(
                    (item: TreatmentPlan, index: number) => {
                        if (item.patientTreatmentPlanId === id) {
                            selectedPatientTreatmentPlans[index] = selectedPatientTreatmentPlan;
                        }
                    }
                );
                commit("setSelectedPatientTreatmentPlans", {
                    patientId: selectedPatientTreatmentPlan?.patientId,
                    treatmentPlans: selectedPatientTreatmentPlans
                });
                commit("setSelectedPatientTreatmentPlan", selectedPatientTreatmentPlan);

                success = true;
            } catch (error) {
                await ErrorNotification(dispatch, error, error.data);
            }
            commit("setLoading",
                {
                    loading: false, loadingType: LoadingType.SAVING
                }
            );
            return success;
        },
        async updateTreatmentPlanResponsibleParties(
            {commit, dispatch, getters, rootGetters},
            payload
                :
                {
                    id: string;
                    values: ResponsibleParty[];
                    service: PatientTreatmentPlansService;
                    patientService: PatientService;
                    patientContractsService: PatientContractsService;
                    forceContractReloadAndRefresh: boolean;
                    suppressNotification: boolean;
                }
        ) {
            let success = false;
            try {
                commit("setLoading", {
                    loading: true,
                    loadingType: LoadingType.SAVING,
                });
                const {
                    service,
                    id,
                    values,
                    suppressNotification,
                    patientService,
                } = payload;
                const dtoValues: ResponsibleParty[] = [];
                const selectedPatientTreatmentPlan = cloneDeep(getters.selectedPatientTreatmentPlan) || null;
                let selectedPatient: Patient = cloneDeep(rootGetters["PatientModule/selectedPatient"]) || null;
                (values || []).forEach(
                    (rp: ResponsibleParty, index: number) => {
                        dtoValues.push(
                            {
                                patientResponsiblePartyId: rp.patientResponsiblePartyId,
                                patientId: rp?.patientId ? rp.patientId : selectedPatient.patientId,
                                firstName: rp?.firstName,
                                lastName: rp?.lastName,
                                email: rp?.email,
                                isAccountHolder: rp?.isAccountHolder || false,
                                isSigner: rp?.isSigner || false,
                            });
                    });
                const responsibleParties = await service.updateTreatmentPlanResponsibleParties(id, dtoValues);

                if (responsibleParties?.length === 0) {
                    throw Error("Unable to save treatment plan responsible parties");
                }

                const latestDate = selectedPatientTreatmentPlan.lastAskedToRegenerateDate && moment?.utc(selectedPatientTreatmentPlan.lastAskedToRegenerateDate).isValid() ? moment?.utc(selectedPatientTreatmentPlan.lastAskedToRegenerateDate) : null;
                (values || []).forEach(
                    (rp: ResponsibleParty, index: number) => {
                        if (rp.patientResponsiblePartyId === NEW_ITEM_ID) {
                            const newIndex = responsibleParties.findIndex((x) => x.email === rp?.email);
                            responsibleParties[newIndex].isAccountHolder = values[index].isAccountHolder;
                            responsibleParties[newIndex].isSigner = values[index].isSigner;
                            values[index] = cloneDeep(responsibleParties[newIndex]);
                            (selectedPatient.patientResponsibleParties || []).push(values[index]);
                        } else {
                            const existingIndex = (selectedPatient.patientResponsibleParties || []).findIndex((x) => x.patientResponsiblePartyId === rp?.patientResponsiblePartyId);
                            if (existingIndex > -1) {
                                (selectedPatient.patientResponsibleParties || [])[existingIndex].email = rp.email;
                                (selectedPatient.patientResponsibleParties || [])[existingIndex].lastModifiedDate = rp.lastModifiedDate;
                                (selectedPatient.patientResponsibleParties || [])[existingIndex].lastModifiedBy = rp.lastModifiedBy;
                            }
                        }
                    }
                );
                selectedPatientTreatmentPlan.patientResponsibleParties = cloneDeep(values?.filter(x => x.isAccountHolder !== true));
                const accountHolderIndex = values?.findIndex(rp => rp.isAccountHolder);
                if (accountHolderIndex > -1) {
                    selectedPatientTreatmentPlan.accountHolderId = values[accountHolderIndex]?.patientResponsiblePartyId;
                    selectedPatientTreatmentPlan.accountHolder = values[accountHolderIndex];
                } else {
                    selectedPatientTreatmentPlan.accountHolderId = undefined;
                    selectedPatientTreatmentPlan.accountHolder = undefined;
                }

                await dispatch(
                    PatientsActions.GET_PATIENT_DETAIL,
                    {
                        id: selectedPatientTreatmentPlan?.patientId,
                        service: patientService,
                        treatmentPlanService: service,
                        skipLoadSelectedTxPlans: true,
                    },
                    {root: true}
                );

                selectedPatient = cloneDeep(rootGetters["PatientModule/selectedPatient"]) || null;
                const patientLastModifiedDate = selectedPatient.lastModifiedDate && moment.utc(selectedPatient.lastModifiedDate).isValid() ? moment.utc(selectedPatient.lastModifiedDate) : null;
                if (!latestDate || (patientLastModifiedDate && latestDate?.isBefore(patientLastModifiedDate))) {
                    selectedPatientTreatmentPlan.lastAskedToRegenerateDate = clone(selectedPatient.lastModifiedDate);
                }
                const selectedPatientTreatmentPlans = cloneDeep(
                    getters.selectedPatientTreatmentPlans
                );

                (selectedPatientTreatmentPlans || []).forEach(
                    (item: TreatmentPlan, index: number) => {
                        if (item.patientTreatmentPlanId === id) {
                            selectedPatientTreatmentPlans[index] = selectedPatientTreatmentPlan;
                        }
                    }
                );
                commit("setSelectedPatientTreatmentPlan", selectedPatientTreatmentPlan);
                commit("setSelectedPatientTreatmentPlans", {
                    patientId: selectedPatientTreatmentPlan?.patientId,
                    treatmentPlans: selectedPatientTreatmentPlans
                });
                success = await SuccessNotification(dispatch, `Updated Responsible Parties!`, suppressNotification);

            } catch (error) {
                await ErrorNotification(dispatch, error, error.data);
            }
            commit("setLoading",
                {
                    loading: false, loadingType: LoadingType.SAVING
                }
            );
            return success;
        },
        async deleteTreatmentPlan(
            {commit, dispatch, getters},
            payload
                :
                {
                    id: string;
                    service: PatientTreatmentPlansService;
                }
        ) {
            let success = false;
            try {
                commit("setLoading", {
                    loading: true,
                    loadingType: LoadingType.DELETING,
                });
                const {service, id} = payload;
                const deleted = await service.deletePlan(id);
                if (deleted.patientTreatmentPlanId === id) {
                    const selectedPatientTreatmentPlans = (
                        cloneDeep(getters.selectedPatientTreatmentPlans) || []
                    ).filter(
                        (treatmentPlan: TreatmentPlan) =>
                            treatmentPlan.patientTreatmentPlanId !== id
                    );
                    commit("setSelectedPatientTreatmentPlans", {
                        patientId: deleted?.patientId,
                        treatmentPlans: selectedPatientTreatmentPlans
                    });
                    commit("setSelectedPatientTreatmentPlan", undefined);
                    success = await SuccessNotification(dispatch, `Deleted Treatment Plan!`);
                }
            } catch (error) {
                await ErrorNotification(dispatch, error, error.data);
            }
            commit("setLoading", {
                loading: false,
                loadingType: LoadingType.DELETING,
            });
            return success;
        },
        async sendPdf(
            {commit, dispatch, rootGetters},
            payload
                :
                {
                    service: PatientTreatmentPlansService;
                    values: PatientContractToEmail | PatientTreatmentPlanToEmail;
                    docType: ConditionalTextTypes;
                }
        ) {
            let success = false;
            try {
                commit("setLoading", {loading: true, loadingType: LoadingType.SENDING});
                const {service, values, docType} = payload;
                const {saveAndEmail, ...sendPdfData} = values;

                const user: User | undefined = cloneDeep(rootGetters[SessionGetters.USER]);
                const selectedPatient: Patient | undefined = cloneDeep(rootGetters[PatientsGetters.SELECTED_PATIENT]);
                if (user?.email?.toLowerCase() === values?.email?.toLowerCase()) {
                    throw Error("Unable to send PDF documents due to email matching logged in user.  Please update email to send PDF.");

                }

                const statusCode = ConditionalTextTypes.CONTRACT === docType
                    ? await service.sendPdf((values as PatientContractToEmail).patientContractId, sendPdfData as PatientContractToEmail)
                    : await service.sendPaymentOptionsPdf({
                        ...sendPdfData as PatientTreatmentPlanToEmail,
                        isReadOnly: true
                    });
                if (statusCode < 200 && statusCode >= 300) {
                    throw Error("Unable to send PDF documents");
                }

                if (selectedPatient && selectedPatient?.birthDate !== values?.birthDate) {
                    selectedPatient.birthDate = values.birthDate;
                    commit("PatientModule/setSelectedPatient", {
                        patient: selectedPatient,
                        type: LoadingType.SAVING,
                        id: selectedPatient.patientId
                    }, {root: true});
                }
                success = await SuccessNotification(dispatch, ConditionalTextTypes.CONTRACT === docType ?
                    saveAndEmail
                        ? `Treatment start document saved and PDF document sent`
                        : `Treatment start document PDF sent!`
                    : `Payment Options read-only information sent to responsible party!`);
            } catch (error) {
                await ErrorNotification(dispatch, error, error.data);
            }
            commit("setLoading", {loading: false, loadingType: LoadingType.SENDING});
            return success;
        },
        async sendTreatmentPlanAdjustments(
            {commit, dispatch, rootGetters},
            payload: {
                service: PatientTreatmentPlansService;
                values: PatientTreatmentPlanToEmail;
            }
        ) {
            let success = false;
            try {
                commit("setLoading", {loading: true, loadingType: LoadingType.SENDING});
                const {service, values} = payload;
                const {saveAndEmail, ...sendPdfData} = values;

                const user: User | undefined = cloneDeep(rootGetters[SessionGetters.USER]);
                const selectedPatient: Patient | undefined = cloneDeep(rootGetters[PatientsGetters.SELECTED_PATIENT]);
                if (user?.email?.toLowerCase() === values?.email?.toLowerCase()) {
                    throw Error("Unable to send adjustable Payment Options to email matching logged in user.  Please update email to send.");
                }

                const statusCode = await service.sendTreatmentPlanAdjustments({
                    ...sendPdfData as PatientTreatmentPlanToEmail,
                    isReadOnly: false
                });
                if (statusCode < 200 && statusCode >= 300) {
                    throw Error("Unable to send adjustable Payment Options");
                }

                let updatedSelectedPatient = false;
                if (selectedPatient && selectedPatient?.birthDate !== values?.birthDate) {
                    updatedSelectedPatient = true;
                    selectedPatient.birthDate = values.birthDate;
                }

                if (selectedPatient && selectedPatient?.patientResponsibleParties?.length && values.patientResponsiblePartyId?.length && values.patientResponsiblePartyId !== NEW_ITEM_ID) {
                    const emailedResponsiblePartyIndex = selectedPatient?.patientResponsibleParties?.findIndex((x) => x.patientResponsiblePartyId === values.patientResponsiblePartyId);
                    if (emailedResponsiblePartyIndex > -1 && selectedPatient?.patientResponsibleParties?.[emailedResponsiblePartyIndex]?.email !== values.email) {
                        updatedSelectedPatient = true;
                        selectedPatient.patientResponsibleParties[emailedResponsiblePartyIndex].email = values.email;
                    }
                }

                if (updatedSelectedPatient) {
                    commit("PatientModule/setSelectedPatient", {
                        patient: selectedPatient,
                        type: LoadingType.SAVING,
                        id: selectedPatient?.patientId
                    }, {root: true});
                }
                success = await SuccessNotification(dispatch, `Adjustable Payment Options sent!`);

            } catch (error) {
                await ErrorNotification(dispatch, error, error.data);
            }
            commit("setLoading", {loading: false, loadingType: LoadingType.SENDING});
            return success;
        },
        async emailSigningSession(
            {commit, dispatch},
            payload: {
                service: PatientTreatmentPlansService;
                patientContractService: PatientContractsService;
                eSignerData: ESignerData;
            }
        ) {
            let urlDocument = "";
            try {
                commit("setLoading", {loading: true, loadingType: LoadingType.SIGNING});
                const {service, patientContractService, eSignerData} = payload;
                urlDocument = await service.emailSigningSession(eSignerData);
                if (!urlDocument || urlDocument.length <= 0) {
                    throw Error("Unable to create email signing session");
                }

                //TODO update patient contract manually instead
                await dispatch(
                    PatientContractsActions.LOAD_PATIENT_CONTRACTS,
                    {
                        id: eSignerData.patientTreatmentPlanId,
                        service: patientContractService,
                        suppressNotification: true,
                    }, {root: true});

                await SuccessNotification(dispatch, `Treatment start documents emailed for signing session`);

            } catch (error) {
                await ErrorNotification(dispatch, error, error.data);
            }
            commit("setLoading", {loading: false, loadingType: LoadingType.SIGNING});
            return urlDocument;
        },
        async embeddedSigningSession(
            {commit, dispatch},
            payload
                :
                {
                    service: PatientTreatmentPlansService;
                    patientContractService: PatientContractsService;
                    eSignerData: ESignerData;
                }
        ) {
            let urlDocument = "";
            try {
                commit("setLoading", {loading: true, loadingType: LoadingType.SIGNING});
                const {service, patientContractService, eSignerData} = payload;
                urlDocument = await service.embeddedSigningSession(eSignerData);
                if (!urlDocument || urlDocument.length <= 0) {
                    throw Error("Unable to create embedded signing session");
                }

                //TODO update patient contract manually instead
                await dispatch(
                    PatientContractsActions.LOAD_PATIENT_CONTRACTS,
                    {
                        id: eSignerData.patientTreatmentPlanId,
                        service: patientContractService,
                        suppressNotification: true,
                    }, {root: true});
            } catch (error) {
                await ErrorNotification(dispatch, error, error.data);
            }
            commit("setLoading", {loading: false, loadingType: LoadingType.SIGNING});
            return urlDocument;
        },
        async declineTreatment(
            {dispatch, commit, getters, rootGetters},
            payload: {
                service: PatientTreatmentPlanCommunicationsService;
                patientContractService: PatientContractsService;
                patientService: PatientService;
                patientTreatmentPlansService: PatientTreatmentPlansService,
                patientTreatmentPlanDiscountsService: PatientTreatmentPlanDiscountsService,
                patientTreatmentPlanAdditionalChargesService: PatientTreatmentPlanAdditionalChargesService,
                id: string;
                contractId: string;
            }
        ) {
            let success = false;
            try {
                const {service, id, contractId,
                    patientTreatmentPlansService, patientContractService,
                    patientTreatmentPlanDiscountsService,
                    patientTreatmentPlanAdditionalChargesService, patientService} = payload;
                commit("setLoading", {
                    loading: true,
                    loadingType: LoadingType.DELETING,
                });
                const statusCode = await service.declineTreatment(id, contractId);
                if (statusCode < 200 && statusCode >= 300) {
                    throw Error("Unable to decline treatment at this time");
                }
                if (process.env.VUE_APP_TYPE === APP_TYPES.MAIN) {
                    const patientDataSearchParams = rootGetters[PatientsGetters.PATIENT_DATA_FILTERS];
                    await Promise.all([
                     dispatch(
                      PatientContractsActions.LOAD_PATIENT_CONTRACTS,
                      {
                          id: id,
                          service: patientContractService,
                          suppressNotification: true,
                      }, {root: true}),
                     dispatch('loadSelectedTreatmentPlan', {
                            id: id,
                            service: patientTreatmentPlansService,
                            patientTreatmentPlanDiscountsService: patientTreatmentPlanDiscountsService,
                            patientTreatmentPlanAdditionalChargesService: patientTreatmentPlanAdditionalChargesService,
                        }
                    ),
                    dispatch(PatientsActions.LIST_PATIENTS, {service: patientService, patientDataSearchParams: patientDataSearchParams }, {root: true})
                    ]);
                }
                const treatmentPlan = getters['selectedPatientTreatmentPlan'];
                const patientContracts = rootGetters[PatientContractsGetters.PATIENT_CONTRACTS];
                commit(PatientContractsMutations.SET_PATIENT_CONTRACTS_SIDE_NAV, {patientContracts, treatmentPlan}, {root: true});
                success = await SuccessNotification(dispatch, `Treatment declined`, process.env.VUE_APP_TYPE === APP_TYPES.MAIN);

            } catch (error) {
                await ErrorNotification(dispatch, error, error.data);
            }
            commit("setLoading", {
                loading: false,
                loadingType: LoadingType.DELETING,
            });
            return success;
        },
        async disableReminders(
            {commit, dispatch, getters},
            payload: {
                id: string;
                service: PatientTreatmentPlansService;
            }
        ) {
            let success = false;
            try {
                commit("setLoading", {
                    loading: true,
                    loadingType: LoadingType.SENDING,
                });
                const {service, id} = payload;
                const disableReminder = await service.disableReminders(id);
                if (disableReminder) {
                    //TODO ideally the disabled reminder(s) would be set here without reloading the page like in history
                    success = await SuccessNotification(dispatch, `Reminder(s) disabled successfully!`);
                }
            } catch (error) {
                await ErrorNotification(dispatch, error, error.data);
            }
            commit("setLoading", {
                loading: false,
                loadingType: LoadingType.SENDING,
            });
            return success;
        },
    },
    mutations: {
        setLoading(
            state: PatientTreatmentPlansState,
            payload: { loading: boolean; loadingType: string | string[] }
        ) {
            if (typeof payload === "boolean") {
                Vue.set(state, "loading", payload);
            } else {
                if (payload.loadingType) {
                    if (Array.isArray(payload.loadingType)) {
                        payload.loadingType.forEach((item) => {
                            Vue.set(state, item, payload.loading);
                        });
                    } else {
                        Vue.set(state, payload.loadingType, payload.loading);
                    }
                }
                Vue.set(state, "loading", payload.loading);
            }
        },
        setReload(state: PatientTreatmentPlansState, reload: boolean = false) {
            Vue.set(state, "reload", reload);
        },
        setIsOfficeMode(state: PatientTreatmentPlansState, isOfficeMode: boolean = false) {
            Vue.set(state, "isOfficeMode", isOfficeMode);
        },
        setSelectedPatientTreatmentPlans: function (
            state: PatientTreatmentPlansState,
            payload: { patientId: string, treatmentPlans?: TreatmentPlan[] }
        ) {
            const {treatmentPlans, patientId} = payload;
            const sideNavTxPlans = {
                key: "Treatment Plans",
                text: "Treatment Plans",
                regex: /\/treatment-plans/,
                group: "/treatment-plans",
                items: [],
            } as NavMenu;
            const sideNavItemTxPlans = {
                icon: "$briefcase",
                key: "Treatment Plans",
                text: "Treatment Plans",
                regex: /\/treatment-plans\/(create|[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})$/,
                link: `/patients/${patientId}/treatment-plans/`,
                group: "/treatment-plans",
                count: (treatmentPlans || []).length,
                items: [{
                    icon: "$plus",
                    add: true,
                    key: "Create Treatment Plan",
                    text: "Create Treatment Plan",
                    link: `/patients/${patientId}/treatment-plans/create`,
                    regex: /\/treatment-plans\/create$/,
                    subscription: ClientSubscriptions.FEE_PRESENTATIONS,
                    group: `/treatment-plans/create`,
                } as NavMenuItem],
            } as NavMenuItem;
            if (treatmentPlans?.length) {
                treatmentPlans.sort((a, b) => {
                    if (moment(a.createdDate) < moment(b.createdDate)) {
                        return 1;
                    } else if (moment(a.createdDate) > moment(b.createdDate)) {
                        return -1;
                    } else {
                        return 0;
                    }
                })
                for (
                    let index = 0;
                    index < (treatmentPlans || []).length;
                    index++
                ) {

                    treatmentPlans[index].bandingDate = getDate(treatmentPlans[index].bandingDate || "");

                    const linkTxPlanId = `/patients/${patientId}/treatment-plans/${treatmentPlans[index]?.patientTreatmentPlanId}`;
                    // @ts-ignore
                    let txTypeDisplay = "";
                    treatmentPlans[index]?.patientTreatmentTypes?.forEach((tt, index) => {
                        txTypeDisplay += tt?.treatmentType?.name + ", "
                    })
                    const txPlanDisplay = `${treatmentPlans[index]?.treatmentClass?.treatmentClassName} - ${moment(treatmentPlans[index]?.createdDate, 'YYYY-MM-DD').format("MM/DD/YY")}`;
                    const sideNavItemTxPlan = {
                        icon: "$external-link",
                        key: txPlanDisplay,
                        text: txPlanDisplay,
                        link: linkTxPlanId,
                        subTitle: txTypeDisplay.replace(/,\s*$/, ""),
                        subTitle2: (typeof treatmentPlans?.[index]?.status === 'number' && (treatmentPlans?.[index]?.status as number) > -1) ? getTreatmentPlanStatusText(treatmentPlans[index].status, true)  : "",
                        group: `/treatment-plans/${treatmentPlans[index]?.patientTreatmentPlanId}`,
                    } as NavMenuItem;

                    sideNavItemTxPlans?.items?.push(sideNavItemTxPlan);
                }
            }
            sideNavTxPlans?.items?.push(sideNavItemTxPlans);
            Vue.set(state, "selectedPatientTreatmentPlansSideNav", sideNavTxPlans);
            Vue.set(state, "selectedPatientTreatmentPlans", treatmentPlans);
        }
        ,
        setSelectedPatientTreatmentPlan(
            state: PatientTreatmentPlansState,
            treatmentPlan?: TreatmentPlan
        ) {
            if (treatmentPlan?.bandingDate) {
                treatmentPlan.bandingDate = getDate(treatmentPlan.bandingDate, 'YYYY-MM-DD', 'YYYY-MM-DD');
            }
            const isSplit =  !!(treatmentPlan?.patientSplitTreatmentPlanPortions && Array.isArray(treatmentPlan?.patientSplitTreatmentPlanPortions) && treatmentPlan?.patientSplitTreatmentPlanPortions?.length >= 2);
            Vue.set(state, "isSplit",isSplit);
            Vue.set(state, "arePaymentOptionsFixed", typeof treatmentPlan?.status === 'number' && treatmentPlan?.status > -1);
            Vue.set(state, "selectedPatientTreatmentPlan", treatmentPlan);
        },
        reset: function (state: PatientTreatmentPlansState) {
            const newState = initialState();
            Object.keys(newState).forEach(key => {
                try {
                    // @ts-ignore
                    state[key] = newState[key];
                } catch (ex) {
                    console.error('PatientTreatmentPlansState Reset Error: ', ex.message);
                }
            });
        },
    },
    getters: {
        loading: (state) => state.loading,
        saving: (state) => state.saving,
        sending: (state) => state.sending,
        getting: (state) => state.getting,
        deleting: (state) => state.deleting,
        reviewing: (state) => state.reviewing,
        restoring: (state) => state.restoring,
        reload: (state) => state.reload,
        signing: (state) => state.signing,
        isSplit: (state) => state.isSplit,
        isOfficeMode: (state) => state.isOfficeMode,
        arePaymentOptionsFixed: (state) => state.arePaymentOptionsFixed,
        selectedPatientTreatmentPlans: (state) => state.selectedPatientTreatmentPlans,
        selectedPatientTreatmentPlan: (state) => state.selectedPatientTreatmentPlan,
        selectedPatientTreatmentPlansSideNav: (state) => state.selectedPatientTreatmentPlansSideNav,
    },
}