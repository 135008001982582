<template>
  <v-dialog v-model="dialog" persistent max-width="500">
    <template  v-slot:activator="{ on }" v-if="!hideActivatorButton">
      <slot name="activator-custom" v-bind:on="on"></slot>
      <v-btn
          v-if="!$scopedSlots['activator-custom'] || $slots['activator-custom']"
          v-on="on"
          type="button"
          :disabled="loading || disable"
          :elevation="actionElevation"
          :class="actionClass"
          :color="actionColor"
          :icon="!!actionIcon && !actionFab"
          :fab="actionFab"
          :text="!actionIcon && actionText"
          :small="(!actionFab ? !!actionIcon || actionSmall : actionFab && !actionSmall) && !actionLarge"
          :x-small="(actionFab && actionSmall) && !actionLarge"
          :outlined="!!actionOutlined"
          rounded
          :loading="actionLoading"
          :large="dark || actionLarge"
          :title="btnTitle"

      >
        <v-icon v-if="!!actionIcon" small>{{ actionIcon }}</v-icon>
        <span v-else>{{ actionButtonText }}</span>
      </v-btn>
    </template>
    <v-card>
      <v-card-title v-if="displayTitleOverride">{{ displayTitleOverride }}</v-card-title>
      <v-card-title v-else>{{ action }} {{ type }}</v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col class="d-flex align-center" v-sanitize.inline="text">
            </v-col>
          </v-row>
          <v-row v-if="!!$slots['additional-text']">
            <v-col class="d-flex align-center pt-0">
              <slot name="additional-text"></slot>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          :disabled="loading"
          text
          @click="cancelDialog()"
          >{{ dismissButtonText }}</v-btn
        >
        <v-btn
          color="primary"
          :disabled="loading || disable"
          :loading="actionLoading"
          rounded
          @click="submit()"
          >{{ actionButtonText }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import {computed, defineComponent, ref, watch} from "@vue/composition-api";
import {delay} from "@shared/functions/helperFunctions";
import {OperationTypes} from "@shared/store/constants";

interface Props {
  type: string;
  action: string;
  actionLoading: boolean;
  actionClass: string;
  actionElevation: number;
  actionIcon: string;
  actionColor: string;
  actionSmall: boolean;
  actionText: boolean;
  actionOutlined: boolean;
  loading: boolean;
  name?: string;
  dark: boolean;
  showDialog?: boolean;
  disable?: boolean;
  actionLarge?: boolean;
  deleteText?: string;
  noDelay: boolean;
  displayTextOverride?: string;
  actionFab: boolean;
  displayActionButtonTextOverride?: string;
  hideActivatorButton: boolean;
  btnTitle?: string;
}

export default defineComponent({
  name: "ConfirmationDialog",
  props: {
    type: String,
    action: {
      type: String,
      default: "Delete",
    },
    actionClass: {
      type: String,
      default: "ml-2",
    },
    actionElevation: {
      type: Number,
      default: 0,
    },
    actionIcon: {
      type: String,
      default: '',
    },
    actionColor: {
      type: String,
      default: 'primary',
    },
    actionLarge: {
      type: Boolean,
      default: false,
    },
    actionSmall: Boolean,
    actionFab: Boolean,
    actionText: {
      type: Boolean,
      default: true,
    },
    actionLoading: Boolean,
    loading: Boolean,
    name: {
      type: String,
      default: "Unknown",
    },

    actionOutlined: {
      type: Boolean,
      default: false,
    },
    dark : {
      type: Boolean,
      default: false,
    },
    noDelay: {
      type: Boolean,
      default: false,
    },
    hideActivatorButton: {
      type: Boolean,
      default: false,
    },
    showDialog: {
      type: Boolean,
      default: false,
    },
    disable: Boolean,
    deleteText: String,
    displayTextOverride: String,
    displayTitleOverride: String,
    displayActionButtonTextOverride: String,
    btnTitle: String
  },
  setup(props: Props, { emit }) {
    const dialog = ref<boolean>(false);
    const text = ref<string>("");
    const actionButtonText = computed<string>(
        () => props.displayActionButtonTextOverride ? props.displayActionButtonTextOverride : props.action
    );
    const dismissButtonText = ref<string>("Cancel");
    watch(
      props,
      async (newVal) => {
        if (newVal) {
          dialog.value = props.showDialog || dialog.value;
          text.value = "";
          //common text that can be added based on action
          if (props.action && props.action.length) {
            switch (props.action.toLowerCase()) {
              case OperationTypes.DELETE:
                text.value = "Are you sure you want to delete ";
                break;
            }
          }
          emit("dialogVisible", newVal);
          //common text to display based on type
          if (props.type) {
            switch (props.type) {
              case "Contract Conditional Text":
                text.value += "this conditional text?";
                break;
              case "Custom Anytime Form":
                text.value += "this custom anytime form?";
                break;
              case "Informed Consent Section":
                text.value += "this section?";
                break;
              case "Custom Form":
                text.value += "this custom form?";
                break;
              case "Custom Intake Form":
                text.value += "this custom intake form?";
                break;
              case "Office":
                text.value += `${props.name}?`;
                break;
              case "Doctor":
                text.value += `Dr. ${props.name}?`;
                break;
              case "Notification Recipient":
                text.value += `the notification recipient ${props.name}?`;
                break;
              case "Treatment Type":
                text.value += "this treatment type?";
                break;
              case "Treatment Coordinator":
                text.value += `the treatment coordinator ${props.name}?`;
                break;
              case "Treatment Class":
                text.value += "this treatment class?";
                break;
              case "Patient Type":
                text.value += "this patient type?";
                break;
              case "Discount":
                text.value += "this discount?";
                break;
              case "Responsible Party Tier":
                text.value += "this responsible party tier?";
                break;
              case "Rate":
                text.value += `the rate ${props.name}?`;
                break;
              case "Discount Override":
                text.value += "this discount override?";
                break;
              case "Logo":
                text.value += "this logo?";
                break;
              case "Product Type":
                text.value += props.deleteText as string;
                break;
              case "Patient":
                text.value += `the patient ${props.name}?`;
                break;
              case "Patients":
                text.value += "these patients?";
                break;
              case "User":
                text.value += `${props.name}?`;
                break;
              case "Patient Treatment Plan & Adjustments":
                text.value += "this treatment plan?  The patient has already adjusted his or her payment plan.";
                break;
              case "Patient Treatment Plan":
                text.value += "this treatment plan?";
                break;
              case "Patient Adjustments":
                text.value += "The patient has already adjusted his or her payment plan. If you edit the Treatment Plan the Payment Options will be regenerated, and the patient adjustments will be lost. </br></br> Do you want to continue with the edit?";
                break;
              case "Treatment Start Document(s) Signature":
                text.value = "Are you sure you want to delete the signature(s) for this treatment start document?</br></br>" +
                    "Note: This action cannot be undone. Furthermore, it will re-open the Treatment Plan for editing.";
                break;
              case "Treatment":
                text.value += "Are you sure you want to decline treatment?</br></br>" +
                    "Note: This action will log you out of the patient portal and notify the office of your decision.";
                break;
              case "App Notification":
                text.value += "this app notification?";
                break;
              default:
                return;
            }
          }

          if (props.type === "Treatment Class" && props.disable) {
            text.value =
              "Treatment Classes that are linked as a completion treatment of another treatment class may not be deleted. Please update links first.";
          }

          if(props.displayTextOverride) {
            text.value = props.displayTextOverride;
          }
        }
      },
      {
        immediate: true,
      }
    );

    async function submit() {
      emit(props.action.toLowerCase());
      if(!props.noDelay) {
        await delay(600);
      }
      dialog.value = false;
    }

    function cancelDialog() {
      if (props.showDialog) {
        emit("cancel");
      }
      dialog.value = false;
    }

    return {
      dialog,
      submit,
      cancelDialog,
      text,
      actionButtonText,
      dismissButtonText,
    };
  },
});
</script>

<style></style>
