<template>
  <div :class="classProps">
    <v-expand-transition :duration="{ enter: 2000, leave: 100 }">
      <div v-if="title" class="pl-1 pr-0 overline pt-0 d-flex justify-space-between ">
        <div class="align-self-center">
          {{ title }}
        </div>
        <v-btn v-if="!disableExpansion" icon text @click="expand = !expand; subMenuExpand = expand;">
          <v-icon small :disabled="!(!!menu && Array.isArray(menu) && menu.length)">
            {{ expand ? '$compressAlt' : '$expandAlt' }}
          </v-icon>
        </v-btn>
      </div>
    </v-expand-transition>
    <slot name="statuses"></slot>
    <v-expand-transition>
      <div v-if="!!menu && Array.isArray(menu) && menu.length">
        <v-list nav dense class="py-0" expand>
          <div v-for="(item, index) in menu" :key="index">
            <nav-menu v-if="!!item && item.items && Array.isArray(item.items) && item.items.length" :expand="expand"
                      :subMenuExpand="subMenuExpand" :menu="item.items" :key="'nav-menu-' + index"/>
          </div>
        </v-list>
      </div>
    </v-expand-transition>
  </div>
</template>

<script lang="ts">
import NavMenu from "./NavMenu.vue";
import {defineComponent, ref, watch} from "@vue/composition-api";

interface Props {
  menu: any[];
  title?: string;
  defaultExpanded: boolean;
  defaultSubMenuExpanded: boolean;
  classProps?: string;
  disableExpansion: boolean;
}

export default defineComponent({
  name: "MainSubMenu",
  components: {
    NavMenu,
  },
  props: {
    menu: {
      type: Array,
      default: () => [],
    },
    title: String,
    defaultExpanded: {
      type: Boolean,
      default: false,
    },
    defaultSubMenuExpanded: {
      type: Boolean,
      default: false,
    },
    disableExpansion: {
      type: Boolean,
      default: false,
    },
    classProps: String,
  },
  setup(props: Props) {
    const expand = ref<boolean>(props.defaultExpanded);
    const subMenuExpand = ref<boolean>(props.defaultSubMenuExpanded);

    watch(
        () => [props.defaultExpanded, props.defaultSubMenuExpanded],
         () => {
           expand.value = props.defaultExpanded;
           subMenuExpand.value = props.defaultSubMenuExpanded;
        }, {immediate: true}
    );

    return {
      expand,
      subMenuExpand
    };
  },
});
</script>

