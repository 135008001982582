import {CalculationRulesService} from "../services/interfaces";
import {inject, provide} from "@vue/composition-api";

export const calculationRulesServiceSymbol = Symbol();

export function provideCalculationRulesService(
  service: CalculationRulesService
) {
  return provide(calculationRulesServiceSymbol, service);
}

export function useCalculationRulesService() {
  const calculationRulesService = inject<CalculationRulesService>(
    calculationRulesServiceSymbol
  );
  if (!calculationRulesService) {
    throw Error("No Calculation Rules Service to Inject!!");
  }
  return calculationRulesService;
}
