<template>
  <div>
    <!-- Navigation -->
    <v-navigation-drawer
        v-model="drawer"
        app
        floating
        class="elevation-1"
        :right="$vuetify.rtl"
        :light="menuTheme === 'light'"
        :dark="menuTheme === 'dark'"
    >
      <!-- Navigation menu info -->
      <template v-slot:prepend>
        <div class="pa-2 pb-0">
          <div class="title font-weight-bold text-uppercase primary--text">
            {{ product.name }}
          </div>
          <div class="overline grey--text">{{ product.version }}</div>
        </div>
      </template>

      <!-- Navigation menu -->
      <main-menu :dashboard="dashboard" :menu="adminNavigation.menu"/>

      <!-- Navigation menu footer -->
      <template v-slot:append>
        <!-- Footer navigation links -->
        <div class="pa-1 text-center">
          <v-btn
              v-for="(item, index) in navigation.footer"
              :key="index"
              @click="navigateTo(item.link)"
              small
              block
              text
          >
            {{ item.key ? item.key : item.text }}
          </v-btn>
        </div>
      </template>
    </v-navigation-drawer>

    <!-- Toolbar -->
    <v-app-bar
        app
        :color="isToolbarDetached ? 'surface' : undefined"
        :flat="isToolbarDetached"
        :light="toolbarTheme === 'light'"
        :dark="toolbarTheme === 'dark'"
    >
      <v-card
          class="flex-grow-1 d-flex"
          :class="[isToolbarDetached ? 'pa-1 mt-3 mx-1' : 'pa-0 ma-0']"
          :flat="!isToolbarDetached"
      >
        <div class="d-flex flex-grow-1 align-center">
          <!-- search input mobile -->
          <v-container fluid class="pa-0" v-if="showSearch">
            <toolbar-search-bar mobile :showSearch.sync="showSearch"/>
          </v-container>

          <div v-else class="d-flex flex-grow-1 align-center">
            <v-app-bar-nav-icon text
                                @click.stop="drawer = !drawer"
            ></v-app-bar-nav-icon>

            <v-spacer class="d-none d-lg-block"></v-spacer>

            <!-- search input desktop -->
            <toolbar-search-bar/>
            <v-spacer class="d-block d-sm-none"></v-spacer>
            <!-- search input mobile -->
            <v-btn class="d-block d-sm-none" icon @click="showSearch = true">
              <v-icon>$search</v-icon>
            </v-btn>

            <toolbar-apps/>

            <toolbar-theme :isDarkTheme="isDarkTheme"/>

            <!--            <div :class="[$vuetify.rtl ? 'ml-1' : 'mr-1']">-->
            <!--              <toolbar-notifications />-->
            <!--            </div>-->

            <toolbar-user/>
          </div>
        </div>
      </v-card>
    </v-app-bar>

    <v-main>
      <v-container
          class="fill-height"
          :fluid="!isContentBoxed"
          :class="{ 'mt-1 pa-0 py-2': $vuetify.breakpoint.mobile }"
      >
        <v-layout>
          <slot></slot>
        </v-layout>
      </v-container>

      <!--      <v-footer app inset>-->
      <!--        <v-spacer></v-spacer>-->
      <!--      </v-footer>-->
    </v-main>
  </div>
</template>

<script lang="ts">
// navigation menu configurations
import config from "../../main/src/configs";
import MainMenu from "../../main/src/components/navigation/MainMenu.vue";
import ToolbarUser from "../../main/src/components/toolbar/ToolbarUser.vue";
import ToolbarApps from "../../main/src/components/toolbar/ToolbarApps.vue";
import theme from "../../main/src/configs/theme";
import {computed, defineComponent} from "@vue/composition-api";
import {useRouteComponents} from "@main/composables/helpers";
import {useStore} from "../providers";
import {UIGetters} from "../store/ui-customizations";
import ToolbarTheme from "../../main/src/components/toolbar/ToolbarTheme.vue";
import ToolbarSearchBar from "../../main/src/components/toolbar/ToolbarSearchBar.vue";

//plugins
import "@shared/plugins/tiptap-vuetify";

export default defineComponent({
  name: "AdminLayout",
  components: {
    MainMenu,
    ToolbarUser,
    ToolbarApps,
    ToolbarTheme,
    ToolbarSearchBar,
  },
  setup() {
    const store = useStore();
    const {navigateTo, navigateToName, routeName} = useRouteComponents();
    const drawer = null;
    const showSearch = false;
    const adminNavigation = config.adminNavigation;
    const navigation = config.navigation;
    const product = config.product;
    const isContentBoxed = theme.isContentBoxed;
    const menuTheme = theme.menuTheme;
    const isToolbarDetached = theme.isToolbarDetached;
    const toolbarTheme = theme.toolbarTheme;
    const dashboard = [
      {
        key: "Dashboard",
        icon: "$home",
        text: "Dashboard",
        link: "/admin/dashboard",
        group: "/dashboard",
      },
    ];

    const isDarkTheme = computed<boolean>(
        () => store.getters[UIGetters.IS_DARK_THEME]
    );

    return {
      dashboard,
      drawer,
      showSearch,
      adminNavigation,
      navigation,
      product,
      isContentBoxed,
      menuTheme,
      isToolbarDetached,
      toolbarTheme,
      navigateTo,
      navigateToName,
      isDarkTheme,
      routeName,
    };
  },
});
</script>
