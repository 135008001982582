import {inject, provide} from "@vue/composition-api";
import {AdditionalChargesService} from "../services/interfaces";

export const additionalChargesServiceSymbol = Symbol();

export function provideAdditionalChargesService(
  service: AdditionalChargesService
) {
  return provide(additionalChargesServiceSymbol, service);
}

export function useAdditionalChargesService() {
  const additionalChargesService = inject<AdditionalChargesService>(
    additionalChargesServiceSymbol
  );
  if (!additionalChargesService) {
    throw Error("No Additional Charges Service to Inject!!");
  }
  return additionalChargesService;
}