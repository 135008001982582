import {inject, provide} from "@vue/composition-api";
import {TemplateDocumentsService} from "../services/interfaces";

export const templateDocumentsServiceSymbol = Symbol();

export function provideTemplateDocumentsService(
  service: TemplateDocumentsService
) {
  return provide(templateDocumentsServiceSymbol, service);
}

export function useTemplateDocumentsService() {
  const templateDocumentsService = inject<TemplateDocumentsService>(
    templateDocumentsServiceSymbol
  );
  if (!templateDocumentsService) {
    throw Error("No Template Documents Service to Inject!!");
  }
  return templateDocumentsService;
}
