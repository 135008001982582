<template>
  <v-menu offset-y left transition="slide-y-transition">
    <template v-slot:activator="{ on }">
      <v-btn icon v-on="on" title="apps">
        <v-icon>$th</v-icon>
      </v-btn>
    </template>

    <v-list class="d-flex flex-row flex-wrap pa-2 text-center justify-center align-content-center"  rounded style="max-width: 330px;">
        <v-list-item   v-for="app in apps"
                       style="flex: 0 50%;"
                       :to="app.link"
                       :disabled="disableApp(app.text)"
                       :key="app.link"
                       link
                       v-shortkey.avoid
                       :inactive="disableApp(app.text)"
                       class="ma-0 pa-1 app-tile"
        >
          <v-icon
            :color="disableApp(app.text) ? '' : 'primary'"
            :disabled="disableApp(app.text)"
            v-shortkey.avoid
            @click.prevent.stop="clickNavigateTo($event, app.link)"
            >{{ app.icon }}</v-icon
          >
          <div class="font-weight-bold ma-0 mt-1">{{ app.text }}</div>
          <div class="caption">
            {{ app.subtitleKey ? app.subtitleKey : app.subtitle }}
          </div>
        </v-list-item>
      </v-list>
  </v-menu>
</template>

<script lang="ts">
import {computed, defineComponent} from "@vue/composition-api";
import {useRouteComponents} from "../../composables/helpers";
import {AppType} from "@shared/store";
import {useRolesToFilterApps} from "../../composables/filters/use-roles-to-filter-apps";
import {AppNames} from "@shared/store/constants";

/*
|---------------------------------------------------------------------
| Toolbar Apps Component
|---------------------------------------------------------------------
|
| Quickmenu for applications in the toolbar
|
*/
export default defineComponent({
  name: "ToolbarApps",
  setup() {
    const apps = computed<AppType[]>(() => useRolesToFilterApps() || []);
    const { clickNavigateTo, navigateTo, routeMeta } = useRouteComponents();

    function disableApp(appText: string) {
      if (routeMeta.value.type) {
        if (
          routeMeta.value.type.toLowerCase() ===
            AppNames.DASHBOARD.toLowerCase() &&
          routeMeta.value.layout
        ) {
          return false;
        }
        return routeMeta.value.type.toLowerCase() === appText.toLowerCase();
      }
      return false;
    }

    return {
      AppNames,
      routeMeta,
      apps,
      navigateTo,
      disableApp,
      clickNavigateTo,
    };
  },
});
</script>

<style lang="scss" scoped>
.app-tile {
  display: flex;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  border-radius: 6px;
  background-color: var(--v-background-base);
  transition: transform 0.2s;
  height: 140px;
  &:hover {
    z-index: 10000;
    transform: scale(1.1);
  }
}
.theme--dark.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
  background-color: transparent !important;
}
</style>
