import {inject, provide} from "@vue/composition-api";
import {PatientTreatmentPlanDiscountsService} from "../services/interfaces";


export const patientTreatmentPlanDiscountsServiceSymbol = Symbol();

export function providePatientTreatmentPlanDiscountsService(
  service: PatientTreatmentPlanDiscountsService
) {
  return provide(patientTreatmentPlanDiscountsServiceSymbol, service);
}

export function usePatientTreatmentPlanDiscountsService() {
  const patientTreatmentPlanDiscountsService = inject<PatientTreatmentPlanDiscountsService>(
      patientTreatmentPlanDiscountsServiceSymbol
  );
  if (!patientTreatmentPlanDiscountsService) {
    throw Error("No Patient Treatment Plan Discounts Service to Inject!!");
  }
  return patientTreatmentPlanDiscountsService;
}
