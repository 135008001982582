import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VExpandTransition } from 'vuetify/lib/components/transitions';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VExpandTransition,{attrs:{"duration":{ enter: 2000, leave: 100 }}},[_c('div',{staticClass:"pl-1 pr-0 body-2 pt-0 d-flex justify-start font-weight-black"},[_c('div',{staticClass:"align-self-center text-uppercase primary--text"},[_vm._v(" Navigation Menu ")])])]),_c(VList,{class:_vm.showDashboard ? 'pt-1' : 'py-0',attrs:{"nav":"","dense":""}},[(_vm.showDashboard)?_c('nav-menu',{staticClass:"pb-1",attrs:{"menu":_vm.dashboard}}):_vm._e(),_vm._l((_vm.menu),function(item,index){return _c('div',{key:index},[_c('div',[_c(VRow,{staticClass:"mb-0 pt-1",attrs:{"dense":""}},[(item.key || item.text)?_c(VExpansionPanels,{staticClass:"condensed",attrs:{"disabled":_vm.apps.length <= 1,"accordion":""}},[_c(VExpansionPanel,[_c(VExpansionPanelHeader,{staticClass:"pl-1 pr-0 overline pt-0",attrs:{"hide-actions":_vm.apps.length <= 1,"disable-icon-rotate":""},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c(VBtn,{attrs:{"icon":"","text":""}},[_c(VIcon,{attrs:{"small":""}},[_vm._v("$th")])],1)]},proxy:true}],null,true)},[_vm._v(_vm._s(item.key ? item.key : item.text)+" ")]),_c(VExpansionPanelContent,[_c(VContainer,{staticClass:"d-flex flex-row flex-wrap ma-0 pa-0 ",staticStyle:{"box-shadow":"none"}},_vm._l((_vm.apps),function(app){return _c('div',{key:app.link,staticClass:"app-tile overline mt-1",on:{"click":function($event){return _vm.navigateTo(app.link)}}},[(item.key.toLowerCase() !== app.text.toLowerCase())?_c('span',[_c(VIcon,{staticStyle:{"min-width":"28px"},attrs:{"color":"primary"}},[_vm._v(_vm._s(app.icon))]),_c('span',{staticClass:"pl-1 ml-1 mr-2"},[_vm._v(_vm._s(app.text))])],1):_vm._e()])}),0)],1)],1)],1):_vm._e()],1)],1),_c('nav-menu',{attrs:{"menu":item.items}})],1)})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }