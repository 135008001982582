<template>
  <v-dialog v-model="idleTimeOutDialog" persistent :fullscreen="$vuetify.breakpoint.mobile" :max-width="500">
    <v-card>
      <v-card-title>Session Expiring Soon</v-card-title>
      <v-card-text class="body-1">
        You've been inactive for a while. You may choose "Yes, stay signed in" to continue or "No, sign me out" if
        you're done.
        <p class="pt-2 font-weight-bold">Time remaining: {{ display }}</p>
      </v-card-text>
      <v-card-actions class="py-1 d-flex justify-end">
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="signOut()"
        >No, sign me out
        </v-btn
        >
        <v-btn
            color="primary"
            rounded
            @click="extendSession()"
        >Yes, stay signed in
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script lang="ts">
import {computed, defineComponent, onMounted, onUnmounted, ref,} from "@vue/composition-api";
import {SessionActions, SessionGetters} from "@shared/store/session";
import {useAccountService, useStore} from "@shared/providers";
import {clone, throttle} from "lodash";
import moment from "moment/moment";
import {APP_LOCALSTORAGE_KEY, SESSION_EXPIRY_DATE, SessionStatuses, SIGN_OUT} from "@shared/store/constants";
import Vue from "vue";
import {updateSessionExpiry} from "@shared/functions/dateFunctions";
import {ClientConfigurationSettingsActions} from "@shared/store/documents/client-configuration-settings";

//COPIED CODE FROM https://github.com/malekim/v-idle
//ANOTHER HELPFUL LINK: https://github.com/JillElaine/jquery-idleTimeout/blob/master/jquery-idleTimeout-iframes.js
export default defineComponent({
  name: "IdleTimeoutDialog",
  setup() {
    const store = useStore();
    const accountService = useAccountService();

    const idleTimeOutDialog = ref<boolean>(false);

    const idleTimeLimit: number = process.env.VUE_APP_IDLE_TIME_LIMIT_IN_SECS ? parseInt(process.env.VUE_APP_IDLE_TIME_LIMIT_IN_SECS) : 900;
    const keepAliveInterval: number = process.env.VUE_APP_KEEP_ALIVE_INTERVAL_IN_SECS ? parseInt(process.env.VUE_APP_KEEP_ALIVE_INTERVAL_IN_SECS) : 300;
    const idleCheckThrottle: number = process.env.VUE_APP_IDLE_TIME_THROTTLE_IN_SECS ? parseInt(process.env.VUE_APP_IDLE_TIME_THROTTLE_IN_SECS) : 1;

    const reminders: number[] = [30, 60];
    const wait: number = 0;
    const events: string[] = ["click", "keypress", "scroll", "mousemove", "touchmove"];

    const display = ref<string>("");
    let timer: number | undefined = undefined;
    let counter: number | undefined = undefined;
    let diff: number = idleTimeLimit;
    let minutes: string = "";
    let seconds: string = "";
    let prevSessionExpiryDate = "";
    let extendSessionStatus = 0;
    const throttledClearTimer = throttle(clearTimer, idleCheckThrottle * 1000); //convert to milliseconds
    if (!localStorage.getItem(SESSION_EXPIRY_DATE) || moment.utc().isAfter(moment.utc(localStorage.getItem(SESSION_EXPIRY_DATE)))) {
      localStorage.setItem(SESSION_EXPIRY_DATE, updateSessionExpiry());
    }

    // const sessionExpiryDate = computed<string | null>(
    const sessionStatus = computed<string>(
        () => store.getters[SessionGetters.STATUS]
    );


    //#region dialog functions only
    async function extendSession() {
      extendSessionStatus = await store.dispatch(
          SessionActions.EXTEND_SESSION,
          {service: accountService}
      );
      idleTimeOutDialog.value = false;
    }

    async function signOut() {
      await store.dispatch(
          SessionActions.SIGN_OUT,
          {service: accountService}
      );
      idleTimeOutDialog.value = false;
    }

    //#endregion dialog functions only

    async function checkExtendSession() {
      const sessionExpiryDate = localStorage.getItem(SESSION_EXPIRY_DATE);
      let diffExtApiCall = sessionExpiryDate && moment(sessionExpiryDate).isValid() ? moment.duration(moment.utc(sessionExpiryDate).diff(moment.utc())).asSeconds() : sessionExpiryDate === SIGN_OUT ? -1 : undefined;

      if (!diffExtApiCall) {
        return;
      }

      if (diffExtApiCall <= 0) {
        // throttledClearTimer?.cancel();
        // clearIntervals();
        // await delay(5000);
        // await signOut();
        return;
      }

      if ((idleTimeLimit - diffExtApiCall) >= keepAliveInterval && (sessionStatus.value !== SessionStatuses.EXTENDING_SESSION_STARTED) && extendSessionStatus < 300) {
        const storageItem = localStorage.getItem(APP_LOCALSTORAGE_KEY);
        const commonData = storageItem ? JSON.parse(storageItem) : {};
        await Vue.nextTick();
        if (commonData?.SessionModule?.status !== SessionStatuses.EXTENDING_SESSION_STARTED) {
          extendSessionStatus = await store.dispatch(
              SessionActions.EXTEND_SESSION,
              {service: accountService}
          );
          store.cache.clear(ClientConfigurationSettingsActions.LOAD_CLIENT_CONFIGURATION_SETTINGS);
        }
      }
    }

    async function setDisplay() {
      const sessionExpiryDate = localStorage.getItem(SESSION_EXPIRY_DATE);
      diff = (sessionExpiryDate && moment(sessionExpiryDate).isValid() ? moment.duration(moment.utc(sessionExpiryDate).diff(moment.utc())).asSeconds() : 0);
      if (diff < 0) {
        return;
      }

      const roundedDiff = Math.round(diff);
      if (reminders?.length && (reminders?.includes(roundedDiff))) {
        //send reminder / dialog
        idleTimeOutDialog.value = true;
      }

      //todo potentially use broadcast message for this instead so it is slightly more revised
      if (!prevSessionExpiryDate || prevSessionExpiryDate !== sessionExpiryDate) {
        prevSessionExpiryDate = clone(sessionExpiryDate) || "";
        extendSessionResetTimers();
      }


      if (reminders?.length && roundedDiff > reminders[reminders.length - 1]) {
        idleTimeOutDialog.value = false;
        return;
      }

      // bitwise OR to handle parseInt
      const minute = (diff / 60) | 0;
      const second = diff % 60 | 0;

      minutes = `${minute < 10 ? "0" + minute : minute}`;
      seconds = `${second < 10 ? "0" + second : second}`;

      display.value = `${minutes}:${seconds}`;

    }

    function clearIntervals(timerOnly = false) {
      if (typeof timer !== 'undefined') {
        clearInterval(timer);
      }
      if (timerOnly) return;
      if (typeof counter !== 'undefined') {
        clearInterval(counter);
      }
    }

    function setTimers(timerOnly = false) {
      const sessionExpiryDate = localStorage.getItem(SESSION_EXPIRY_DATE);
      const expiry = sessionExpiryDate && moment.utc(sessionExpiryDate).isValid()
          ? moment.duration(moment.utc(sessionExpiryDate).diff(moment.utc())).asSeconds()
          : idleTimeLimit;
      timer = window.setInterval(signOut, (expiry * 1000) + 2000); //slight buffer for display
      if (timerOnly) return;
      counter = window.setInterval(setDisplay, 1000);
    }

    async function clearTimer() {
      if (!idleTimeOutDialog.value) {
        clearIntervals();
        setDisplay();
        setTimers();
        diff = idleTimeLimit;
        await checkExtendSession();
      }
    }

    function extendSessionResetTimers() {
      Vue.nextTick(() => {
        if (!idleTimeOutDialog.value) {
          clearIntervals(true);
          setTimers(true);
        }
      });
    }


    onMounted(() => {
      setTimeout(() => {
        throttledClearTimer?.cancel();
        clearIntervals();
        setDisplay();
        setTimers();
        for (let i = events.length - 1; i >= 0; i -= 1) {
          window.addEventListener(events[i], throttledClearTimer, {passive: true});
        }
      }, wait * 1000);
    })

    onUnmounted(() => {
      throttledClearTimer?.cancel();
      clearIntervals();
      for (let i = events.length - 1; i >= 0; i -= 1) {
        window.removeEventListener(events[i], throttledClearTimer);
      }
      idleTimeOutDialog.value = false;
    })

    return {
      idleTimeOutDialog,
      extendSession,
      signOut,
      display,
    };
  },
});
</script>
