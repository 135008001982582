import {inject, provide} from "@vue/composition-api";
import {PatientTreatmentPlanCommunicationsService} from "../services/interfaces";


export const patientTreatmentPlanCommunicationsServiceSymbol = Symbol();

export function providePatientTreatmentPlanCommunicationsService(
  service: PatientTreatmentPlanCommunicationsService
) {
  return provide(patientTreatmentPlanCommunicationsServiceSymbol, service);
}

export function usePatientTreatmentPlanCommunicationsService() {
  const  patientTreatmentPlanCommunicationsService = inject<PatientTreatmentPlanCommunicationsService>(
      patientTreatmentPlanCommunicationsServiceSymbol
  );
  if (!patientTreatmentPlanCommunicationsService) {
    throw Error("No Patient Treatment Plan Communications Service to Inject!!");
  }
  return  patientTreatmentPlanCommunicationsService;
}
