import {inject, provide} from "@vue/composition-api";
import {NotificationRecipientsService} from "../services/interfaces";

export const notificationRecipientsServiceSymbol = Symbol();

export function provideNotificationRecipientsService(
  service: NotificationRecipientsService
) {
  return provide(notificationRecipientsServiceSymbol, service);
}

export function useNotificationRecipientsService() {
  const notificationRecipientsService = inject<NotificationRecipientsService>(
    notificationRecipientsServiceSymbol
  );
  if (!notificationRecipientsService) {
    throw Error("No Notification Recipient Service to Inject!!");
  }
  return notificationRecipientsService;
}