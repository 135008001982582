import {inject, provide} from "@vue/composition-api";
import {CustomFormService} from "../services/interfaces";

export const customFormServiceSymbol = Symbol();

export function provideCustomFormService(
  service: CustomFormService
) {
  return provide(customFormServiceSymbol, service);
}

export function useCustomFormService() {
  const customFormService = inject<CustomFormService>(
    customFormServiceSymbol
  );
  if (!customFormService) {
    throw Error("No Custom Form Service to Inject!!");
  }
  return customFormService;
}
