import {ChargeOverService} from "../services/interfaces";
import {inject, provide} from "@vue/composition-api";

export const chargeOverServiceSymbol = Symbol();

export function provideChargeOverService(service: ChargeOverService) {
  return provide(chargeOverServiceSymbol, service);
}

export function useChargeOverService() {
  const chargeOverService = inject<ChargeOverService>(chargeOverServiceSymbol);
  if (!chargeOverService) {
    throw Error("No Charge Over Service to Inject!!");
  }
  return chargeOverService;
}