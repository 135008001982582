import {inject, provide} from "@vue/composition-api";
import {PatientWetSignDocumentsService} from "../services/interfaces";

export const patientWetSignDocumentsServiceSymbol = Symbol();

export function providePatientWetSignDocumentsService(service: PatientWetSignDocumentsService) {
  return provide(patientWetSignDocumentsServiceSymbol, service);
}

export function usePatientWetSignDocumentsService() {
  const patientWetSignDocumentsService = inject<PatientWetSignDocumentsService>(patientWetSignDocumentsServiceSymbol);
  if (!patientWetSignDocumentsService) {
    throw Error("No Patient Wet Sign Documents Service to Inject!!");
  }
  return patientWetSignDocumentsService;
}
