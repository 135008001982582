import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VSnackbar,{style:(_vm.isAuthLayout && _vm.$vuetify.breakpoint.mdAndUp ? 'margin-left: 216px' : ''),attrs:{"color":_vm.snackbar.status,"timeout":_vm.snackbar.timeout,"top":_vm.snackbar.position === 'top',"right":_vm.snackbar.position === 'right',"centered":_vm.snackbar.position === 'centered',"rounded":"","elevation":4},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c(VBtn,_vm._b({attrs:{"icon":"","dense":""},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();_vm.alert = false}}},'v-btn',attrs,false),[_c(VIcon,{attrs:{"small":_vm.$vuetify.breakpoint.smAndDown}},[_vm._v("$close")])],1)]}}]),model:{value:(_vm.alert),callback:function ($$v) {_vm.alert=$$v},expression:"alert"}},[_c(VRow,{attrs:{"no-gutters":"","align":"center","justify":"start"}},[_c(VCol,{staticClass:"animate__animated animate__pulse",attrs:{"cols":"2"}},[_c(VIcon,{attrs:{"large":!_vm.$vuetify.breakpoint.smAndDown,"dense":"","dark":""}},[_vm._v(_vm._s(_vm.snackbar.icon))])],1),_c(VCol,{attrs:{"cols":"10"}},[_c('span',[_vm._v(_vm._s(_vm.snackbar.message))])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }