import {PDFService} from "../services/interfaces";
import {inject, provide} from "@vue/composition-api";

export const pdfServiceSymbol = Symbol();

export function providePDFService(service: PDFService) {
  return provide(pdfServiceSymbol, service);
}

export function usePDFService() {
  const pdfService = inject<PDFService>(pdfServiceSymbol);
  if (!pdfService) {
    throw Error("No PDF Service to Inject!!");
  }
  return pdfService;
}