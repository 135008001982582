import {inject, provide} from "@vue/composition-api";
import {UIService} from "../services/interfaces";

export const UIServiceSymbol = Symbol();

export function provideUIService(service: UIService) {
  return provide(UIServiceSymbol, service);
}

export function useUIService() {
  const UIService = inject<UIService>(UIServiceSymbol);
  if (!UIService) {
    throw Error("No UI Service to Inject!!");
  }
  return UIService;
}
