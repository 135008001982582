<template>
  <!--  TODO would love to remove the rounding or white around edges-->
  <v-dialog v-model="dialog"
            max-width="500"
  >
    <template v-slot:activator="{ on }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on:tooltip }">
          <v-btn v-blur rounded v-on="{ ...on, ...tooltip }"
                 icon :disabled="loading || disable" large
          >
            <v-icon>$helpSolid</v-icon>
          </v-btn>
        </template>
        <span>Do you need help?</span>
      </v-tooltip>
    </template>
    <v-card>
      <v-toolbar
        dark
        flat
        color="primary"
        :rounded="false"
      >
        <v-btn
          icon
          dark
          @click="dialog = false"
        >
          <v-icon>$close</v-icon>
        </v-btn>
        <v-toolbar-title>Help</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn
            dark
            text
            @click="dialog = false"
          >
            Close
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text class="ma-0 my-2 body-1 text--primary" v-if="(tutorialVideos || []).length > 0">
        <p class="ma-1 body-2 primary--text" v-if="$vuetify.breakpoint.width <768"><i>*Please Note: Viewing the
          documents in landscape (horizontal) mode is best, which may require you to rotate your device.</i></p>
        <p class="ma-1">For guidance, check out the tutorial videos below:</p>
        <div class="text-center">
          <v-row align="center" dense>
            <v-col cols="12">
              <v-select
                v-model="selectedVideo"
                :items="tutorialVideos"
                item-text="title"
                item-value="videoId"
                return-object
                label="Videos"
                hint="select a video"
                persistent-hint
                single-line
                :disabled="loading || !tutorialVideos || tutorialVideos.length === 0"
              ></v-select>
            </v-col>
            <v-col cols="12">
              <v-btn v-blur
                     @click="activateVideoDialog((selectedVideo || {}).embeddedLink || '', (selectedVideo || {}).title || 'Video')"
                     text x-large :elevation="2" class="rounded-lg"
                     :class="$vuetify.breakpoint.smAndDown ? 'pa-4' : ' pa-6'">
                <font-awesome-layers class="red--text text--darken-2 fa-bottom"
                                     :class="$vuetify.breakpoint.smAndDown ? 'fa-2x' : 'fa-3x'">
                  <font-awesome-icon icon="fa-brands fa-youtube" />
                  <font-awesome-layers-text
                    :transform="$vuetify.breakpoint.smAndDown ? 'down-30 right-3' : 'down-36 right-4'"
                    value="Play Video"
                    :style="{'font-size': $vuetify.breakpoint.smAndDown ?  '12px ': '14px'}" />
                </font-awesome-layers>
              </v-btn>
            </v-col>
          </v-row>
          <video-dialog
            :showDialog.sync="showVideoDialog"
            :src.sync="videoEmbeddedLink"
            :title="videoTitle"
            :loading="false"
          />
        </div>
        <div class="mt-2 text-center" v-if="selectedVideo">
          <a :href="selectedVideo.externalLink" target="_blank">or Open in YouTube</a>
        </div>
        <p class="ma-1 mt-2">If you need additional help, feel free to reach out to us:</p>
        <div class="ml-3">
          <strong>Email:&nbsp;&nbsp;</strong><a href="mailto:support@orthodocspro.com">support@orthodocspro.com</a>
          <p><strong>Phone:</strong> 708-586-7846</p>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { computed, defineComponent, onBeforeMount, ref, watch } from "@vue/composition-api";
import { useRouteComponents } from "@main/composables/helpers";
import { SessionStatuses, VideoTypes } from "@shared/store/constants";
import { User } from "@shared/store";
import { SessionGetters } from "@shared/store/session";
import Vue from "vue";
import VideoDialog from "@portal/components/dialogs/VideoDialog.vue";
import { useStore, useVideosService } from "@shared/providers";
import { VideosActions, VideosGetters } from "@shared/store/videos";
import { Video } from "@shared/store/videos/interfaces";

interface Props {
  loading: boolean;
  disable: boolean;
}

export default defineComponent({
  name: "ToolbarHelp",
  props: {
    loading: Boolean,
    disable: Boolean
  },
  components: { VideoDialog },
  setup(props: Props) {
    const store = useStore();
    const dialog = ref<boolean>(false);
    const { routeName, navigateToName } = useRouteComponents();
    const sessionStatus = computed<string | null>(
      () => store.getters[SessionGetters.STATUS]
    );
    const currentUser = computed<User>(
      () => store.getters[SessionGetters.USER]
    );
    const videosService = useVideosService();
    const toolbarTitle = ref<string>("Help");
    const videoEmbeddedLink = ref<string>("");
    const videoTitle = ref<string>("");
    const showVideoDialog = ref<boolean>(false);

    const tutorialVideos = computed<Video[]>(
      () => store.getters[VideosGetters.VIDEOS]?.filter(
        (video: Video) => video.type === VideoTypes.Tutorials
      )
    );

    const selectedVideo = ref<Video | undefined>(undefined);

    function activateVideoDialog(src: string, title: string) {
      if(!src) {
        return;
      }
      videoTitle.value = title;
      videoEmbeddedLink.value = src;
      showVideoDialog.value = true;
    }

    onBeforeMount(async () => {
      await Promise.all([
        store.cache.dispatch(
          VideosActions.LOAD_VIDEOS,
          {
            service: videosService
          }
        )
      ]);
      await Vue.nextTick();
      if (tutorialVideos?.value?.length > 0) {
        selectedVideo.value = tutorialVideos?.value?.[0];
      }
    });

    watch(
      sessionStatus,
      async (currentStatus, prevStatus) => {
        if (currentStatus === prevStatus) {
          return;
        }
        if (currentStatus === SessionStatuses.LOGIN_SUCCESSFUL && (!currentUser.value?.lastLoginDate)) {
          toolbarTitle.value = "Welcome";
          if (tutorialVideos?.value?.length > 0) {
            selectedVideo.value = tutorialVideos?.value?.[0];
          }
          dialog.value = true;
        }
      },
      { immediate: true }
    );

    return {
      dialog,
      routeName,
      navigateToName,
      showVideoDialog,
      videoEmbeddedLink,
      videoTitle,
      tutorialVideos,
      selectedVideo,
      activateVideoDialog
    };
  }
});
</script>
